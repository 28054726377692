import styled from 'styled-components';


export const Main = styled.main`
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100%;

  >div {
    padding: 16px 0px;
    width: 100%;
    border-bottom: 5px solid var(--color-gray);
  }

  >div:last-child{
    border:none;
  }

`;

export const SubMenu = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px;

  >a {
      font-family: 'Expansiva', sans-serif;
      cursor: pointer;
      margin: 16px;
      color: var(--color-gray);
      text-decoration: none;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      padding-bottom: 35px;

      &:hover{
        text-decoration: underline;
      }
    }

    .submenu-active{
      text-decoration: underline;
      color: var(--color-yellow);
    }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 70%;

  iframe{
    width: 100%;
    min-height: 80vh;
    min-width: 320px;
  }
  
  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50%;

    >p:first-child {
      font-family: 'Expansiva', sans-serif;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      border-bottom: 5px solid var(--color-gray);
      padding-bottom: 35px;
    }

    >p, ul {
      padding: 0 16px;
      padding-top: 35px;
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      
    }

    img {
      padding: 32px;
    }

    @media screen and (max-width: 960px) {
      padding: 16px;
    }
  }

  @media screen and (max-width: 960px) {
    flex-wrap: wrap;

    img {
      width: 320px;
    }
  }
`;


export const Localization = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 25px 0px;

  a{
    text-decoration: none;
    color: var(--color-gray);
    font-size: 16px;
    line-height: 20px; 
    
    &:hover{
      text-decoration: underline;
    }
  }
  
  span:first-child {
    font-size: 20px; 
  }

  span{
    font-size: 16px;
    line-height: 20px; 
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-bottom: 2.2rem;

  h2:first-child {
    font-family: 'Expansiva', sans-serif;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    border-bottom: 5px solid var(--color-gray);
    padding-bottom: 35px;
  }

  h2+div{
    margin-top: 32px;
  }

  div+div{
    margin-top: 16px;
  }

  >div{
    display: flex;
    flex-direction: column;

    >label{
      font-size: 16px;
      margin-left: 24px;
    }

    >select{
      background-color: var(--color-gray);
      border: 3px solid var(--color-black);
      color: var(--color-white);
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
      background-repeat: no-repeat;
      height: 40px;
      border-radius: 25px;
      padding: 0px 16px;
      
      option{
        color: var(--color-white);
        background-color: var(--color-gray);
      }
      &:focus{
        background-image:
        linear-gradient(45deg, var(--color-yellow) 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, var(--color-yellow) 50%),
        linear-gradient(to right, #ccc, #ccc);
        background-position:
        calc(100% - 15px) 1em,
        calc(100% - 20px) 1em,
        calc(100% - 2.5em) 0.5em;
        background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
        background-repeat: no-repeat;
        border-color: var(--color-yellow);
        outline: 0;
      }
    }
  }

  >button {
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
    -webkit-appearance: none;
    border: none;
    overflow: visible;
    color: var(--color-white);
    text-transform: uppercase;
    display: inline-block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 16px;
    background: var(--color-black);
    vertical-align: middle;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    border-radius: 25px;
    border: 3px solid var(--color-black);
    font-weight: normal;
    box-shadow: inset 0 0 0 0 transparent;

    &:hover{
      background: var(--color-black);
      border: 3px solid var(--color-yellow);
      color: var(--color-white);
      transition: all 0.5s ease-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
  }
  

  .loading{
    color: var(--color-gray);
    border: 3px solid var(--color-black);

    &:hover{
      color: var(--color-gray);
      border: 3px solid var(--color-black);
      cursor: initial;
    }
  }
  
  .success{
    margin-top: 32px;
    border: 0;
    text-align: center;
  }
  
  small{
    font-size: 16px;
    margin: 1.2rem;
    a{
      color: var(--color-yellow-dark);

      &:hover{
        color: var(--color-yellow);
        transition: 0.2s color;
      }
    }
  }  
`
export const TrabalheConosco = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-bottom: 2.2rem;

  h2:first-child {
    font-family: 'Expansiva', sans-serif;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    border-bottom: 5px solid var(--color-gray);
    padding-bottom: 35px;
  }
  h3 {
    font-size: 20px;
    text-decoration: underline;
  }

  div{
    text-align: center;
  }

  .curriculum {
    margin-top: 2rem;
  }

  article {
    font-size: 17px;
  }
`