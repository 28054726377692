import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logoImg from '../../assets/logo-solo.png';
import CulturaImg from '../../assets/cultura.png';
import TimeLine from '../../components/Timeline';
import { SubMenu } from '../Infraestrutura/styles';
import { Main, Container, Terms } from './styles';
import { Helmet } from 'react-helmet';

type InfraestruturaProps = {
  showHistoriaBool?: boolean;
  showCulturaBool?: boolean;
}

export function Institucional({ showHistoriaBool = true, showCulturaBool = false }: InfraestruturaProps) {
  const [showHistorias, setShowHistorias] = useState(showHistoriaBool);
  const [showCulturas, setShowCulturas] = useState(showCulturaBool);

  const location = useLocation();
  useEffect(() => {

    if (location.pathname === "/institucional/historia") {
      setShowCulturas(false);
      setShowHistorias(true);
    }

    if (location.pathname === "/institucional/cultura") {
      setShowCulturas(true);
      setShowHistorias(false);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Institucional | Zanaflex - Compostos de Borracha</title>
        <meta name="description" content="História e Cultura - Zanaflex" />
        <meta name="keywords" content="Institucional, História, Cultura, Zanaflex, Borracha, Composto de Borracha" />

        <link rel="canonical" href="https://zanaflex.com.br/institucional" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/institucional" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/institucional/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/institucional/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/institucional/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/institucional/historia" className={showHistoriaBool ? 'submenu-active' : 'submenu-disabled'} ><h1>História</h1></Link>
        <Link to="/institucional/cultura" className={showCulturaBool ? 'submenu-active' : 'submenu-disabled'} ><h1>Cultura</h1></Link>
      </SubMenu>
      {showHistorias && (
        <>
          <Container>
            <div data-aos="fade-right">
              <h2>Histórico</h2>
              <p>Fundada e 1968, a Zanaflex produz Compostos de Borracha Sintética e Natural (masterbatch e composto acelerado) para diversos fins industriais. Situada em Cotia – São Paulo, fornece compostos técnicos de borracha de alta qualidade com preços competitivos a diversas empresas da cadeia automotiva (TIER 1) e reposição. Com equipamentos adequados para atender os requisitos de seus clientes combinado ao conhecimento técnico e um sistema da qualidade abrangente, a Zanaflex fornece compostos técnicos de borracha de alta qualidade numa gama variada de elastômeros sintéticos e naturais, pretos ou coloridos.</p>
            </div>
            <div data-aos="fade-left">
              <img src={logoImg} alt="Logo Zanaflex" />
            </div>
          </Container>
          <Container>
            <div data-aos="fade-right" data-aos-duration="1500">
              <h2>Soluções Tecnológicas em Produtos Elastômeros</h2>
              <p>A Zanaflex é líder no mercado brasileiro de compostos de borracha, uma empresa com capacidade para desenvolver e produzir produtos personalizados para as mais diversas aplicações. Nossos clientes são nossos parceiros, por isso privilegiamos o cumprimento de prazos, e a dedicação à qualidade através do investimento em equipamentos cada vez mais sofisticados.</p>
            </div>
          </Container>
          <TimeLine lang="pt-BR" />
          <Terms>
            <h3>Termo e Condições Gerais</h3>
            <a href="https://znfx.com.br/files/termos_e_condicoes_gerais.pdf" target="_blank" rel="noreferrer">
              Clique aqui e consulte nosso Termo e Condições Gerais
            </a>
          </Terms>
        </>
      )}
      {showCulturas && (
        <Container data-aos="fade-right">

          <div>
            <img src={CulturaImg} className="cultura" alt="Cultura Zanaflex" />
          </div>

        </Container>

      )}
    </Main>
  );
}

export function InstitucionalES({ showHistoriaBool = true, showCulturaBool = false }: InfraestruturaProps) {

  const [showHistorias, setShowHistorias] = useState(showHistoriaBool);
  const [showCulturas, setShowCulturas] = useState(showCulturaBool);

  const location = useLocation();
  useEffect(() => {

    if (location.pathname === "/institucional/historia/es-ES") {
      setShowCulturas(false);
      setShowHistorias(true);
    }

    if (location.pathname === "/institucional/cultura/es-ES") {
      setShowCulturas(true);
      setShowHistorias(false);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Institucional | Zanaflex - Compuestos de Caucho</title>
        <meta name="description" content="Historia y Cultura - Zanaflex" />
        <meta name="keywords" content="Institucional, Historia, Cultura, Zanaflex, Caucho, Compuesto de caucho" />

        <link rel="canonical" href="https://zanaflex.com.br/institucional/es-ES" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/institucional" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/institucional/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/institucional/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/institucional/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/institucional/historia/es-ES" className={showHistoriaBool ? 'submenu-active' : 'submenu-disabled'} ><h1>Historia</h1></Link>
        <Link to="/institucional/cultura/es-ES" className={showCulturaBool ? 'submenu-active' : 'submenu-disabled'} ><h1>Cultura</h1></Link>
      </SubMenu>
      {showHistorias && (
        <>
          <Container>
            <div data-aos="fade-right">
              <h2>Histórico</h2>
              <p>Fundada en 1968, Zanaflex produce compuestos de caucho sintético y natural (masterbatch y compuesto acelerado) para diversos fines industriales. Ubicada en Cotia – São Paulo, suministra compuestos de caucho técnico de alta calidad a precios competitivos a varias empresas de la cadena automotriz (TIER 1) y del mercado de repuestos. Con equipos adecuados para cumplir con los requisitos de sus clientes, combinado con conocimiento técnico y un sistema de calidad integral, Zanaflex suministra compuestos de caucho técnico de alta calidad en una amplia gama de elastómeros sintéticos y naturales, negros o de color.</p>
            </div>
            <div data-aos="fade-left">
              <img src={logoImg} alt="Logo Zanaflex" />
            </div>
          </Container>
          <Container>
            <div data-aos="fade-right" data-aos-duration="1500">
              <h2>Soluciones Tecnológicas en Productos Elastómeros</h2>
              <p>Zanaflex es líder en el mercado brasileño de compuestos de caucho, una empresa con capacidad para desarrollar y producir productos personalizados para las más diversas aplicaciones. Nuestros clientes son nuestros socios, por lo que priorizamos el cumplimiento de los plazos y la dedicación a la calidad mediante la inversión en equipos cada vez más sofisticados.</p>
            </div>
          </Container>
          <TimeLine lang="es-ES" />
          <Terms>
            <h3>Términos Generales y Condiciones</h3>
            <a href="https://znfx.com.br/files/termos_e_condicoes_gerais.pdf" target="_blank" rel="noreferrer">
              Haga clic aquí y vea nuestros Términos y Condiciones Generales
            </a>
          </Terms>
        </>
      )}
      {showCulturas && (
        <Container data-aos="fade-right">

          <div>
            <img src={CulturaImg} className="cultura" alt="Cultura Zanaflex" />
          </div>

        </Container>

      )}
    </Main>
  );
}

export function InstitucionalUS({ showHistoriaBool = true, showCulturaBool = false }: InfraestruturaProps) {

  const [showHistorias, setShowHistorias] = useState(showHistoriaBool);
  const [showCulturas, setShowCulturas] = useState(showCulturaBool);

  const location = useLocation();
  useEffect(() => {

    if (location.pathname === "/institucional/historia/en-US") {
      setShowCulturas(false);
      setShowHistorias(true);
    }

    if (location.pathname === "/institucional/cultura/en-US") {
      setShowCulturas(true);
      setShowHistorias(false);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Institutional | Zanaflex - Rubber Compounds</title>
        <meta name="description" content="History and Culture - Zanaflex" />
        <meta name="keywords" content="Institutional, History, Culture, Zanaflex, Rubber, Rubber Compound" />

        <link rel="canonical" href="https://zanaflex.com.br/institucional/en-US" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/institucional" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/institucional/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/institucional/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/institucional/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/institucional/historia/en-US" className={showHistoriaBool ? 'submenu-active' : 'submenu-disabled'} ><h1>History</h1></Link>
        <Link to="/institucional/cultura/en-US" className={showCulturaBool ? 'submenu-active' : 'submenu-disabled'} ><h1>Culture</h1></Link>
      </SubMenu>
      {showHistorias && (
        <>
          <Container>
            <div data-aos="fade-right">
              <h2>Historic</h2>
              <p>Founded in 1968, Zanaflex produces Synthetic and Natural Rubber Compounds (masterbatch and accelerated compound) for various industrial purposes. Located in Cotia – São Paulo, it supplies high quality technical rubber compounds at competitive prices to several companies in the automotive chain (TIER 1) and aftermarket. With suitable equipment to meet the requirements of its customers, combined with technical knowledge and a comprehensive quality system, Zanaflex supplies high quality technical rubber compounds in a wide range of synthetic and natural elastomers, black or colored.</p>
            </div>
            <div data-aos="fade-left">
              <img src={logoImg} alt="Logo Zanaflex" />
            </div>
          </Container>
          <Container>
            <div data-aos="fade-right" data-aos-duration="1500">
              <h2>Technological Solutions in Elastomer Products</h2>
              <p>Zanaflex is the leader in the Brazilian rubber compound market, a company with the capacity to develop and produce customized products for the most diverse applications. Our customers are our partners, so we prioritize meeting deadlines and dedication to quality through investment in increasingly sophisticated equipment.</p>
            </div>
          </Container>
          <TimeLine lang="en-US" />
          <Terms>
            <h3>General Terms and Conditions</h3>
            <a href="https://znfx.com.br/files/termos_e_condicoes_gerais.pdf" target="_blank" rel="noreferrer">
              Click here and see our General Terms and Conditions
            </a>
          </Terms>
        </>
      )}
      {showCulturas && (
        <Container data-aos="fade-right">

          <div>
            <img src={CulturaImg} className="cultura" alt="Cultura Zanaflex" />
          </div>

        </Container>

      )}
    </Main>
  );
}
