import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.header`
  display: flex;
  flex: 1;
  background-color: var(--color-gray);
  width: 100%;
  flex-direction: column;
`

export const SelectLanguage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--color-white);

  p{
    font-size: 12px;
  }

  .languages-images{
    img {
      cursor: pointer;
      width: 18px;
      height: 12px;
      margin: 8px;
    }
  }
  

  .language-disabled {
    display: none;
  }
  
  .language-active {
    display: flex;
  }

  .languages-images-mobile{
    img{
      width: 36px;
      height: 24px;
      margin: 8px;
    }
  }

`

export const Section = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-around;

  > a {
    color: var(--color-white);
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-size: 2rem;
    display: flex;
    padding: 20px 0;
    align-items: center;

    > img {
      width: 300px;

      @media screen and (max-width: 960px) {
        width: 250px;
      }
    }    
  }
`

export const Nav = styled.nav`
  background: linear-gradient(90deg, #1d1b1b 0%, #1a1717 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  > a {
    color: var(--color-white);
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 250px;
    }    
  }
  .nav-links {
    color: var(--color-white);
    text-decoration: none;
    padding: 0.5rem 1rem;
    &:hover{
      background-color: var(--color-yellow);
      border-radius: 4px;
      transition: all 0.2s ease-out;
      }
    }
    .fa-firstdraft {
      margin-left: 0.5rem;
      font-size: 1.6rem;
    }
    .fa-bars {
      color: var(--color-white);
    }
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
    .active {
      background: var(--color-black);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-links {
      position: relative;
      z-index: 1;
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
     &:hover{
      background-color: ${shade(0.2, '#FFD63B')};
      border-radius: 0;
     }
    }
    .fa-times {
      color: var(--color-white);
      font-size: 2rem;
    }
  }
`;

export const MenuIcon = styled.div`
  display: none;
  @media screen and (max-width: 960px){
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    
    }
`;

export const NavMenu = styled.ul`
  display: grid;
  grid-template-columns: repeat(9, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: center;
  margin-right: 2rem;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: start;
    .active {
      background: var(--color-black);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  }
`

export const NavItem = styled.li`
  display: flex;
  align-items: center;
  height: 80px;
`