import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles'

type ButtonProps = {
  content: string;
  pathname?: string;
  link?: string;
  onClick?: () => void; // Adicionando propriedade onClick
}

export default function Button({ pathname, content, link, onClick  }: ButtonProps) {

  if (link) {
    return (
      <Container>
        <Link to={link} target="_blank" download>{content}</Link>
      </Container>
    );
  } else if(pathname) {
    return (
      <Container>
        <Link to={pathname}>{content}</Link>
      </Container>
    );
  } else if (onClick) { // Adicionando a condição para onClick
    return (
      <Container>
        <button onClick={onClick}>{content}</button>
      </Container>
    );
  }
  return <></>
}