import React from 'react';
import { Helmet } from 'react-helmet';
import { Main, Container } from './styles';

export function Links() {
  return (
    <Main>
      <Helmet>
        <title>Links | Zanaflex - Compostos de Borracha</title>
        <meta name="description" content="Links Úteis - Zanaflex" />
        <meta name="keywords" content="Links, Zanaflex, Borracha, Composto de Borracha" />

        <link rel="canonical" href="https://zanaflex.com.br/links" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/links" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/links/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/links/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/links/en-US" />
      </Helmet>

      <Container data-aos="fade-left">
        <div>
          <h1>Links Úteis</h1>
          <ul>
            <li>ABR - Associação Brasileira de Segmento de Reforma de Pneus - <a href="https://www.abr.org.br" target="_blank" rel="noreferrer">www.abr.org.br</a></li>
            <li>Malaysian Rubber Board - <a href="https://www.lgm.gov.my" target="_blank" rel="noreferrer">www.lgm.gov.my</a></li>
            <li>Flexsys - <a href="https://www.flexsys.com" target="_blank" rel="noreferrer">www.flexsys.com</a></li>
            <li>ABIARB / SINDIBOR - <a href="https://www.borracha.com.br" target="_blank" rel="noreferrer">www.borracha.com.br</a> - <a href="https://www.abiarb.com.br" target="_blank" rel="noreferrer">www.abiarb.com.br</a> - <a href="https://www.sindibor.com.br" target="_blank" rel="noreferrer">www.sindibor.com.br</a></li>
            <li>APEX - Agência de Promoção de Exportações do Brasil - <a href="https://www.apex.org.br" target="_blank" rel="noreferrer">www.apex.org.br</a></li>
            <li>ABNT - Associação Brasileira de Normas Técnicas - <a href="https://www.abnt.org.br" target="_blank" rel="noreferrer">www.abnt.org.br</a></li>
            <li>ABTB - Associação Brasileira de Tecnologia da Borracha - <a href="https://www.abtb.com.br" target="_blank" rel="noreferrer">www.abtb.com.br</a></li>
            <li>APABOR - Associação Paulista de Produtores e Beneficiadores de Borracha - <a href="https://www.apabor.org.br" target="_blank" rel="noreferrer">www.apabor.org.br</a></li>
            <li>Expobor - Feira Internacional de Tecnologia, Máquinas e Artefatos de Borracha - <a href="https://www.expobor.com.br" target="_blank" rel="noreferrer">www.expobor.com.br</a></li>
            <li>INMETRO - <a href="https://www.inmetro.gov.br" target="_blank" rel="noreferrer">www.inmetro.gov.br</a></li>
            <li>RUBBER - <a href="https://www.rubber.com" target="_blank" rel="noreferrer">www.rubber.com</a></li>
            <li>Astlett Rubber - <a href="https://www.astlettrubber.com" target="_blank" rel="noreferrer">www.astlettrubber.com</a></li>
            <li>Associação Latino Americana de Pneus e Aros - <a href="https://www.alapa.org.br" target="_blank" rel="noreferrer">www.alapa.org.br</a></li>
          </ul>
        </div>
      </Container>
    </Main>
  );
}

export function LinksES() {
  return (
    <Main>
      <Helmet>
        <title>Enlaces | Zanaflex - Compuestos de Caucho</title>
        <meta name="description" content="Enlaces útiles - Zanaflex" />
        <meta name="keywords" content="Enlaces, Zanaflex, Caucho, Compuesto de Caucho" />

        <link rel="canonical" href="https://zanaflex.com.br/links/es-ES" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/links" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/links/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/links/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/links/en-US" />
      </Helmet>

      <Container data-aos="fade-left">
        <div>
          <h1>Enlaces útiles</h1>
          <ul>
            <li>ABR - Asociación Brasileña del Segmento de Retiro de Neumáticos - <a href="https://www.abr.org.br" target="_blank" rel="noreferrer">www.abr.org.br</a></li>
            <li>Malaysian Rubber Board - <a href="https://www.lgm.gov.my" target="_blank" rel="noreferrer">www.lgm.gov.my</a></li>
            <li>Flexsys - <a href="https://www.flexsys.com" target="_blank" rel="noreferrer">www.flexsys.com</a></li>
            <li>ABIARB / SINDIBOR - <a href="https://www.borracha.com.br" target="_blank" rel="noreferrer">www.borracha.com.br</a> - <a href="https://www.abiarb.com.br" target="_blank" rel="noreferrer">www.abiarb.com.br</a> - <a href="https://www.sindibor.com.br" target="_blank" rel="noreferrer">www.sindibor.com.br</a></li>
            <li>APEX - Agencia Brasileña de Promoción de Exportaciones - <a href="https://www.apex.org.br" target="_blank" rel="noreferrer">www.apex.org.br</a></li>
            <li>ABNT - Asociación Brasileña de Normas Técnicas - <a href="https://www.abnt.org.br" target="_blank" rel="noreferrer">www.abnt.org.br</a></li>
            <li>ABTB - Asociación Brasileña de Tecnología del Caucho - <a href="https://www.abtb.com.br" target="_blank" rel="noreferrer">www.abtb.com.br</a></li>
            <li>APABOR - Asociación de Productores y Transformadores de Caucho de São Paulo - <a href="https://www.apabor.org.br" target="_blank" rel="noreferrer">www.apabor.org.br</a></li>
            <li>Expobor - Feria Internacional de Tecnología, Maquinaria y Artefactos del Caucho - <a href="https://www.expobor.com.br" target="_blank" rel="noreferrer">www.expobor.com.br</a></li>
            <li>INMETRO - <a href="https://www.inmetro.gov.br" target="_blank" rel="noreferrer">www.inmetro.gov.br</a></li>
            <li>RUBBER - <a href="https://www.rubber.com" target="_blank" rel="noreferrer">www.rubber.com</a></li>
            <li>Astlett Rubber - <a href="https://www.astlettrubber.com" target="_blank" rel="noreferrer">www.astlettrubber.com</a></li>
            <li>Asociación Latinoamericana de Llantas y Llantas - <a href="https://www.alapa.org.br" target="_blank" rel="noreferrer">www.alapa.org.br</a></li>
          </ul>
        </div>
      </Container>
    </Main>
  );
}

export function LinksUS() {
  return (
    <Main>
      <Helmet>
        <title>Links | Zanaflex - Rubber Compounds</title>
        <meta name="description" content="Useful Links - Zanaflex" />
        <meta name="keywords" content="Links, Zanaflex, Rubber, Rubber Compound" />

        <link rel="canonical" href="https://zanaflex.com.br/links/en-US" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/links" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/links/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/links/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/links/en-US" />
      </Helmet>

      <Container data-aos="fade-left">
        <div>
          <h1>Useful links</h1>
          <ul>
            <li>ABR - Brazilian Association of Tire Retreat Segment - <a href="https://www.abr.org.br" target="_blank" rel="noreferrer">www.abr.org.br</a></li>
            <li>Malaysian Rubber Board - <a href="https://www.lgm.gov.my" target="_blank" rel="noreferrer">www.lgm.gov.my</a></li>
            <li>Flexsys - <a href="https://www.flexsys.com" target="_blank" rel="noreferrer">www.flexsys.com</a></li>
            <li>ABIARB / SINDIBOR - <a href="https://www.borracha.com.br" target="_blank" rel="noreferrer">www.borracha.com.br</a> - <a href="https://www.abiarb.com.br" target="_blank" rel="noreferrer">www.abiarb.com.br</a> - <a href="https://www.sindibor.com.br" target="_blank" rel="noreferrer">www.sindibor.com.br</a></li>
            <li>APEX - Brazilian Export Promotion Agency - <a href="https://www.apex.org.br" target="_blank" rel="noreferrer">www.apex.org.br</a></li>
            <li>ABNT - Brazilian Association of Technical Standards - <a href="https://www.abnt.org.br" target="_blank" rel="noreferrer">www.abnt.org.br</a></li>
            <li>ABTB - Brazilian Association of Rubber Technology - <a href="https://www.abtb.com.br" target="_blank" rel="noreferrer">www.abtb.com.br</a></li>
            <li>APABOR - São Paulo Association of Rubber Producers and Processors - <a href="https://www.apabor.org.br" target="_blank" rel="noreferrer">www.apabor.org.br</a></li>
            <li>Expobor - International Fair of Rubber Technology, Machinery and Artifacts - <a href="https://www.expobor.com.br" target="_blank" rel="noreferrer">www.expobor.com.br</a></li>
            <li>INMETRO - <a href="https://www.inmetro.gov.br" target="_blank" rel="noreferrer">www.inmetro.gov.br</a></li>
            <li>RUBBER - <a href="https://www.rubber.com" target="_blank" rel="noreferrer">www.rubber.com</a></li>
            <li>Astlett Rubber - <a href="https://www.astlettrubber.com" target="_blank" rel="noreferrer">www.astlettrubber.com</a></li>
            <li>Latin American Tire and Rim Association - <a href="https://www.alapa.org.br" target="_blank" rel="noreferrer">www.alapa.org.br</a></li>
          </ul>
        </div>
      </Container>
    </Main>
  );
}
