import { createGlobalStyle } from 'styled-components';
import fontMavenPro from '../assets/fonts/maven-pro-regular.ttf';

export default createGlobalStyle`

  @font-face {
        font-family: "Maven Pro";
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url(${fontMavenPro}) format('truetype');
    }
  

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #fff;
    color: var(--color-gray);
    -webkit-font-smoothing: antialiased;
  }

  body, input, select, button, textarea {
    font-size: 16px;
    font-family: 'Maven Pro', sans-serif;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  a{
    color: var(--color-gray);
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }

  small{
    font-size: 1rem;
  }

  .modal-button{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    background-color: var(--color-gray);
    height: 32px;
    img{
      padding: 4px;
      cursor: pointer;
    }
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .react-modal-content {
    background: var(---color-background);
    padding: 3rem;
    position: relative;
    border-radius: 0.24rem;

    >img{
      width: 100%;
    }
  }
  
  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }

  :root {
    --color-yellow: #e4ba22;
    --color-yellow-dark: #a58614;
    --color-gray: #56565A;
    --color-black: #242222;
    --color-white: #f8f8f8;
    --color-background: #f8f8f8;


    --line-size: 0.3rem; 
    --line-circle-size: 1rem; 
    --line-circle-border-size: 2px; 
    --card-margin: 2rem; 
    --card-max-width: 300px; 
    --card-arrow-size: 1rem; 
    }
    
`;
