import styled from 'styled-components';
import { shade } from 'polished';

export const DropdownMenu = styled.ul` 
  background: red;
  width: 200px;
  position: absolute;
  top: 160px;
  list-style: none;
  text-align: start;
  z-index: 5;

  li {
  background: var(--color-yellow);
  cursor: pointer;
    
  &:hover{
      background: ${shade(0.2, '#FFD63B')};
    }
  }
  .clicked {
    display: none;
  }
  .link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: var(--color-white);
    padding: 16px;
  }
`