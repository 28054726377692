import React from "react";
import Slider from "react-slick";
import { SliderContainer } from "./styles";

interface SlickSliderProps {
  firstImgURL: string;
  secondImgURL?: string;
  thirdImgURL?: string;
  fourthImgURL?: string;
  fiveImgURL?: string;
  sixthImgURL?: string;
}

export default function SlickSlider({ firstImgURL,
  secondImgURL,
  thirdImgURL,
  fourthImgURL,
  fiveImgURL,
  sixthImgURL
}: SlickSliderProps) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <SliderContainer>
      <Slider {...settings} arrows={false}>
        {firstImgURL && (
          <div>
            <img src={firstImgURL} alt="Primeira Imagem" />
          </div>
        )}
        {secondImgURL && (
          <div>
            <img src={secondImgURL} alt="Segunda Imagem" />
          </div>
        )}
        {thirdImgURL && (
          <div>
            <img src={thirdImgURL} alt="Terceira Imagem" />
          </div>
        )}
        {fourthImgURL && (
          <div>
            <img src={fourthImgURL} alt="Quarta Imagem" />
          </div>
        )}
        {fiveImgURL && (
          <div>
            <img src={fiveImgURL} alt="Quinta Imagem" />
          </div>
        )}
        {sixthImgURL && (
          <div>
            <img src={sixthImgURL} alt="Sexta Imagem" />
          </div>
        )}
      </Slider>
    </SliderContainer>
  );
}