import styled from 'styled-components';

export const Footer = styled.footer`
  background: linear-gradient(90deg, #1d1b1b 0%, #1a1717 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--color-white);

  p{
    max-width: 70%;

    @media screen and (max-width: 960px) {
      
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  @media screen and (max-width: 960px) {
    height: 120px;
  }
`;