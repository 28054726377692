import styled from 'styled-components';


export const SliderContainer = styled.div`
  width: 100%;
  padding: 32px;

  button{
    margin: 32px;
  }

  img {
    width: 100%;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

  .slick-dots{
    text-align: center;
  }

 

  @media screen and (max-width: 960px) {
    width: 300px;
    padding: 0;

    button{
      margin: 0;
    }

    img {
      width: 250px;
    }
  }
`;