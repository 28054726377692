import React from 'react';
import { useLocation } from 'react-router-dom';
import { Footer as FooterDiv } from './styles'


export default function Footer(){
  const location = useLocation();
  const esES = location.pathname.includes("es-ES");
  const enUS = location.pathname.includes("en-US");
  if(esES){
    return (
      <FooterDiv>
      <p>Soluciones Tecnológicas en Productos Elastómeros Copyright© 2022 - Zanaflex Borrachas Ltda - Todos los derechos reservados.</p>
      </FooterDiv>
    )
  }
  if(enUS){
    return(
      <FooterDiv>
        <p>ZANAFLEX - Technological Solutions in Elastomer Products Copyright© 2022 - Zanaflex Borrachas Ltda - All rights reserved.</p>
      </FooterDiv>
    );
  }
  return(
    <FooterDiv>
      <p>ZANAFLEX - Soluções Tecnológicas em Produtos Elastômeros Copyright© 2022 - Zanaflex Borrachas Ltda - Todos os direitos reservados.</p>
    </FooterDiv>
  );
}