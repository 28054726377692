import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import certificado2Img from '../../assets/certificadotuvnord.png';
import certificado1Img from '../../assets/certificado_energia.png';
import certificado3Img from '../../assets/certificado_iatf.png';
import sgiImg from '../../assets/sgi.png';

import { Main, Container, SubMenu, Title, Terms, Square, AnimationContainer, Glass, PlayerContainer, Button2 } from './styles';
import ReactPlayer from 'react-player';
import Tooltip from '../../components/Tooltip';

type QualidadeProps = {
  showPoliticaDoSGIBool?: boolean;
  showCertificacoesBool?: boolean;
  showAreaDoFornecedorBool?: boolean;
  showFISPQSBool?: boolean;
}

export function Qualidade({ showPoliticaDoSGIBool = true, showCertificacoesBool = false, showAreaDoFornecedorBool = false, showFISPQSBool = false }: QualidadeProps) {
  const [showPoliticaDoSGI, setShowPoliticaDoSGI] = useState(showPoliticaDoSGIBool);
  const [showCertificacoes, setShowCertificacoes] = useState(showCertificacoesBool);
  const [showAreaDoFornecedor, setShowAreaDoFornecedor] = useState(showAreaDoFornecedorBool);
  const [showFISPQS, setShowFISPQS] = useState(showFISPQSBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/qualidade/politica-do-sgi") {
      setShowAreaDoFornecedor(false);
      setShowCertificacoes(false);
      setShowFISPQS(false);
      setShowPoliticaDoSGI(true);
    }

    if (location.pathname === "/qualidade/certificados") {
      setShowAreaDoFornecedor(false);
      setShowCertificacoes(true);
      setShowFISPQS(false);
      setShowPoliticaDoSGI(false);
    }

    if (location.pathname === "/qualidade/area-fornecedor") {
      setShowAreaDoFornecedor(true);
      setShowCertificacoes(false);
      setShowFISPQS(false);
      setShowPoliticaDoSGI(false);
    }

    if (location.pathname === "/qualidade/fispqs") {
      setShowAreaDoFornecedor(false);
      setShowCertificacoes(false);
      setShowFISPQS(true);
      setShowPoliticaDoSGI(false);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Qualidade & Meio Ambiente | Zanaflex - Compostos de Borracha</title>
        <meta name="description" content="Política do SGI, Certificações, Área do Fornecedor e FISPQS - Zanaflex" />
        <meta name="keywords" content="Qualidade, Meio Ambiente, Política do SGI, Certificações, Área do Fornecedor, FISPQS, Zanaflex, Borracha, Composto de Borracha" />

        <link rel="canonical" href="https://zanaflex.com.br/qualidade" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/qualidade" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/qualidade/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/qualidade/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/qualidade/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/qualidade/politica-do-sgi" className={showPoliticaDoSGI ? 'submenu-active' : 'submenu-disabled'} ><h1>Política do SGI</h1></Link>
        <Link to="/qualidade/certificados" className={showCertificacoes ? 'submenu-active' : 'submenu-disabled'}><h1>Certificações</h1></Link>
        <Link to="/qualidade/area-fornecedor" className={showAreaDoFornecedor ? 'submenu-active' : 'submenu-disabled'} ><h1>Área do Fornecedor</h1></Link>
        <Link to="/qualidade/fispqs" className={showFISPQS ? 'submenu-active' : 'submenu-disabled'} ><h1>FISPQS</h1></Link>
      </SubMenu>
      {showPoliticaDoSGI && (
        <>
          <Title>
            <h2>Sistema de Gestão Integrado</h2>
            <p>Qualidade, Meio Ambiente, Saúde e Segurança</p>
          </Title>
          <Container>
            <div data-aos="fade-right">
              <h4>A Zanaflex Borrachas Ltda.</h4>
              <p>A Zanaflex Borrachas Ltda. indústria de compostos elastoméricos e seus colaboradores comprometem-se em satisfazer seus clientes e outras partes interessadas, proteger o meio ambiente, reduzir riscos e melhorar continuamente seu Sistema de Gestão Integrado, através:</p>
              <ul>
                <li>Do atendimento aos requisitos legais e outros aplicáveis;</li>
                <li>Da prevenção, redução e correta destinação dos resíduos;</li>
                <li>De proporcionar condições de trabalho seguras e saudáveis;</li>
                <li>Da prevenção e redução dos perigos e riscos ocupacionais;</li>
                <li>Da consulta e participação dos trabalhadores;</li>
                <li>Do aprimoramento contínuo dos processos internos.</li>
              </ul>
            </div>
            <div>
              <img src={sgiImg} alt="Logo Zanaflex" />
            </div>
          </Container>
          <Terms className="terms">
            <p>Termo e Condições Gerais</p>
            <a href="/files/termos_e_condicoes_gerais.pdf" target="_blank" rel="noreferrer">
              Clique aqui e consulte nosso Termo e Condições Gerais
            </a>
          </Terms>
          <AnimationContainer className="animation-container">
            <div className="proeficiencia" data-aos="zoom-in">
              <h3>Proeficiencia</h3>
              <p>Comprometida sempre com a qualidade dos produtos e serviços, a Zanaflex participa dos principais programas de proficiência entre os laboratórios de ensaio de borracha. A avaliação dessas análises são realizadas por organismos independentes (Senai e IPT) garantindo a imparcialidade e demonstrando nossa capacidade de atender as exigências do mercado.</p>
            </div>
            <div className="laboratorio" data-aos="zoom-in-right">

            </div>
            <div className="grid" data-aos="zoom-in">
              <Glass className="grid-first">
                <div>
                  <div>
                    <Link to="/qualidade/certificados">Certificado</Link>
                    <Link to="/qualidade/certificados">ISO 9001-2008</Link>
                  </div>
                  <div>
                    <Link to="/qualidade/certificados">Desenvolvimento e Manufatura de Compostos e Produtos Moldados de Borracha.</Link>
                  </div>
                </div>
              </Glass>
              <Glass className="grid-second" data-aos="zoom-in">
                <div>
                  <div>
                    <Link to="/qualidade/politica-do-sgi">Política</Link>
                    <Link to="/qualidade/politica-do-sgi">Ambiental</Link>
                  </div>
                  <div>
                    <Link to="/qualidade/politica-do-sgi">Sistema ambiental totalmente licenciado e controlado.</Link>
                  </div>
                </div>
              </Glass>
              <Glass className="grid-third" data-aos="zoom-in">
                <div>
                  <div>
                    <Link to="/infraestrutura/laboratorios">Laboratórios</Link>
                  </div>
                  <div>
                    <Link to="/infraestrutura/laboratorios">Altamente equipados e com capacidade para realizar diversos tipos de análises em nossas matérias-primas, compostos e produtos vulcanizados.</Link>
                  </div>
                </div>
              </Glass>
              <div className="grid-fourth" data-aos="zoom-in">

              </div>
            </div>
            <div className="fispqs" data-aos="zoom-in-left">
              <h3>FISPQ’S</h3>
              <p>Ficha de Informações de Segurança de Produtos Químicos.</p>
            </div>
          </AnimationContainer>
        </>
      )}
      {showCertificacoes && (
        <>
          <Title>
            <h2>Certificados</h2>
            <p>A Zanaflex possui as certificações IATF 16949:2016 e ISO 9001:2015 com ênfase no desenvolvimento e fornecimento de compostos de borracha, além disso, aplica ferramentas específicas da Qualidade como APQP, MSA, PFMEA,PPAP.</p>
          </Title>
          <Container>
            <div data-aos="fade-down" data-aos-duration="1500">
              <ul>
                <li>Em 1999 a empresa conquistou o certificado ISO 9002:1994</li>
                <li>Em 2002 a empresa conquistou o certificado ISO 9001:2000</li>
                <li>Em 2005 a empresa foi certificada conforme ISO 9001:2000</li>
                <li>Em 2008 a empresa foi certificada conforme ISO 9001:2000</li>
                <li>Em 2009 a empresa foi certificada conforme ISO 9001:2008</li>
                <li>Em 2017 a empresa foi certificada conforme ISO 9001:2015</li>
                <li>Em 2022 a empresa foi certificada conforme IATF 16949:2016</li>
              </ul>
            </div>
          </Container>

          <Container data-aos="fade-up" data-aos-duration="1500">

            <Tooltip title={{
              type: 'pattern',
              ref: undefined,
              types: undefined,
              message: "Certificado de Energia renovavel, clique na imagem para baixar"
            }}>

              <div>
                <a href="/files/certificates/certificado1.pdf" target="_blank">
                  <img src={certificado1Img} alt="Certificado" />
                </a>
              </div>

            </Tooltip>

            <Tooltip title={{
              type: 'pattern',
              ref: undefined,
              types: undefined,
              message: "Certificado IATF 16949:2016, clique na imagem para baixar"
            }}>

              <div>
                <a href="/files/certificates/certificado3.pdf" target="_blank">
                  <img src={certificado3Img} alt="Certificado" />
                </a>
              </div>

            </Tooltip>

            <Tooltip title={{
              type: 'pattern',
              ref: undefined,
              types: undefined,
              message: "Certificado ISO 9001:2015, clique na imagem para baixar"
            }}>

              <div>
                <a href="/files/certificates/certificado2.pdf" target="_blank">
                  <img src={certificado2Img} alt="Certificado" />
                </a>
              </div>

            </Tooltip>

          </Container>

          <Container data-aos="fade-up" data-aos-duration="1500">
            <div>
              {/* <Button link="/files/certificates/certificados.pdf" content="Clique na Imagem ou Baixe aqui" /> */}
              <Button2>
                <a href="/files/certificates/certificados.pdf" target="_blank"> Clique na Imagem ou Baixe aqui </a>
              </Button2>
            </div>
          </Container>
        </>
      )}
      {showAreaDoFornecedor && (
        <>
          <PlayerContainer>
            <ReactPlayer url="/files/webinar2022.mp4"
              width="100%"
              playing={true}
              muted={true}
              controls={true} />
          </PlayerContainer>
          <Title data-aos="fade" data-aos-duration="1500">
            <h2>Documentação</h2>
            <p>Pertinente a relação do fornecedor com a Zanaflex.</p>
          </Title>
          <Container>
            <Square>
              <div className="supplier-location">
                {/* <Button2>
                  <Link to="/files/supplier-location/formulario-de-derrogas.doc" target="_blank" download>Download</Link>
                </Button2> */}
                <Button2>
                  <a href="/files/supplier-location/formulario-de-derrogas.doc" target="_blank"> Fomulário de Derrogas </a>
                  {/* <button onClick={() => setDownload(urlToDownloadDerrogas)}> Fomulário de Derrogas </button> */}
                  {/* {download} */}
                </Button2>
                <Button2>
                  <a href="/files/supplier-location/MQAFport.pdf" target="_blank"> MQAF </a>
                </Button2>
                <Button2>
                  <a href="/files/supplier-location/analise-de-risco-do-fornecedor.xls" target="_blank"> Análise de Risco </a>
                  {/* <button onClick={() => setDownload(urlToDownloadAnaliseRisco)}> Análise de Risco </button> */}
                  {/* {download} */}
                </Button2>
                {/* <iframe src={download} style={{display: 'none'}}></iframe> */}
              </div>
            </Square>
          </Container>
        </>
      )}
      {showFISPQS && (
        <>
          <Title data-aos="fade" data-aos-duration="1500">
            <h2>FISPQS</h2>
            <p>Linha fina - A FISPQ (Ficha de Informações de Segurança de Produtos Químicos) é um documento normalizado pela Associação Brasileira de Normas Técnicas (ABNT) conforme norma, ABNT-NBR 14725. Este documento, denominado “Ficha com Dados de Segurança” segundo Decreto nº 2.657 de 03/07/1998 (promulga a Convenção nº 170 da Organização Internacional do Trabalho-OIT), deve ser recebido pelos empregadores que utilizem produtos químicos, tornando-se um documento obrigatório para a comercialização destes produtos.</p>
          </Title>
          <Container>
            <Square>
              <div className="families">
                <ul>
                  <li><a href="/files/fispqs/fispq_familia_bromobutil.zip" target="_blank" rel="noreferrer">Família Bromobutil</a></li>
                  <li><a href="/files/fispqs/fispq_familia_clorobutil.zip" target="_blank" rel="noreferrer">Família Clorobutil</a></li>
                  <li><a href="/files/fispqs/fispq_familia_estireno_butadieno_e_natural.zip" target="_blank" rel="noreferrer">Família Estireno Butadieno e Natural</a></li>
                  <li><a href="/files/fispqs/fispq_familia_estireno_butadieno.zip" target="_blank" rel="noreferrer">Família Estireno Butadieno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_etileno_acrilato.zip" target="_blank" rel="noreferrer">Família Etileno Acrilato</a></li>
                  <li><a href="/files/fispqs/fispq_familia_etileno_propileno_dieno.zip" target="_blank" rel="noreferrer">Família Etileno Propileno Dieno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_fluorelastomero.zip" target="_blank" rel="noreferrer">Família Fluroelastômero</a></li>
                </ul>
                <ul>
                  <li><a href="/files/fispqs/fispq_familia_nitrilica.zip" target="_blank" rel="noreferrer">Família Nitrílica</a></li>
                  <li><a href="/files/fispqs/fispq_familia_poli_butadieno.zip" target="_blank" rel="noreferrer">Família Poli Butadieno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_poli_cloropreno.zip" target="_blank" rel="noreferrer">Família Poli Cloropreno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_polietileno_clorado.zip" target="_blank" rel="noreferrer">Família Polietileno Clorado</a></li>
                  <li><a href="/files/fispqs/fispq_familia_polietileno_cloro_sulfonado.zip" target="_blank" rel="noreferrer">Família Polietileno Cloro Sulfonado</a></li>
                  <li><a href="/files/fispqs/fispq_familia_poli_isopreno_sintetico_e_ou_natural.zip" target="_blank" rel="noreferrer">Família Poli Isopropeno Sintético e ou Natural</a></li>
                  <li><a href="/files/fispqs/fispq_familia_silicone.zip" target="_blank" rel="noreferrer"> Família Silicone</a></li>
                  <li><a href="/files/fispqs/fispq_nbr_pvc.zip" target="_blank" rel="noreferrer">Família NBR PVC</a></li>
                </ul>
              </div>
            </Square>
          </Container>
        </>
      )}
    </Main>
  );
}

export function QualidadeES({ showPoliticaDoSGIBool = true, showCertificacoesBool = false, showAreaDoFornecedorBool = false, showFISPQSBool = false }: QualidadeProps) {
  const [showPoliticaDoSGI, setShowPoliticaDoSGI] = useState(showPoliticaDoSGIBool);
  const [showCertificacoes, setShowCertificacoes] = useState(showCertificacoesBool);
  const [showAreaDoFornecedor, setShowAreaDoFornecedor] = useState(showAreaDoFornecedorBool);
  const [showFISPQS, setShowFISPQS] = useState(showFISPQSBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/qualidade/politica-do-sgi/es-ES") {
      setShowAreaDoFornecedor(false);
      setShowCertificacoes(false);
      setShowFISPQS(false);
      setShowPoliticaDoSGI(true);
    }

    if (location.pathname === "/qualidade/certificados/es-ES") {
      setShowAreaDoFornecedor(false);
      setShowCertificacoes(true);
      setShowFISPQS(false);
      setShowPoliticaDoSGI(false);
    }

    if (location.pathname === "/qualidade/area-fornecedor/es-ES") {
      setShowAreaDoFornecedor(true);
      setShowCertificacoes(false);
      setShowFISPQS(false);
      setShowPoliticaDoSGI(false);
    }

    if (location.pathname === "/qualidade/fispqs/es-ES") {
      setShowAreaDoFornecedor(false);
      setShowCertificacoes(false);
      setShowFISPQS(true);
      setShowPoliticaDoSGI(false);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Calidad y Medio Ambiente | Zanaflex - Compuestos de Caucho</title>
        <meta name="description" content="Política SGI, Certificaciones, Área de Proveedores y FISPQS - Zanaflex" />
        <meta name="keywords" content="Calidad, Medio Ambiente, Política SGI, Certificaciones, Área de Proveedores, FISPQS, Zanaflex, Caucho, Compuesto de Caucho" />

        <link rel="canonical" href="https://zanaflex.com.br/qualidade/es-ES" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/qualidade" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/qualidade/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/qualidade/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/qualidade/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/qualidade/politica-do-sgi/es-ES" className={showPoliticaDoSGI ? 'submenu-active' : 'submenu-disabled'} ><h1>Política de SGI</h1></Link>
        <Link to="/qualidade/certificados/es-ES" className={showCertificacoes ? 'submenu-active' : 'submenu-disabled'}><h1>Certificaciones</h1></Link>
        <Link to="/qualidade/area-fornecedor/es-ES" className={showAreaDoFornecedor ? 'submenu-active' : 'submenu-disabled'} ><h1>Área de proveedores</h1></Link>
        <Link to="/qualidade/fispqs/es-ES" className={showFISPQS ? 'submenu-active' : 'submenu-disabled'} ><h1>FISPQS</h1></Link>
      </SubMenu>
      {showPoliticaDoSGI && (
        <>
          <Title>
            <h2>Sistema de manejo integrado</h2>
            <p>Calidad, Medio Ambiente, Salud y Seguridad</p>
          </Title>
          <Container>
            <div data-aos="fade-right">
              <h4>Zanaflex Borrachas Ltda.</h4>
              <p>Zanaflex Borrachas Ltda. La industria de compuestos elastoméricos y sus empleados se comprometen a satisfacer a sus clientes y demás partes interesadas, proteger el medio ambiente, reducir los riesgos y mejorar continuamente su Sistema Integrado de Gestión, a través de:</p>
              <ul>
                <li>Cumplimiento de requisitos legales y otros requisitos aplicables;</li>
                <li>Prevención, reducción y correcta disposición de residuos;</li>
                <li>Proporcionar condiciones de trabajo seguras y saludables;</li>
                <li>Prevención y reducción de riesgos y peligros laborales;</li>
                <li>Consulta y participación de los trabajadores;</li>
                <li>Mejora continua de los procesos internos.</li>
              </ul>
            </div>
            <div>
              <img src={sgiImg} alt="Logo Zanaflex" />
            </div>
          </Container>
          <Terms className="terms">
            <p>Términos Generales y Condiciones</p>
            <a href="/files/termos_e_condicoes_gerais.pdf" target="_blank" rel="noreferrer">
              Haga clic aquí y vea nuestros Términos y Condiciones Generales
            </a>
          </Terms>
          <AnimationContainer className="animation-container">
            <div className="proeficiencia" data-aos="zoom-in">
              <h3>Competencia</h3>
              <p>Siempre comprometida con la calidad de los productos y servicios, Zanaflex participa en los principales programas de competencia entre los laboratorios de prueba de caucho. La evaluación de estos análisis es realizada por organismos independientes (Senai e IPT) garantizando la imparcialidad y demostrando nuestra capacidad para atender los requerimientos del mercado.</p>
            </div>
            <div className="laboratorio" data-aos="zoom-in-right">

            </div>
            <div className="grid" data-aos="zoom-in">
              <Glass className="grid-first">
                <div>
                  <div>
                    <Link to="/qualidade/certificados/es-ES">Certificado</Link>
                    <Link to="/qualidade/certificados/es-ES">ISO 9001-2008</Link>
                  </div>
                  <div>
                    <Link to="/qualidade/certificado/es-ES">Desarrollo y Fabricación de Compuestos y Productos de Caucho Moldeado</Link>
                  </div>
                </div>
              </Glass>
              <Glass className="grid-second" data-aos="zoom-in">
                <div>
                  <div>
                    <Link to="/qualidade/politica-do-sgi/es-ES">Política de</Link>
                    <Link to="/qualidade/politica-do-sgi/es-ES">Medio ambiente</Link>
                  </div>
                  <div>
                    <Link to="/qualidade/politica-do-sgi/es-ES">Sistema ambiental totalmente licenciado y controlado</Link>
                  </div>
                </div>
              </Glass>
              <Glass className="grid-third" data-aos="zoom-in">
                <div>
                  <div>
                    <Link to="/infraestrutura/laboratorios/es-ES">Laboratorios</Link>
                  </div>
                  <div>
                    <Link to="/infraestrutura/laboratorios/es-ES">Altamente equipado y capaz de realizar diferentes tipos de análisis sobre nuestras materias primas, compuestos y productos vulcanizados.</Link>
                  </div>
                </div>
              </Glass>
              <div className="grid-fourth" data-aos="zoom-in">

              </div>
            </div>
            <div className="fispqs" data-aos="zoom-in-left">
              <h3>FISPQ’S</h3>
              <p>Ficha de datos de seguridad de materiales.</p>
            </div>
          </AnimationContainer>
        </>
      )}
      {showCertificacoes && (
        <>
          <Title>
            <h2>Certificados</h2>
            <p>Zanaflex cuenta con las certificaciones IATF 16949:2016 y ISO 9001-2015 con énfasis en el desarrollo y suministro de compuestos de caucho, además, aplica herramientas específicas de Calidad como APQP, MSA, PFMEA, PPAP.</p>
          </Title>
          <Container>
            <div data-aos="fade-down" data-aos-duration="1500">
              <ul>
                <li>En 1999 la empresa obtiene el certificado ISO 9002:1994</li>
                <li>En 2002 la empresa obtuvo el certificado ISO 9001:2000</li>
                <li>En 2005 la empresa se certificó según la norma ISO 9001:2000</li>
                <li>En 2008 la empresa se certificó según la norma ISO 9001:2000</li>
                <li>En 2009 la empresa se certificó según la norma ISO 9001:2008</li>
                <li>En 2017 la empresa se certificó según la norma ISO 9001:2015</li>
                <li>En 2022 la empresa se certificó según la norma IATF 16949:2016</li>
              </ul>
            </div>
          </Container>

          <Container data-aos="fade-up" data-aos-duration="1500">

            <Tooltip title={{
              type: 'pattern',
              ref: undefined,
              types: undefined,
              message: "Certificado de Energías Renovables, click en la imagen para descargar"
            }}>

              <div>
                <a href="/files/certificates/certificado1.pdf" target="_blank">
                  <img src={certificado1Img} alt="Certificado" />
                </a>
              </div>

            </Tooltip>

            <Tooltip title={{
              type: 'pattern',
              ref: undefined,
              types: undefined,
              message: "Certificado IATF 16949:2016, click en la imagen para descargar"
            }}>

              <div>
                <a href="/files/certificates/certificado3.pdf" target="_blank">
                  <img src={certificado3Img} alt="Certificado" />
                </a>
              </div>

            </Tooltip>

            <Tooltip title={{
              type: 'pattern',
              ref: undefined,
              types: undefined,
              message: "Certificado ISO 9001:2015, click en la imagen para descargar"
            }}>

              <div>
                <a href="/files/certificates/certificado2.pdf" target="_blank">
                  <img src={certificado2Img} alt="Certificado" />
                </a>
              </div>

            </Tooltip>

          </Container>

          <Container data-aos="fade-up" data-aos-duration="1500">
            <div>
              {/* <Button link="/files/certificates/certificados.pdf" content="Click en la imagen o descarga aqui" /> */}
              <Button2>
                <a href="/files/certificates/certificados.pdf" target="_blank"> Click en la imagen o descarga aqui </a>
              </Button2>
            </div>
          </Container>
        </>
      )}
      {showAreaDoFornecedor && (
        <>
          <PlayerContainer>
            <ReactPlayer url="/files/webinar2022.mp4"
              width="100%"
              playing={true}
              muted={true}
              controls={true} />
          </PlayerContainer>
          <Title data-aos="fade" data-aos-duration="1500">
            <h2>Documentación</h2>
            <p>La relación del proveedor con Zanaflex es relevante.</p>
          </Title>
          <Container>
            <Square>
              <div className="supplier-location">
                <Button2>
                  <a href="/files/supplier-location/formulario-de-derrogas.doc" target="_blank"> Formulário de Renuncia </a>
                </Button2>
                <Button2>
                  <a href="/files/supplier-location/MQAFing.pdf" target="_blank"> MQAF </a>
                </Button2>
                <Button2>
                  <a href="/files/supplier-location/analise-de-risco-do-fornecedor.xls" target="_blank"> Análisis de riesgo </a>
                </Button2>
              </div>
            </Square>
          </Container>
        </>
      )}
      {showFISPQS && (
        <>
          <Title data-aos="fade" data-aos-duration="1500">
            <h2>FISPQS</h2>
            <p>Línea fina - La FISPQ (Hoja de Información de Seguridad para Productos Químicos) es un documento estandarizado por la Asociación Brasileña de Normas Técnicas (ABNT) de acuerdo con la norma ABNT-NBR 14725. Este documento, denominado "Hoja de Datos de Seguridad" según el Decreto n. 2657 del 03/07/1998 (promulga el Convenio No. 170 de la Organización Internacional del Trabajo-OIT), debe ser recibido por los empleadores que utilizan productos químicos, convirtiéndose en un documento obligatorio para la comercialización de estos productos.</p>
          </Title>
          <Container>
            <Square>
              <div className="families">
                <ul>
                  <li><a href="/files/fispqs/fispq_familia_bromobutil.zip" target="_blank" rel="noreferrer">Familia Bromobutil</a></li>
                  <li><a href="/files/fispqs/fispq_familia_clorobutil.zip" target="_blank" rel="noreferrer">Familia Clorobutil</a></li>
                  <li><a href="/files/fispqs/fispq_familia_estireno_butadieno_e_natural.zip" target="_blank" rel="noreferrer">Familia Estireno Butadieno e Natural</a></li>
                  <li><a href="/files/fispqs/fispq_familia_estireno_butadieno.zip" target="_blank" rel="noreferrer">Familia Estireno Butadieno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_etileno_acrilato.zip" target="_blank" rel="noreferrer">Familia Etileno Acrilato</a></li>
                  <li><a href="/files/fispqs/fispq_familia_etileno_propileno_dieno.zip" target="_blank" rel="noreferrer">Familia Etileno Propileno Dieno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_fluorelastomero.zip" target="_blank" rel="noreferrer">Familia Fluroelastômero</a></li>
                </ul>
                <ul>
                  <li><a href="/files/fispqs/fispq_familia_nitrilica.zip" target="_blank" rel="noreferrer">Familia Nitrílica</a></li>
                  <li><a href="/files/fispqs/fispq_familia_poli_butadieno.zip" target="_blank" rel="noreferrer">Familia Poli Butadieno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_poli_cloropreno.zip" target="_blank" rel="noreferrer">Familia Poli Cloropreno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_polietileno_clorado.zip" target="_blank" rel="noreferrer">Familia Polietileno Clorado</a></li>
                  <li><a href="/files/fispqs/fispq_familia_polietileno_cloro_sulfonado.zip" target="_blank" rel="noreferrer">Familia Polietileno Cloro Sulfonado</a></li>
                  <li><a href="/files/fispqs/fispq_familia_poli_isopreno_sintetico_e_ou_natural.zip" target="_blank" rel="noreferrer">Familia Poli Isopropeno Sintético e ou Natural</a></li>
                  <li><a href="/files/fispqs/fispq_familia_silicone.zip" target="_blank" rel="noreferrer">Familia Silicone</a></li>
                  <li><a href="/files/fispqs/fispq_nbr_pvc.zip" target="_blank" rel="noreferrer">Familia NBR PVC</a></li>
                </ul>
              </div>
            </Square>
          </Container>
        </>
      )}
    </Main>
  );
}

export function QualidadeUS({ showPoliticaDoSGIBool = true, showCertificacoesBool = false, showAreaDoFornecedorBool = false, showFISPQSBool = false }: QualidadeProps) {
  const [showPoliticaDoSGI, setShowPoliticaDoSGI] = useState(showPoliticaDoSGIBool);
  const [showCertificacoes, setShowCertificacoes] = useState(showCertificacoesBool);
  const [showAreaDoFornecedor, setShowAreaDoFornecedor] = useState(showAreaDoFornecedorBool);
  const [showFISPQS, setShowFISPQS] = useState(showFISPQSBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/qualidade/politica-do-sgi/en-US") {
      setShowAreaDoFornecedor(false);
      setShowCertificacoes(false);
      setShowFISPQS(false);
      setShowPoliticaDoSGI(true);
    }

    if (location.pathname === "/qualidade/certificados/en-US") {
      setShowAreaDoFornecedor(false);
      setShowCertificacoes(true);
      setShowFISPQS(false);
      setShowPoliticaDoSGI(false);
    }

    if (location.pathname === "/qualidade/area-fornecedor/en-US") {
      setShowAreaDoFornecedor(true);
      setShowCertificacoes(false);
      setShowFISPQS(false);
      setShowPoliticaDoSGI(false);
    }

    if (location.pathname === "/qualidade/fispqs/en-US") {
      setShowAreaDoFornecedor(false);
      setShowCertificacoes(false);
      setShowFISPQS(true);
      setShowPoliticaDoSGI(false);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Quality & Environment | Zanaflex - Rubber Compounds</title>
        <meta name="description" content="SGI Policy, Certifications, Supplier Area and FISPQS - Zanaflex" />
        <meta name="keywords" content="Quality, Environment, SGI Policy, Certifications, Supplier Area, MSDS, Zanaflex, Rubber, Rubber Compound" />

        <link rel="canonical" href="https://zanaflex.com.br/qualidade/en-US" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/qualidade" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/qualidade/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/qualidade/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/qualidade/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/qualidade/politica-do-sgi/en-US" className={showPoliticaDoSGI ? 'submenu-active' : 'submenu-disabled'} ><h1>SGI Policy</h1></Link>
        <Link to="/qualidade/certificados/en-US" className={showCertificacoes ? 'submenu-active' : 'submenu-disabled'}><h1>Certifications</h1></Link>
        <Link to="/qualidade/area-fornecedor/en-US" className={showAreaDoFornecedor ? 'submenu-active' : 'submenu-disabled'} ><h1>Supplier Area</h1></Link>
        <Link to="/qualidade/fispqs/en-US" className={showFISPQS ? 'submenu-active' : 'submenu-disabled'} ><h1>FISPQS</h1></Link>
      </SubMenu>
      {showPoliticaDoSGI && (
        <>
          <Title>
            <h2>Integrated management system</h2>
            <p>Quality, Environment, Health and Safety</p>
          </Title>
          <Container>
            <div data-aos="fade-right">
              <h4>Zanaflex Borrachas Ltda.</h4>
              <p>Zanaflex Borrachas Ltda. elastomeric compounds industry and its employees are committed to satisfying their customers and other interested parties, protecting the environment, reducing risks and continually improving their Integrated Management System, through:</p>
              <ul>
                <li>Compliance with legal and other applicable requirements;</li>
                <li>Prevention, reduction and correct disposal of waste;</li>
                <li>To provide safe and healthy working conditions;</li>
                <li>Prevention and reduction of occupational hazards and risks;</li>
                <li>Consultation and participation of workers;</li>
                <li>Continuous improvement of internal processes.</li>
              </ul>
            </div>
            <div>
              <img src={sgiImg} alt="Logo Zanaflex" />
            </div>
          </Container>
          <Terms className="terms">
            <p>General Terms and Conditions</p>
            <a href="/files/termos_e_condicoes_gerais.pdf" target="_blank" rel="noreferrer">
              Click here and see our General Terms and Conditions
            </a>
          </Terms>
          <AnimationContainer className="animation-container">
            <div className="proeficiencia" data-aos="zoom-in">
              <h3>Proficiency</h3>
              <p>Always committed to the quality of products and services, Zanaflex participates in the main proficiency programs among rubber testing laboratories. The evaluation of these analyzes are carried out by independent bodies (Senai and IPT) guaranteeing impartiality and demonstrating our ability to meet market requirements.</p>
            </div>
            <div className="laboratorio" data-aos="zoom-in-right">

            </div>
            <div className="grid" data-aos="zoom-in">
              <Glass className="grid-first">
                <div>
                  <div>
                    <Link to="/qualidade/certificados/en-US">Certificate</Link>
                    <Link to="/qualidade/certificados/en-US">ISO 9001-2008</Link>
                  </div>
                  <div>
                    <Link to="/qualidade/certificados/en-US">Development and Manufacture of Compounds and Molded Rubber Products.</Link>
                  </div>
                </div>
              </Glass>
              <Glass className="grid-second" data-aos="zoom-in">
                <div>
                  <div>
                    <Link to="/qualidade/politica-do-sgi/en-US">Environmental</Link>
                    <Link to="/qualidade/politica-do-sgi/en-US">Policy</Link>
                  </div>
                  <div>
                    <Link to="/qualidade/politica-do-sgi/en-US">Fully licensed and controlled environmental system.</Link>
                  </div>
                </div>
              </Glass>
              <Glass className="grid-third" data-aos="zoom-in">
                <div>
                  <div>
                    <Link to="/infraestrutura/laboratorios/en-US">Laboratories</Link>
                  </div>
                  <div>
                    <Link to="/infraestrutura/laboratorios/en-US">Highly equipped and capable of performing different types of analysis on our raw materials, compounds and vulcanized products.</Link>
                  </div>
                </div>
              </Glass>
              <div className="grid-fourth" data-aos="zoom-in">

              </div>
            </div>
            <div className="fispqs" data-aos="zoom-in-left">
              <h3>FISPQ’S</h3>
              <p>Material Safety Data Sheet.</p>
            </div>
          </AnimationContainer>
        </>
      )}
      {showCertificacoes && (
        <>
          <Title>
            <h2>Certificates</h2>
            <p>Zanaflex is IATF 16949:2016 and ISO 9001-2015 certified with emphasis on the development and supply of rubber compounds, in addition, it applies specific Quality tools such as APQP, MSA, PFMEA, PPAP.</p>
          </Title>
          <Container>
            <div data-aos="fade-down" data-aos-duration="1500">
              <ul>
                <li>In 1999 the company obtained the ISO 9002:1994 certificate</li>
                <li>In 2002 the company obtained the ISO 9001:2000 certificate</li>
                <li>In 2005 the company was certified according to ISO 9001:2000</li>
                <li>In 2008 the company was certified according to ISO 9001:2000</li>
                <li>In 2009 the company was certified according to ISO 9001:2008</li>
                <li>In 2017 the company was certified according to ISO 9001:2015</li>
                <li>In 2022 the company was certified according to IATF 16949:2016</li>
              </ul>
            </div>
          </Container>

          <Container data-aos="fade-up" data-aos-duration="1500">

            <Tooltip title={{
              type: 'pattern',
              ref: undefined,
              types: undefined,
              message: "Renewable Energy Certificate, click on the image to download"
            }}>

              <div>
                <a href="/files/certificates/certificado1.pdf" target="_blank">
                  <img src={certificado1Img} alt="Certificate" />
                </a>
              </div>

            </Tooltip>

            <Tooltip title={{
              type: 'pattern',
              ref: undefined,
              types: undefined,
              message: "Certificate IATF 16949:2016, click on the image to download"
            }}>

              <div>
                <a href="/files/certificates/certificado3.pdf" target="_blank">
                  <img src={certificado3Img} alt="Certificate" />
                </a>
              </div>

            </Tooltip>

            <Tooltip title={{
              type: 'pattern',
              ref: undefined,
              types: undefined,
              message: "Certificate ISO 9001:2015, click on the image to download"
            }}>

              <div>
                <a href="/files/certificates/certificado2.pdf" target="_blank">
                  <img src={certificado2Img} alt="Certificate" />
                </a>
              </div>

            </Tooltip>

          </Container>

          <Container data-aos="fade-up" data-aos-duration="1500">
            <div>
              {/* <Button link="/files/certificates/certificados.pdf" content="Click on the image or download here" /> */}
              <Button2>
                <a href="/files/certificates/certificados.pdf" target="_blank"> Click on the image or download here </a>
              </Button2>
            </div>
          </Container>
        </>
      )}
      {showAreaDoFornecedor && (
        <>
          <PlayerContainer>
            <ReactPlayer url="/files/webinar2022.mp4"
              width="100%"
              playing={true}
              muted={true}
              controls={true} />
          </PlayerContainer>
          <Title data-aos="fade" data-aos-duration="1500">
            <h2>Documentation</h2>
            <p>The supplier's relationship with Zanaflex is relevant</p>
          </Title>
          <Container>
            <Square>
              <div className="supplier-location">
                <Button2>
                  <a href="/files/supplier-location/formulario-de-derrogas.doc" target="_blank"> Waiver form - Supplier </a>
                </Button2>
                <Button2>
                  <a href="/files/supplier-location/MQAFing.pdf" target="_blank"> MQAF </a>
                </Button2>
                <Button2>
                  <a href="/files/supplier-location/analise-de-risco-do-fornecedor.xls" target="_blank"> Risk Analysis - Supplier </a>
                </Button2>
              </div>
            </Square>
          </Container>
        </>
      )}
      {showFISPQS && (
        <>
          <Title data-aos="fade" data-aos-duration="1500">
            <h2>FISPQS</h2>
            <p>Fine line - The FISPQ (Safety Information Sheet for Chemical Products) is a document standardized by the Brazilian Association of Technical Norms (ABNT) according to the standard, ABNT-NBR 14725. This document, called "Safety Data Sheet" according to Decree no. 2657 of 07/03/1998 (promulgates Convention No. 170 of the International Labor Organization-ILO), must be received by employers who use chemical products, becoming a mandatory document for the commercialization of these products.</p>
          </Title>
          <Container>
            <Square>
              <div className="families">
                <ul>
                  <li><a href="/files/fispqs/fispq_familia_bromobutil.zip" target="_blank" rel="noreferrer">Family Bromobutil</a></li>
                  <li><a href="/files/fispqs/fispq_familia_clorobutil.zip" target="_blank" rel="noreferrer">Family Clorobutil</a></li>
                  <li><a href="/files/fispqs/fispq_familia_estireno_butadieno_e_natural.zip" target="_blank" rel="noreferrer">Family Estireno Butadieno e Natural</a></li>
                  <li><a href="/files/fispqs/fispq_familia_estireno_butadieno.zip" target="_blank" rel="noreferrer">Family Estireno Butadieno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_etileno_acrilato.zip" target="_blank" rel="noreferrer">Family Etileno Acrilato</a></li>
                  <li><a href="/files/fispqs/fispq_familia_etileno_propileno_dieno.zip" target="_blank" rel="noreferrer">Family Etileno Propileno Dieno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_fluorelastomero.zip" target="_blank" rel="noreferrer">Family Fluroelastômero</a></li>
                </ul>
                <ul>
                  <li><a href="/files/fispqs/fispq_familia_nitrilica.zip" target="_blank" rel="noreferrer">Family Nitrílica</a></li>
                  <li><a href="/files/fispqs/fispq_familia_poli_butadieno.zip" target="_blank" rel="noreferrer">Family Poli Butadieno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_poli_cloropreno.zip" target="_blank" rel="noreferrer">Family Poli Cloropreno</a></li>
                  <li><a href="/files/fispqs/fispq_familia_polietileno_clorado.zip" target="_blank" rel="noreferrer">Family Polietileno Clorado</a></li>
                  <li><a href="/files/fispqs/fispq_familia_polietileno_cloro_sulfonado.zip" target="_blank" rel="noreferrer">Family Polietileno Cloro Sulfonado</a></li>
                  <li><a href="/files/fispqs/fispq_familia_poli_isopreno_sintetico_e_ou_natural.zip" target="_blank" rel="noreferrer">Family Poli Isopropeno Sintético e ou Natural</a></li>
                  <li><a href="/files/fispqs/fispq_familia_silicone.zip" target="_blank" rel="noreferrer"> Family Silicone</a></li>
                  <li><a href="/files/fispqs/fispq_nbr_pvc.zip" target="_blank" rel="noreferrer">Family NBR PVC</a></li>
                </ul>
              </div>
            </Square>
          </Container>
        </>
      )}
    </Main>
  );
}