import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  background-color: var(--color-white);
  box-shadow: 5px 5px var(--color-yellow);
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 70%;
  justify-content: center;
  align-items: center;
  padding: 3% 3%;
  margin: 3% 0;
`;

export const PrivacyPolicy = styled.section`
  p,li{
    strong{
    font-weight: bold;
    }
  }

  ul,ol li{
    list-style: inside
  }

  ul,ol, p+p{
    margin: 1.1rem 0;
  }

  h1{
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    margin: 2.2rem 0;
    }
  
  h2, h3{
    font-weight: 700;
    text-transform: uppercase;
    margin: 2.2rem 0;
    }
`;

export const TermsOfUsePolicy = styled.section`
  ul,ol li{
    list-style: inside
  }

  ul,ol, p+p{
    margin: 1.1rem 0;
  }

  h1{
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    margin: 2.2rem 0;
    }
  
  h2, h3{
    font-weight: 700;
    text-transform: uppercase;
    margin: 2.2rem 0;
    }
`;