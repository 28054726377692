import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import GlobalStyle from './styles/global';

ReactDOM.render(
  <BrowserRouter basename="/">
    <Navbar />
    <App />
    <Footer />
    <GlobalStyle />
  </BrowserRouter>,
  document.getElementById('root')
);
