import React, {
  ForwardRefRenderFunction,
  forwardRef,
} from 'react';
import { FieldError } from 'react-hook-form';

import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error } from './styles';

interface InputProps {
  name: string;
  label?: string;
  isTextArea?: boolean;
  error?: FieldError; 
} 

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({name, label, isTextArea = false, error = null, ...rest }, ref) => {
  return(
    <Container
      isTextArea={isTextArea}
      isErrored={!!error}>
      <input
        name={name}
        id={name}
        ref={ref}
        {...rest}
      />

      {!! error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export const Input = forwardRef(InputBase);
