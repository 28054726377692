import styled from 'styled-components';


export const Main = styled.main`
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100%;

  >div {
    padding: 16px 0px;
    width: 100%;
    border-bottom: 5px solid var(--color-gray);
  }

  >div:last-child{
    border:none;
  }

`;

export const SubMenu = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px;

  >a {
      font-family: 'Expansiva', sans-serif;
      cursor: pointer;
      margin: 16px;
      color: var(--color-gray);
      text-decoration: none;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      padding-bottom: 35px;

      &:hover{
        text-decoration: underline;
      }
    }

    .submenu-active{
      text-decoration: underline;
      color: var(--color-yellow);
    }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 70%;

  iframe{
    width: 100%;
    min-height: 80vh;
    min-width: 320px;
  }
  
  
  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50%;

    small{
    font-size: 16px;
    margin: 1.2rem;
    a{
      color: var(--color-yellow-dark);

      &:hover{
        color: var(--color-yellow);
        transition: 0.2s color;
      }
    }
  }  

    >h1:first-child {
      font-family: 'Expansiva', sans-serif;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      border-bottom: 5px solid var(--color-gray);
      padding-bottom: 35px;
    }

    >p, ul {
      padding: 0 16px;
      padding-top: 35px;
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      
    }

    img {
      padding: 32px;
    }

    @media screen and (max-width: 960px) {
      padding: 16px;
    }
  }

  @media screen and (max-width: 960px) {
    flex-wrap: wrap;

    img {
      width: 320px;
    }
  }
`;
