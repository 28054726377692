import React, { useEffect, useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import styled from 'styled-components';

const FormWrapper = styled.form`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  textarea {
    resize: vertical;
    height: 100px;
  }

  button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

interface FormError {
  field: string;
  message: string;
}

const TrabalheConoscoForm: React.FC = () => {
  const [formData, setFormData] = useState({
    email: '',
    message: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const localHandleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let hasErrors = false;
    const newErrors: { [key: string]: string } = {};

    if (!formData.email) {
      newErrors.email = 'Campo obrigatório';
      hasErrors = true;
    }

    if (!formData.message) {
      newErrors.message = 'Campo obrigatório';
      hasErrors = true;
    }

    if (hasErrors) {
      const errorArray: FormError[] = Object.entries(newErrors).map(([field, message]) => ({
        field,
        message,
      }));
      setFormErrors(errorArray);
      return;
    }

    try {
      // Código para enviar o formulário para o serviço Formspree
      const response = await fetch('https://formspree.io/f/mbjvlnvq', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // console.log('Formulário enviado com sucesso!');
        setIsFormSubmitted(true);
      } else {
        // console.error('Erro ao enviar o formulário.');
      }
    } catch (error) {
      // console.error('Erro ao enviar o formulário:', error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, handleSubmit] = useForm('mbjvlnvq');
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (state.succeeded) {
      setSubmitted(true);

      setTimeout(() => {
        window.location.href = 'https://zanaflex.com.br/';
      }, 3000); // 3 segundos
    } else if (state.errors) {
      // Submissão com erro
      const errorArray: FormError[] = Object.entries(state.errors).map(([field, message]) => ({
        field,
        message: message as unknown as string,
      }));
      setFormErrors(errorArray);
    }
  }, [state.succeeded, state.errors]);

  return (
    <FormWrapper onSubmit={localHandleSubmit}>
      {!isFormSubmitted && (
        <>
          <FormGroup>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {formErrors.map((error) =>
              error.field === 'email' ? <ValidationError errors={[]} key={error.field}>{error.message}</ValidationError> : null
            )}
          </FormGroup>

          <FormGroup>
            <label htmlFor="message">Insira o link do seu Linkedin ou portifólio</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
            />
            {formErrors.map((error) =>
              error.field === 'message' ? (
                <ValidationError errors={[]} key={error.field}>{error.message}</ValidationError>
              ) : null
            )}
          </FormGroup>

          <FormGroup>
            <button type="submit" disabled={state.submitting}>
              Submit
            </button>
          </FormGroup>
        </>
      )}

      {isFormSubmitted && <p>Obrigado por se juntar! Sua submissão foi bem-sucedida.</p>}
    </FormWrapper>
  );
};

export default TrabalheConoscoForm;