import React from 'react';
import AOS from 'aos';
import AppProvider from './hooks';
import Routes from './routes';

function App() {
  AOS.init()
  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  );
}

export default App;
