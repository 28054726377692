import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { DropdownMenu } from './styles';

type MenuItem = {
    title: string,
    path: string,
    cName: string,
  };

interface DropDownProps {
  menuItems: MenuItem[];
}

function Dropdown({ menuItems }:DropDownProps) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <DropdownMenu
        onClick={handleClick}
        className={click ? 'clicked' : ''}
      >
        {menuItems.map((item, index) => {
          if(item.title === "Trabalhe conosco" ||
          item.title === "Trabaja con nosotros" ||
          item.title === "Work with us"
          ){
            return (
              <li key={index}>
                {/* <a href="https://www.catho.com.br/empregos/zanaflex" target="_blank" rel="noreferrer"
                  className={item.cName}
                  onClick={() => setClick(false)}
                >
                  {item.title}
                </a> */}
                <Link
                  className={item.cName}
                  to={item.path}
                  onClick={() => setClick(false)}
                >
                  {item.title}
                </Link>
              </li>
            )
          } else {
            return (
              <li key={index}>
                <Link
                  className={item.cName}
                  to={item.path}
                  onClick={() => setClick(false)}
                >
                  {item.title}
                </Link>
              </li>
            );
          }
        })}
      </DropdownMenu>
    </>
  );
}

export default Dropdown;
