import styled from 'styled-components';


export const Main = styled.main`
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  >div + div {
    padding: 64px 0px;
    border-bottom: 5px solid var(--color-gray);
  }

  >div:last-child{
    border:none;
  }
`;

export const SubMenu = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px;

  >a {
      font-family: 'Expansiva', sans-serif;
      cursor: pointer;
      margin: 16px;
      color: var(--color-gray);
      text-decoration: none;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      padding-bottom: 35px;

      &:hover{
        text-decoration: underline;
      }
    }

    .submenu-active{
      text-decoration: underline;
      color: var(--color-yellow);
    }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 70%;
  
  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50%;
    padding: 16px;

    >h2:first-child {
      font-family: 'Expansiva', sans-serif;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      border-bottom: 5px solid var(--color-gray);
      padding-bottom: 35px;

      small{
        font-size: 16px;
      }
    }

    >img + h2:last-child {
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      border-top: 5px solid var(--color-gray);
      padding-top: 10px;
      padding-bottom: 35px;

      small{
        font-size: 16px;
      }
    }

    >p, ul {
      padding: 0 16px;
      padding-top: 35px;
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      
    }

    img {
      width: 100%;
      padding: 32px;
    }

    @media screen and (max-width: 960px) {
      padding: 16px;
    }
  }

  @media screen and (max-width: 960px) {
    flex-wrap: wrap;

    img {
      width: 320px;
    }
  }
`;

export const FormatListItem = styled.div`
  display: flex;
  flex-wrap: wrap;

  >div:first-child {
    margin-top: 32px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    img {
      padding: 0;
      width: 100px;
    }
  }
  
  >div + div {
    display: flex;
    flex-direction: column;
    padding: 24px;

    >h3 {
      font-size: 20px;
      margin: 12px;
    }

    >p + p{
      font-size: 15px;
    }
    
  }
  @media screen and (min-width: 960px) {
      flex-wrap: nowrap;

    }

  @media screen and (max-width: 960px) {
    >div:first-child {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

    img {
      width: 50%;
      }
    }
  }
`;
