import styled from 'styled-components';


export const Main = styled.main`
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  >div {
    padding: 64px 0px;
    border-bottom: 5px solid var(--color-gray);
  }

  >div:last-child{
    border:none;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 70%;


  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50%;
    padding: 1px;

    >h2:first-child {
      font-family: 'Expansiva', sans-serif;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      border-bottom: 5px solid var(--color-gray);
      padding-bottom: 35px;
    }

    >p, ul {
      padding: 0 16px;
      padding-top: 35px;
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      
    }

    img {
      padding: 32px;
    }

    .cultura {
      width: 100%;
      padding: 2px;
    }

    @media screen and (max-width: 960px) {
      padding: 16px;

      .cultura {
        width: 320px;
      }
    }
  }

  @media screen and (max-width: 960px) {
    flex-wrap: wrap;

    img {
      width: 320px;
    }
  }
`;

export const Terms = styled.div`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  width: 70%;

  h3{
    font-size: 26px;
  }

  a{
    color: var(--color-gray);
    text-decoration: none;

    &:hover{
        text-decoration: underline;
      }
  }
`;