import styled from 'styled-components';


export const Main = styled.main`
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  >div + div {
    padding: 64px 0px;
    border-bottom: 5px solid var(--color-gray);
  }

  >div:last-child{
    border:none;
  }

  
`;
// export const PlayerContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   background-color: var(--color-gray);
// `;


export const Carousel = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: var(--color-gray);
  z-index: 0;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 70%;
  
  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50%;
    padding: 16px;

    >h1:first-child {
      font-family: 'Expansiva', sans-serif;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      border-bottom: 5px solid var(--color-gray);
      padding-bottom: 35px;
    }

    >p, ul {
      padding: 0 16px;
      padding-top: 35px;
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      
    }

    img {
      padding: 32px;
    }

    @media screen and (max-width: 960px) {
      padding: 16px;
    }
  }

  @media screen and (max-width: 960px) {
    flex-wrap: wrap;

    img {
      width: 320px;
    }
  }
`;

export const Square = styled.div`
  border: 1px solid var(--color-gray);

  img {
    width: 150px;
  }
`;

export const DivImgModal = styled.div`
  color: black; 
  font-family: "Mitr", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-top: -80px;
  margin-left: 10px;
  font-size: 18px;

  @media screen and (max-width: 1400px) {
    margin-top: -70px;
    margin-left: 4px;
    font-size: 14px;
  };
  @media screen and (max-width: 1200px) {
    margin-top: -50px;
    margin-left: 4px;
    font-size: 12px;
  };
  @media screen and (max-width: 1000px) {
    margin-top: -40px;
    margin-left: 4px;
    font-size: 10px;
  };
  @media screen and (max-width: 800px) {
    margin-top: -36px;
    margin-left: 4px;
    font-size: 8px;
  };
  a {
    color: #000DA8; 
    }
`