import React from 'react';
import { Container } from './styles'

type TimeLineProps = {
  lang: "pt-BR" | "en-US" | "es-ES";
}

export default function TimeLine({ lang }: TimeLineProps) {
  return (
    <Container>
      <div className="timeline-left">
        <div className="card left" data-aos="fade-right"><p>1968</p>
          {lang === "en-US" ? (<>Beginning of activities in the city of São Paulo</>)
            : lang === "es-ES" ? (<>Inicio de actividades en la ciudad de São Paulo</>)
              : (<>Início das atividades na cidade de São Paulo</>)}
        </div>
      </div>
      <div className="timeline-right">
        <div className="card right" data-aos="fade-left"><p>1978</p>
          {lang === "en-US" ? (<>The company moved to the city of Cotia with excellent logistical conditions</>)
            : lang === "es-ES" ? (<>La empresa se trasladó a la ciudad de Cotia con excelentes condiciones logísticas</>)
              : (<>Mudança para a cidade de Cotia com excelentes condições logísticas</>)}</div>
      </div>
      <div className="timeline-left">
        <div className="card left" data-aos="fade-right"><p>1992</p>
          {lang === "en-US" ? (<>Start of production of custom rubber compounds</>)
            : lang === "es-ES" ? (<>Inicio de la producción de compuestos de caucho personalizados</>)
              : (<>Início da produção de compostos de borracha personalizados</>)}</div>
      </div>
      <div className="timeline-right">
        <div className="card right" data-aos="fade-left"><p>1999</p>
          {lang === "en-US" ? (<>ISO 9002:1994 Certification</>)
            : lang === "es-ES" ? (<>Certificación ISO 9002:1994</>)
              : (<>Conquistamos a Certificação ISO 9002:1994</>)}
        </div>
      </div>
      <div className="timeline-left">
        <div className="card left" data-aos="fade-right"><p>2002</p>
          {lang === "en-US" ? (<>The first Intermix mixer manufactured in Brazil</>)
            : lang === "es-ES" ? (<>La primera mezcladora Intermix fabricada en Brasil</>)
              : (<>O primeiro misturador Intermix fabricado no Brasil</>)}
        </div>
      </div>
      <div className="timeline-right">
        <div className="card right" data-aos="fade-left"><p>2005</p>
          {lang === "en-US" ? (<>Factory expansion, new warehouse and new installations for black humus and automatic weighing</>)
            : lang === "es-ES" ? (<>Ampliación de fábrica, nuevo almacén y nuevas instalaciones para negro de humo y pesaje automático</>)
              : (<>Expansão da fábrica, novo armazém e novas instalações para negro de fumo e pesagem automática</>)}
        </div>
      </div>
      <div className="timeline-left">
        <div className="card left" data-aos="fade-right"><p>2007</p>
          {lang === "en-US" ? (<>Introduction of the fifth line of rubber blend</>)
            : lang === "es-ES" ? (<>Introducción de la quinta línea de mezcla de caucho</>)
              : (<>Inauguração da 5° linha de mistura de borracha</>)}
        </div>
      </div>
      <div className="timeline-right">
        <div className="card right" data-aos="fade-left"><p>2009</p>
          {lang === "en-US" ? (<>50% increase in rubber mixing capacity</>)
            : lang === "es-ES" ? (<>50% de aumento en la capacidad de mezcla de caucho</>)
              : (<>Aumento de 50% da capacidade de mistura de borracha</>)}</div>
      </div>
      <div className="timeline-left">
        <div className="card left" data-aos="fade-right"><p>2012</p>
          {lang === "en-US" ? (<>Migration to ERP - SAP ECC 6.0 – Best practices</>)
            : lang === "es-ES" ? (<>Migración a ERP - SAP ECC 6.0 – Mejores prácticas</>)
              : (<>Migração para ERP - SAP ECC 6.0 – Melhores práticas</>)}
        </div>
      </div>
      <div className="timeline-right">
        <div className="card right" data-aos="fade-left"><p>2017</p>
          {lang === "en-US" ? (<>Update for ISO 9001:2015 certification</>)
            : lang === "es-ES" ? (<>Actualización para la certificación ISO 9001:2015</>)
              : (<>Atualização para a certificação ISO 9001:2015</>)}
        </div>
      </div>
      <div className="timeline-left">
        <div className="card left" data-aos="fade-right"><p>2021</p>
          {lang === "en-US" ? (<>Opening of the 7th processing line</>)
            : lang === "es-ES" ? (<>Apertura de la séptima línea de procesamiento</>)
              : (<>Inauguração da 7° linha de processamento</>)}
        </div>
      </div>
      <div className="timeline-right">
        <div className="card right" data-aos="fade-left"><p>2022</p>
          {lang === "en-US" ? (<>We achieved IATF16949:2016 Certification and Renewable Energy Seal</>)
            : lang === "es-ES" ? (<>Logramos la Certificación IATF16949:2016 y el Sello de Energías Renovables</>)
              : (<>Conquistamos a Certificação IATF16949:2016 e Selo de Energia Renovável</>)}
        </div>
      </div>
      <div className="timeline-left">
        <div className="card left" data-aos="fade-right"><p>2023</p>
          {lang === "en-US" ? (<>18th Top Rubber recognition</>)
            : lang === "es-ES" ? (<>18º reconocimiento Top Rubber</>)
              : (<>18º reconhecimento Top Rubber</>)}
        </div>
      </div>
    </Container>
  );
}