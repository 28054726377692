import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import firstImg from '../../assets/carousel/saida-de-compostos/1.png';
import secondImg from '../../assets/carousel/saida-de-compostos/2.png';
import thirdImg from '../../assets/carousel/saida-de-compostos/3.png';
import fourthImg from '../../assets/carousel/saida-de-compostos/4.png';
import fiveImg from '../../assets/carousel/saida-de-compostos/5.png';
import wigwagImg from '../../assets/wigwag.png';
import mantasImg from '../../assets/mantas.png';
import tirasImg from '../../assets/tiras.png';
import tirasPerfuradasImg from '../../assets/tiras-perfuradas.png';
import preFormadosImg from '../../assets/pre-formados.png';
import granuladoImg from '../../assets/granulado.png';
import calandradoImg from '../../assets/calandrado.png';
import armazenamentoImg from '../../assets/armazenamento.png';
import embalagensImg from '../../assets/embalagens.png';
import fornecedoresMapImg from '../../assets/fornecedores-map.png';

import { Main, Container, SubMenu, FormatListItem } from './styles';
import SlickSlider from '../../components/Slider';
import { Helmet } from 'react-helmet';
// import ReactPlayer from 'react-player';

type LogisticaProps = {
  showFornecedoresBool?: boolean;
  showArmazenamentoBool?: boolean;
  showSaidaDosCompostosBool?: boolean;
  showFormatosDosCompostosBool?: boolean;
}

export function Logistica({ showFornecedoresBool = true, showArmazenamentoBool = false, showSaidaDosCompostosBool = false, showFormatosDosCompostosBool = false }: LogisticaProps) {
  const [showFornecedores, setShowFornecedores] = useState(showFornecedoresBool);
  const [showArmazenamento, setShowArmazenamento] = useState(showArmazenamentoBool);
  const [showSaidaDosCompostos, setShowSaidaDosCompostos] = useState(showSaidaDosCompostosBool);
  const [showFormatosDosCompostos, setShowFormatosDosCompostos] = useState(showFormatosDosCompostosBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/logistica/fornecedores") {
      setShowFornecedores(true);
      setShowArmazenamento(false);
      setShowSaidaDosCompostos(false);
      setShowFormatosDosCompostos(false);
    }

    if (location.pathname === "/logistica/armazenamento") {
      setShowFornecedores(false);
      setShowArmazenamento(true);
      setShowSaidaDosCompostos(false);
      setShowFormatosDosCompostos(false);
    }

    if (location.pathname === "/logistica/saida-dos-compostos") {
      setShowFornecedores(false);
      setShowArmazenamento(false);
      setShowSaidaDosCompostos(true);
      setShowFormatosDosCompostos(false);
    }

    if (location.pathname === "/logistica/formatos-dos-compostos") {
      setShowFornecedores(false);
      setShowArmazenamento(false);
      setShowSaidaDosCompostos(false);
      setShowFormatosDosCompostos(true);
    }
  }, [location]);

  return (
    <Main >
      <Helmet>
        <title>Logística | Zanaflex - Compostos de Borracha</title>
        <meta name="description" content="Fornecedores, Armazenamento, Saída dos Compostos e Formatos dos Compostos - Zanaflex" />
        <meta name="keywords" content="Fornecedores, Armazenamento, Saída dos Compostos, Formatos dos Compostos, Zanaflex, Borracha, Composto de Borracha" />

        <link rel="canonical" href="https://zanaflex.com.br/logistica" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/logistica" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/logistica/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/logistica/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/logistica/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/logistica/fornecedores" className={showFornecedores ? 'submenu-active' : 'submenu-disabled'} ><h1>Fornecedores</h1></Link>
        <Link to="/logistica/armazenamento" className={showArmazenamento ? 'submenu-active' : 'submenu-disabled'}><h1>Armazenamento</h1></Link>
        <Link to="/logistica/saida-dos-compostos" className={showSaidaDosCompostos ? 'submenu-active' : 'submenu-disabled'} ><h1>Saida dos Compostos</h1></Link>
        <Link to="/logistica/formatos-dos-compostos" className={showFormatosDosCompostos ? 'submenu-active' : 'submenu-disabled'} ><h1>Formatos dos Compostos</h1></Link>
      </SubMenu>
      {showFornecedores && (
        <>
          <Container data-aos="fade-right">
            <div>
              <h2>Fornecedores Internacionais</h2>
              <p>A parceria com os principais fornecedores mundiais é a garantia no desenvolvimento de produtos de alta qualidade e durabilidade.</p>
            </div>
          </Container>
          <Container data-aos="fade-right">
            <div>
              <img src={fornecedoresMapImg} alt="Mapa de Fornecedores" />
            </div>
          </Container>
        </>
      )}
      {showArmazenamento && (
        <Container data-aos="fade-left">
          <div>
            <h2>Armazenamento</h2>
            <p>O armazenamento das matérias-primas é feito em ambientes com temperatura rigorosamente controlada, bem como o controle dos prazos de validade. Respeitando as peculiaridades de cada insumo, desenvolvemos embalagens específicas para o melhor armazenamento, como Paletes de Madeira, Caixas e Barris de Papelão Reforçado, Caixas Plásticas Retornáveis e Caixas de Metal, tudo para garantir altíssima qualidade e performance na produção dos compostos de borracha.
            </p>
          </div>
          <div>
            <img src={armazenamentoImg} alt="Armazenamento Zanaflex" />
          </div>
        </Container>
      )}
      {showSaidaDosCompostos && (
        <Container data-aos="fade-right">
          <div>
            <h2>Saída do composto <br />
              <small>A logistíca de distribuição é pensada de forma...</small>
            </h2>
            <SlickSlider
              firstImgURL={firstImg}
              secondImgURL={secondImg}
              thirdImgURL={thirdImg}
              fourthImgURL={fourthImg}
              fiveImgURL={fiveImg} />
          </div>
          <div>
            <img src={embalagensImg} alt="Embalagens de distribuição" />
            <h2>Embalagens de distribuição<br />
              <small>Paletes de Madeira, Caixas de papelão reforçado, Grades de plástico retornáveis, Caixas de metal retornáveis.</small>
            </h2>
          </div>
        </Container>
      )}
      {showFormatosDosCompostos && (
        <Container data-aos="fade-left">
          <div>
            <h2>Formatos dos Compostos <br />
              <small>A logistíca de distribuição é pensada de forma...</small>
            </h2>
            <FormatListItem>
              <div>
                <img src={wigwagImg} alt="Wigwag" />
              </div>
              <div>
                <h3>WigWag</h3>
                <p>Borracha em manta contínua acondicionada em sistema wig-wag, sobre palete.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={mantasImg} alt="Mantas" />
              </div>
              <div>
                <h3>Mantas</h3>
                <p>Sistemas de corte em mantas separadas em lâminas de acordo com o tamanho especificado.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={tirasImg} alt="Tiras" />
              </div>
              <div>
                <h3>Tiras</h3>
                <p>Cortadas em espessuras e comprimentos pré-determinados.</p>
              </div>
            </FormatListItem>
          </div>
          <div>
            <FormatListItem>
              <div>
                <img src={tirasPerfuradasImg} alt="Tiras Perfuradas" />
              </div>
              <div>
                <h3>Tiras Perfuradas</h3>
                <p>As tiras perfuradas são muito versáteis pois apresentam extrema flexibilidade e resistência, facilitando seu uso em diversas aplicações.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={preFormadosImg} alt="Pre Formados" />
              </div>
              <div>
                <h3>Pré-formados</h3>
                <p>Fabricados de acordo com as especificações técnicas de cada cliente a fim de atender as exigências requeridas em suas aplicações.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={granuladoImg} alt="Granulado" />
              </div>
              <div>
                <h3>Granulado</h3>
                <p>O composto de borracha pode ser granulado em equipamentos especiais. E para evitar sua aglomeração, ao material granulado é aplicado um agente de superfície.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={calandradoImg} alt="Calandrado" />
              </div>
              <div>
                <h3>Calandrado</h3>
                <p>Compostos de borracha acelerados e não acelerados, calandrados à base de NR, SBR, NBR, EPDM, BR, CR, entre outros.</p>
              </div>
            </FormatListItem>
          </div>
        </Container>
      )}
    </Main>
  );
}

export function LogisticaES({ showFornecedoresBool = true, showArmazenamentoBool = false, showSaidaDosCompostosBool = false, showFormatosDosCompostosBool = false }: LogisticaProps) {
  const [showFornecedores, setShowFornecedores] = useState(showFornecedoresBool);
  const [showArmazenamento, setShowArmazenamento] = useState(showArmazenamentoBool);
  const [showSaidaDosCompostos, setShowSaidaDosCompostos] = useState(showSaidaDosCompostosBool);
  const [showFormatosDosCompostos, setShowFormatosDosCompostos] = useState(showFormatosDosCompostosBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/logistica/fornecedores/es-ES") {
      setShowFornecedores(true);
      setShowArmazenamento(false);
      setShowSaidaDosCompostos(false);
      setShowFormatosDosCompostos(false);
    }

    if (location.pathname === "/logistica/armazenamento/es-ES") {
      setShowFornecedores(false);
      setShowArmazenamento(true);
      setShowSaidaDosCompostos(false);
      setShowFormatosDosCompostos(false);
    }

    if (location.pathname === "/logistica/saida-dos-compostos/es-ES") {
      setShowFornecedores(false);
      setShowArmazenamento(false);
      setShowSaidaDosCompostos(true);
      setShowFormatosDosCompostos(false);
    }

    if (location.pathname === "/logistica/formatos-dos-compostos/es-ES") {
      setShowFornecedores(false);
      setShowArmazenamento(false);
      setShowSaidaDosCompostos(false);
      setShowFormatosDosCompostos(true);
    }
  }, [location]);

  return (
    <Main >
      <Helmet>
        <title>Logística | Zanaflex - Compuestos de Caucho</title>
        <meta name="description" content="Proveedores, Almacenamiento, Salida Compuesta y Formatos Compuestos - Zanaflex" />
        <meta name="keywords" content="Proveedores, Almacenamiento, Salida de compuestos, Formatos compuestos, Zanaflex, Caucho, Compuesto de Caucho" />

        <link rel="canonical" href="https://zanaflex.com.br/logistica/es-ES" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/logistica" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/logistica/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/logistica/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/logistica/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/logistica/fornecedores/es-ES" className={showFornecedores ? 'submenu-active' : 'submenu-disabled'} ><h1>Proveedores</h1></Link>
        <Link to="/logistica/armazenamento/es-ES" className={showArmazenamento ? 'submenu-active' : 'submenu-disabled'}><h1>Almacenamiento</h1></Link>
        <Link to="/logistica/saida-dos-compostos/es-ES" className={showSaidaDosCompostos ? 'submenu-active' : 'submenu-disabled'} ><h1>Salida compuest</h1></Link>
        <Link to="/logistica/formatos-dos-compostos/es-ES" className={showFormatosDosCompostos ? 'submenu-active' : 'submenu-disabled'} ><h1>Formatos compuestos</h1></Link>
      </SubMenu>
      {showFornecedores && (
        <>
          <Container data-aos="fade-right">
            <div>
              <h2>Proveedores internacionales</h2>
              <p>La asociación con los principales proveedores mundiales es la garantía en el desarrollo de productos duraderos y de alta calidad.</p>
            </div>
          </Container>
          <Container data-aos="fade-right">
            <div>
              <img src={fornecedoresMapImg} alt="Mapa de proveedores" />
            </div>
          </Container>
        </>
      )}
      {showArmazenamento && (
        <Container data-aos="fade-left">
          <div>
            <h2>Almacenamiento</h2>
            <p>Las materias primas se almacenan en ambientes de temperatura estrictamente controlada, así como control de fechas de caducidad. Respetando las peculiaridades de cada insumo, desarrollamos empaques específicos para el mejor almacenamiento, como Paletas de Madera, Cajas y Barriles de Cartón Reforzado, Cajas de Plástico Retornables y Cajas Metálicas, todo para garantizar la más alta calidad y desempeño en la producción de compuestos de caucho.
            </p>
          </div>
          <div>
            <img src={armazenamentoImg} alt="Almacenamiento Zanaflex" />
          </div>
        </Container>
      )}
      {showSaidaDosCompostos && (
        <Container data-aos="fade-right">
          <div>
            <h2>Salida de compost<br />
              <small>La logística de distribución está diseñada de manera...</small>
            </h2>
            <SlickSlider
              firstImgURL={firstImg}
              secondImgURL={secondImg}
              thirdImgURL={thirdImg}
              fourthImgURL={fourthImg}
              fiveImgURL={fiveImg} />
          </div>
          <div>
            <img src={embalagensImg} alt="Embalaje de distribución" />
            <h2>Embalaje de distribución<br />
              <small>Palets de madera, Cajas de cartón reforzado, Cajas de plástico retornables, Cajas metálicas retornables.</small>
            </h2>
          </div>
        </Container>
      )}
      {showFormatosDosCompostos && (
        <Container data-aos="fade-left">
          <div>
            <h2>Formatos compuestos<br />
              <small>La logística de distribución está diseñada de manera...</small>
            </h2>
            <FormatListItem>
              <div>
                <img src={wigwagImg} alt="Wigwag" />
              </div>
              <div>
                <h3>WigWag</h3>
                <p>Caucho en manta continua embalado en sistema wig-wag, sobre palet.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={mantasImg} alt="Mantas" />
              </div>
              <div>
                <h3>Mantas</h3>
                <p>Sistemas de corte en mantillas separadas en cuchillas según el tamaño especificado.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={tirasImg} alt="Tiras" />
              </div>
              <div>
                <h3>Tiras</h3>
                <p>Corte a espesores y longitudes predeterminados.</p>
              </div>
            </FormatListItem>
          </div>
          <div>
            <FormatListItem>
              <div>
                <img src={tirasPerfuradasImg} alt="Tiras perforadas" />
              </div>
              <div>
                <h3>Tiras perforadas</h3>
                <p>Las tiras perforadas son muy versátiles ya que tienen extrema flexibilidad y resistencia, facilitando su uso en diversas aplicaciones.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={preFormadosImg} alt="Pre Formados" />
              </div>
              <div>
                <h3>Pré-formados</h3>
                <p>Fabricados según las especificaciones técnicas de cada cliente con el fin de cumplir con los requisitos exigidos en sus aplicaciones.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={granuladoImg} alt="Granulado" />
              </div>
              <div>
                <h3>Granulado</h3>
                <p>El compuesto de caucho se puede granular en equipos especiales. Y para evitar su aglomeración, se aplica un agente de superficie al material granulado.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={calandradoImg} alt="Calandrado" />
              </div>
              <div>
                <h3>Calandrado</h3>
                <p>Compuestos de caucho acelerados y no acelerados, calandrados a base de NR, SBR, NBR, EPDM, BR, CR, entre otros.</p>
              </div>
            </FormatListItem>
          </div>
        </Container>
      )}
    </Main>
  );
}


export function LogisticaUS({ showFornecedoresBool = true, showArmazenamentoBool = false, showSaidaDosCompostosBool = false, showFormatosDosCompostosBool = false }: LogisticaProps) {
  const [showFornecedores, setShowFornecedores] = useState(showFornecedoresBool);
  const [showArmazenamento, setShowArmazenamento] = useState(showArmazenamentoBool);
  const [showSaidaDosCompostos, setShowSaidaDosCompostos] = useState(showSaidaDosCompostosBool);
  const [showFormatosDosCompostos, setShowFormatosDosCompostos] = useState(showFormatosDosCompostosBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/logistica/fornecedores/en-US") {
      setShowFornecedores(true);
      setShowArmazenamento(false);
      setShowSaidaDosCompostos(false);
      setShowFormatosDosCompostos(false);
    }

    if (location.pathname === "/logistica/armazenamento/en-US") {
      setShowFornecedores(false);
      setShowArmazenamento(true);
      setShowSaidaDosCompostos(false);
      setShowFormatosDosCompostos(false);
    }

    if (location.pathname === "/logistica/saida-dos-compostos/en-US") {
      setShowFornecedores(false);
      setShowArmazenamento(false);
      setShowSaidaDosCompostos(true);
      setShowFormatosDosCompostos(false);
    }

    if (location.pathname === "/logistica/formatos-dos-compostos/en-US") {
      setShowFornecedores(false);
      setShowArmazenamento(false);
      setShowSaidaDosCompostos(false);
      setShowFormatosDosCompostos(true);
    }
  }, [location]);

  return (
    <Main >
      <Helmet>
        <title>Logistics | Zanaflex - Rubber Compounds</title>
        <meta name="description" content="Suppliers, Storage, Compound Output and Compound Formats - Zanaflex" />
        <meta name="keywords" content="Suppliers, Storage, Compound Output, Compound Formats, Zanaflex, Rubber, Rubber Compound" />

        <link rel="canonical" href="https://zanaflex.com.br/logistica/en-US" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/logistica" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/logistica/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/logistica/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/logistica/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/logistica/fornecedores/en-US" className={showFornecedores ? 'submenu-active' : 'submenu-disabled'} ><h1>Suppliers</h1></Link>
        <Link to="/logistica/armazenamento/en-US" className={showArmazenamento ? 'submenu-active' : 'submenu-disabled'}><h1>Storage</h1></Link>
        <Link to="/logistica/saida-dos-compostos/en-US" className={showSaidaDosCompostos ? 'submenu-active' : 'submenu-disabled'} ><h1>Compost output</h1></Link>
        <Link to="/logistica/formatos-dos-compostos/en-US" className={showFormatosDosCompostos ? 'submenu-active' : 'submenu-disabled'} ><h1>Compound Formats</h1></Link>
      </SubMenu>
      {showFornecedores && (
        <>
          <Container data-aos="fade-right">
            <div>
              <h2>International providers</h2>
              <p>The partnership with the main world suppliers is the guarantee in the development of high quality and durable products.</p>
            </div>
          </Container>
          <Container data-aos="fade-right">
            <div>
              <img src={fornecedoresMapImg} alt="Providers Map" />
            </div>
          </Container>
        </>
      )}
      {showArmazenamento && (
        <Container data-aos="fade-left">
          <div>
            <h2>Storage</h2>
            <p>Raw materials are stored in strictly controlled temperature environments, as well as control of expiration dates. Respecting the peculiarities of each input, we develop specific packaging for the best storage, such as Wooden Pallets, Reinforced Cardboard Boxes and Barrels, Returnable Plastic Boxes and Metal Boxes, all to guarantee the highest quality and performance in the production of rubber compounds.
            </p>
          </div>
          <div>
            <img src={armazenamentoImg} alt="Zanaflex Storage" />
          </div>
        </Container>
      )}
      {showSaidaDosCompostos && (
        <Container data-aos="fade-right">
          <div>
            <h2>Compost output <br />
              <small>Distribution logistics is designed in a way...</small>
            </h2>
            <SlickSlider
              firstImgURL={firstImg}
              secondImgURL={secondImg}
              thirdImgURL={thirdImg}
              fourthImgURL={fourthImg}
              fiveImgURL={fiveImg} />
          </div>
          <div>
            <img src={embalagensImg} alt="Distribution packaging" />
            <h2>Distribution packaging<br />
              <small>Wooden pallets, Reinforced cardboard boxes, Returnable plastic crates, Returnable metal boxes.</small>
            </h2>
          </div>
        </Container>
      )}
      {showFormatosDosCompostos && (
        <Container data-aos="fade-left">
          <div>
            <h2>Compound Formats <br />
              <small>Distribution logistics is designed in a way...</small>
            </h2>
            <FormatListItem>
              <div>
                <img src={wigwagImg} alt="Wigwag" />
              </div>
              <div>
                <h3>WigWag</h3>
                <p>Rubber in continuous blanket packed in wig-wag system, on pallet.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={mantasImg} alt="Blankets" />
              </div>
              <div>
                <h3>Blankets</h3>
                <p>Cutting systems in blankets separated into blades according to the specified size.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={tirasImg} alt="Strips" />
              </div>
              <div>
                <h3>Strips</h3>
                <p>Cut to predetermined thicknesses and lengths.</p>
              </div>
            </FormatListItem>
          </div>
          <div>
            <FormatListItem>
              <div>
                <img src={tirasPerfuradasImg} alt="Perforated strips" />
              </div>
              <div>
                <h3>Perforated strips</h3>
                <p>The perforated strips are very versatile as they have extreme flexibility and resistance, facilitating their use in various applications.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={preFormadosImg} alt="Preformed" />
              </div>
              <div>
                <h3>Preformed</h3>
                <p>Manufactured according to the technical specifications of each customer in order to meet the requirements required in their applications.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={granuladoImg} alt="Granulate" />
              </div>
              <div>
                <h3>Granulate</h3>
                <p>The rubber compound can be granulated in special equipment. And to prevent its agglomeration, a surface agent is applied to the granulated material.</p>
              </div>
            </FormatListItem>
            <FormatListItem>
              <div>
                <img src={calandradoImg} alt="Calendered" />
              </div>
              <div>
                <h3>Calendered</h3>
                <p>Accelerated and non-accelerated rubber compounds, calendered based on NR, SBR, NBR, EPDM, BR, CR, among others.</p>
              </div>
            </FormatListItem>
          </div>
        </Container>
      )}
    </Main>
  );
}
