import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  text-align: center;
  padding: 5px;
  

  a, button{
    -webkit-appearance: none;
    margin: 0;
    border: none;
    overflow: visible;
    color: var(--color-gray);
    text-transform: uppercase;
    display: inline-block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 20px;
    background: transparent;
    vertical-align: middle;
    line-height: 40px;
    min-height: 40px;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    border-radius: 25px;
    border: 2px solid var(--color-gray);
    font-weight: normal;
    box-shadow: inset 0 0 0 0 transparent;

    &:hover{
      background:var(--color-gray);
      color: var(--color-white);
      transition: all 0.2s ease-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
  }

`;