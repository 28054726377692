import React, {
  ForwardRefRenderFunction,
  forwardRef,
} from 'react';
import { FieldError } from 'react-hook-form';

import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error } from './styles';

interface SelectProps {
  name: string;
  label?: string;
  error?: FieldError; 
  children?: React.ReactNode;
} 

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = ({name, label, error = null, children, ...rest }, ref) => {
  return(
    <Container
      isErrored={!!error}>
      <select
        name={name}
        id={name}
        ref={ref}
        {...rest}
      >
        {children}
      </select>

      {!! error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export const Select = forwardRef(SelectBase);
