import { Routes as ReactRouterRoutes, Route } from "react-router-dom";

import { Home, HomeES, HomeUS } from "./pages/Home";
import { Institucional, InstitucionalES, InstitucionalUS } from "./pages/Institucional";
import { Infraestrutura, InfraestruturaES, InfraestruturaUS } from "./pages/Infraestrutura";
import { DT, DTES, DTUS } from "./pages/DT";
import { Qualidade , QualidadeES, QualidadeUS } from "./pages/Qualidade";
import { Logistica, LogisticaES, LogisticaUS } from "./pages/Logistica";
import { Produtos, ProdutosES, ProdutosUS} from "./pages/Produtos";
import { Contato, ContatoES, ContatoUS } from "./pages/Contato";
import { Links, LinksES, LinksUS } from "./pages/Links";
import { Politicas, PoliticasES, PoliticasUS } from "./pages/Politicas";
import { Pesquisa } from "./pages/Pesquisa";


export default function Routes() {
  return (
    <ReactRouterRoutes>
        <Route path="/" element={<Home />} />
        <Route path="es-ES" element={<HomeES />} />
        <Route path="en-US" element={<HomeUS />} />

        <Route path="institucional" element={<Institucional />} />
        <Route path="institucional/historia" 
        element={<Institucional showHistoriaBool={true} showCulturaBool={false} />} />
        <Route path="institucional/cultura" 
        element={<Institucional showHistoriaBool={false} showCulturaBool={true} />} />

        <Route path="institucional/es-ES" element={<InstitucionalES />} />
        <Route path="institucional/historia/es-ES" 
        element={<InstitucionalES showHistoriaBool={true} showCulturaBool={false} />} />
        <Route path="institucional/cultura/es-ES" 
        element={<InstitucionalES showHistoriaBool={false} showCulturaBool={true} />} />

        <Route path="institucional/en-US" element={<InstitucionalUS />} />
        <Route path="institucional/historia/en-US" 
        element={<InstitucionalUS showHistoriaBool={true} showCulturaBool={false} />} />
        <Route path="institucional/cultura/en-US" 
        element={<InstitucionalUS showHistoriaBool={false} showCulturaBool={true} />} />
        
        <Route path="infraestrutura" element={<Infraestrutura />} />
        <Route path="infraestrutura/instalacoes" 
        element={<Infraestrutura showInstalacoesBool={true} showLaboratoriosBool={false} showAnalisesBool={false} />} />
        <Route path="infraestrutura/laboratorios" 
        element={<Infraestrutura showInstalacoesBool={false} showLaboratoriosBool={true} showAnalisesBool={false} />} />
        <Route path="infraestrutura/analises" 
        element={<Infraestrutura showInstalacoesBool={false} showLaboratoriosBool={false} showAnalisesBool={true} />} />

        <Route path="infraestrutura/es-ES" element={<InfraestruturaES />} />
        <Route path="infraestrutura/instalacoes/es-ES" 
        element={<InfraestruturaES showInstalacoesBool={true} showLaboratoriosBool={false} showAnalisesBool={false} />} />
        <Route path="infraestrutura/laboratorios/es-ES" 
        element={<InfraestruturaES showInstalacoesBool={false} showLaboratoriosBool={true} showAnalisesBool={false} />} />
        <Route path="infraestrutura/analises/es-ES" 
        element={<InfraestruturaES showInstalacoesBool={false} showLaboratoriosBool={false} showAnalisesBool={true} />} />

        <Route path="infraestrutura/en-US" element={<InfraestruturaUS />} />
        <Route path="infraestrutura/instalacoes/en-US" 
        element={<InfraestruturaUS showInstalacoesBool={true} showLaboratoriosBool={false} showAnalisesBool={false} />} />
        <Route path="infraestrutura/laboratorios/en-US" 
        element={<InfraestruturaUS showInstalacoesBool={false} showLaboratoriosBool={true} showAnalisesBool={false} />} />
        <Route path="infraestrutura/analises/en-US" 
        element={<InfraestruturaUS showInstalacoesBool={false} showLaboratoriosBool={false} showAnalisesBool={true} />} />

        <Route path="dt" element={<DT />} />
        <Route path="dt/es-ES" element={<DTES />} />
        <Route path="dt/en-US" element={<DTUS />} />
        
        <Route path="qualidade" element={<Qualidade />} />
        <Route path="qualidade/politica-do-sgi" 
        element={<Qualidade showAreaDoFornecedorBool={false} showCertificacoesBool={false} showPoliticaDoSGIBool={true} showFISPQSBool={false} />} />
        <Route path="qualidade/certificados" 
        element={<Qualidade showAreaDoFornecedorBool={false} showCertificacoesBool={true} showPoliticaDoSGIBool={false} showFISPQSBool={false} />} />
        <Route path="qualidade/area-fornecedor" 
        element={<Qualidade showAreaDoFornecedorBool={true} showCertificacoesBool={false} showPoliticaDoSGIBool={false} showFISPQSBool={false} />} />
        <Route path="qualidade/fispqs" 
        element={<Qualidade showAreaDoFornecedorBool={true} showCertificacoesBool={false} showPoliticaDoSGIBool={false} showFISPQSBool={true} />} />
        <Route path="qualidade/es-ES" element={<QualidadeES />} />
        <Route path="qualidade/politica-do-sgi/es-ES" 
        element={<QualidadeES showAreaDoFornecedorBool={false} showCertificacoesBool={false} showPoliticaDoSGIBool={true} showFISPQSBool={false} />} />
        <Route path="qualidade/certificados/es-ES" 
        element={<QualidadeES showAreaDoFornecedorBool={false} showCertificacoesBool={true} showPoliticaDoSGIBool={false} showFISPQSBool={false} />} />
        <Route path="qualidade/area-fornecedor/es-ES" 
        element={<QualidadeES showAreaDoFornecedorBool={true} showCertificacoesBool={false} showPoliticaDoSGIBool={false} showFISPQSBool={false} />} />
        <Route path="qualidade/fispqs/es-ES" 
        element={<QualidadeES showAreaDoFornecedorBool={true} showCertificacoesBool={false} showPoliticaDoSGIBool={false} showFISPQSBool={true} />} />
        <Route path="qualidade/en-US" element={<QualidadeUS />} />
        <Route path="qualidade/politica-do-sgi/en-US" 
        element={<QualidadeUS showAreaDoFornecedorBool={false} showCertificacoesBool={false} showPoliticaDoSGIBool={true} showFISPQSBool={false} />} />
        <Route path="qualidade/certificados/en-US" 
        element={<QualidadeUS showAreaDoFornecedorBool={false} showCertificacoesBool={true} showPoliticaDoSGIBool={false} showFISPQSBool={false} />} />
        <Route path="qualidade/area-fornecedor/en-US" 
        element={<QualidadeUS showAreaDoFornecedorBool={true} showCertificacoesBool={false} showPoliticaDoSGIBool={false} showFISPQSBool={false} />} />
        <Route path="qualidade/fispqs/en-US" 
        element={<QualidadeUS showAreaDoFornecedorBool={true} showCertificacoesBool={false} showPoliticaDoSGIBool={false} showFISPQSBool={true} />} />
        
        <Route path="logistica" element={<Logistica />} />
        <Route path="logistica/fornecedores" 
        element={<Logistica showFornecedoresBool={true} showArmazenamentoBool={false} showSaidaDosCompostosBool={false} showFormatosDosCompostosBool={false} />} />
        <Route path="logistica/armazenamento" 
        element={<Logistica showFornecedoresBool={false} showArmazenamentoBool={true} showSaidaDosCompostosBool={false} showFormatosDosCompostosBool={false} />} />
        <Route path="logistica/saida-dos-compostos" 
        element={<Logistica showFornecedoresBool={false} showArmazenamentoBool={false} showSaidaDosCompostosBool={true} showFormatosDosCompostosBool={false} />} />
        <Route path="logistica/formatos-dos-compostos" 
        element={<Logistica showFornecedoresBool={false} showArmazenamentoBool={false} showSaidaDosCompostosBool={false} showFormatosDosCompostosBool={true} />} />
        <Route path="logistica/es-ES" element={<LogisticaES />} />
        <Route path="logistica/fornecedores/es-ES" 
        element={<LogisticaES showFornecedoresBool={true} showArmazenamentoBool={false} showSaidaDosCompostosBool={false} showFormatosDosCompostosBool={false} />} />
        <Route path="logistica/armazenamento/es-ES" 
        element={<LogisticaES showFornecedoresBool={false} showArmazenamentoBool={true} showSaidaDosCompostosBool={false} showFormatosDosCompostosBool={false} />} />
        <Route path="logistica/saida-dos-compostos/es-ES" 
        element={<LogisticaES showFornecedoresBool={false} showArmazenamentoBool={false} showSaidaDosCompostosBool={true} showFormatosDosCompostosBool={false} />} />
        <Route path="logistica/formatos-dos-compostos/es-ES" 
        element={<LogisticaES showFornecedoresBool={false} showArmazenamentoBool={false} showSaidaDosCompostosBool={false} showFormatosDosCompostosBool={true} />} />
        <Route path="logistica/en-US" element={<LogisticaUS />} />
        <Route path="logistica/fornecedores/en-US" 
        element={<LogisticaUS showFornecedoresBool={true} showArmazenamentoBool={false} showSaidaDosCompostosBool={false} showFormatosDosCompostosBool={false} />} />
        <Route path="logistica/armazenamento/en-US" 
        element={<LogisticaUS showFornecedoresBool={false} showArmazenamentoBool={true} showSaidaDosCompostosBool={false} showFormatosDosCompostosBool={false} />} />
        <Route path="logistica/saida-dos-compostos/en-US" 
        element={<LogisticaUS showFornecedoresBool={false} showArmazenamentoBool={false} showSaidaDosCompostosBool={true} showFormatosDosCompostosBool={false} />} />
        <Route path="logistica/formatos-dos-compostos/en-US" 
        element={<LogisticaUS showFornecedoresBool={false} showArmazenamentoBool={false} showSaidaDosCompostosBool={false} showFormatosDosCompostosBool={true} />} />
        
        <Route path="produtos" element={<Produtos />} />
        <Route path="produtos/compostos-de-borracha" 
        element={<Produtos showCompostosDeBorrachaBool={true} />} />
        <Route path="produtos/es-ES" element={<ProdutosES />} />
        <Route path="produtos/compostos-de-borracha/es-ES" 
        element={<ProdutosES showCompostosDeBorrachaBool={true} />} />
        <Route path="produtos/en-US" element={<ProdutosUS />} />
        <Route path="produtos/compostos-de-borracha/en-US" 
        element={<ProdutosUS showCompostosDeBorrachaBool={true} />} />

        <Route path="contato" element={<Contato />} />
        <Route path="contato/fale-conosco" element={<Contato showFaleConoscoBool={true} showLocalizacaoBool={false} showTrabalheConoscoBool={false}/>} />
        <Route path="contato/localizacao" element={<Contato showFaleConoscoBool={false} showLocalizacaoBool={true} showTrabalheConoscoBool={false}/>} />
        <Route path="contato/trabalhe-conosco" element={<Contato showFaleConoscoBool={false} showLocalizacaoBool={false} showTrabalheConoscoBool={true}/>} />
        
        <Route path="contato/es-ES" element={<ContatoES />} />
        <Route path="contato/fale-conosco/es-ES" element={<ContatoES showFaleConoscoBool={true} showLocalizacaoBool={false} showTrabalheConoscoBool={false}/>} />
        <Route path="contato/localizacao/es-ES" element={<ContatoES showFaleConoscoBool={false} showLocalizacaoBool={true} showTrabalheConoscoBool={false}/>} />
        <Route path="contato/trabalhe-conosco/es-ES" element={<ContatoES showFaleConoscoBool={false} showLocalizacaoBool={false} showTrabalheConoscoBool={true}/>} />
        
        <Route path="contato/en-US" element={<ContatoUS />} />
        <Route path="contato/fale-conosco/en-US" element={<ContatoUS showFaleConoscoBool={true} showLocalizacaoBool={false} showTrabalheConoscoBool={false}/>} />
        <Route path="contato/localizacao/en-US" element={<ContatoUS showFaleConoscoBool={false} showLocalizacaoBool={true} showTrabalheConoscoBool={false}/>} />
        <Route path="contato/trabalhe-conosco/en-US" element={<ContatoUS showFaleConoscoBool={false} showLocalizacaoBool={false} showTrabalheConoscoBool={true}/>} />
       
       
       <Route path="links" element={<Links />} />
       <Route path="links/es-ES" element={<LinksES />} /> 
       <Route path="links/en-US" element={<LinksUS />} /> 

       <Route path="politica-de-privacidade" element={<Politicas />} />
       <Route path="politica-de-privacidade/es-ES" element={<PoliticasES />} /> 
       <Route path="politica-de-privacidade/us-US" element={<PoliticasUS />} />  

       <Route path="pesquisa-satisfacao/QHX1xVD4QkeGsp6g7Q3G6BnOc-oHGbdIoP4r0HMRIKpUQ1M5UFFaSDROR1lERTNUT0o3TU5ERVhENS4u" element={<Pesquisa />} />
    </ReactRouterRoutes>
  )
} 