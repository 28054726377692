export const infraestruturaMenuItems = [
  {
    title: 'Instalações',
    path: '/infraestrutura/instalacoes',
    cName: 'link'
  },
  {
    title: 'Laboratórios',
    path: '/infraestrutura/laboratorios',
    cName: 'link'
  },
  {
    title: 'Análises',
    path: '/infraestrutura/analises',
    cName: 'link'
  }
];

export const infraestruturaESMenuItems = [
  {
    title: 'Instalaciones',
    path: '/infraestrutura/instalacoes/es-ES',
    cName: 'link'
  },
  {
    title: 'Laboratorios',
    path: '/infraestrutura/laboratorios/es-ES',
    cName: 'link'
  },
  {
    title: 'Análisis',
    path: '/infraestrutura/analises/es-ES',
    cName: 'link'
  }
];

export const infraestruturaUSMenuItems = [
  {
    title: 'installations',
    path: '/infraestrutura/instalacoes/en-US',
    cName: 'link'
  },
  {
    title: 'Laboratories',
    path: '/infraestrutura/laboratorios/en-US',
    cName: 'link'
  },
  {
    title: 'Analysis',
    path: '/infraestrutura/analises/en-US',
    cName: 'link'
  }
];

export const institucionalMenuItems = [
  {
    title: 'História',
    path: '/institucional/historia',
    cName: 'link'
  },
  {
    title: 'Cultura',
    path: '/institucional/cultura',
    cName: 'link'
  }
];

export const institucionalESMenuItems = [
  {
    title: 'Historia',
    path: '/institucional/historia/es-ES',
    cName: 'link'
  },
  {
    title: 'Cultura',
    path: '/institucional/cultura/es-ES',
    cName: 'link'
  }
];

export const institucionalUSMenuItems = [
  {
    title: 'History',
    path: '/institucional/historia/en-US',
    cName: 'link'
  },
  {
    title: 'Culture',
    path: '/institucional/cultura/en-US',
    cName: 'link'
  }
];

export const qualidadeMenuItems = [
  {
    title: 'Política do SGI',
    path: '/qualidade/politica-do-sgi',
    cName: 'link'
  },
  {
    title: 'Certificações',
    path: '/qualidade/certificados',
    cName: 'link'
  },
  {
    title: 'Área do Fornecedor',
    path: '/qualidade/area-fornecedor',
    cName: 'link'
  },
  {
    title: 'FISPQS',
    path: '/qualidade/fispqs',
    cName: 'link'
  }
];

export const qualidadeESMenuItems = [
  {
    title: 'Política do SGI',
    path: '/qualidade/politica-do-sgi/es-ES',
    cName: 'link'
  },
  {
    title: 'Certificaciones',
    path: '/qualidade/certificados/es-ES',
    cName: 'link'
  },
  {
    title: 'Área de proveedores',
    path: '/qualidade/area-fornecedor/es-ES',
    cName: 'link'
  },
  {
    title: 'FISPQS',
    path: '/qualidade/fispqs/es-ES',
    cName: 'link'
  }
];

export const qualidadeUSMenuItems = [
  {
    title: 'SGI Policy',
    path: '/qualidade/politica-do-sgi/en-US',
    cName: 'link'
  },
  {
    title: 'Certifications',
    path: '/qualidade/certificados/en-US',
    cName: 'link'
  },
  {
    title: 'Supplier Area',
    path: '/qualidade/area-fornecedor/en-US',
    cName: 'link'
  },
  {
    title: 'FISPQS',
    path: '/qualidade/fispqs/en-US',
    cName: 'link'
  }
];

export const logisticaMenuItems = [
  {
    title: 'Fornecedores',
    path: '/logistica/fornecedores',
    cName: 'link'
  },
  {
    title: 'Armazenamento',
    path: '/logistica/armazenamento',
    cName: 'link'
  },
  {
    title: 'Saida dos compostos',
    path: '/logistica/saida-dos-compostos',
    cName: 'link'
  },
  {
    title: 'Formatos dos compostos',
    path: '/logistica/formatos-dos-compostos',
    cName: 'link'
  }
];

export const logisticaESMenuItems = [
  {
    title: 'Proveedores',
    path: '/logistica/fornecedores/es-ES',
    cName: 'link'
  },
  {
    title: 'Almacenamiento',
    path: '/logistica/armazenamento/es-ES',
    cName: 'link'
  },
  {
    title: 'Salida de compuestos',
    path: '/logistica/saida-dos-compostos/es-ES',
    cName: 'link'
  },
  {
    title: 'Formatos compuestos',
    path: '/logistica/formatos-dos-compostos/es-ES',
    cName: 'link'
  }
];

export const logisticaUSMenuItems = [
  {
    title: 'Suppliers',
    path: '/logistica/fornecedores/en-US',
    cName: 'link'
  },
  {
    title: 'Storage',
    path: '/logistica/armazenamento/en-US',
    cName: 'link'
  },
  {
    title: 'Compounds output',
    path: '/logistica/saida-dos-compostos/en-US',
    cName: 'link'
  },
  {
    title: 'Compound formats',
    path: '/logistica/formatos-dos-compostos/en-US',
    cName: 'link'
  }
];

export const produtosMenuItems = [
  {
    title: 'Compostos de Borracha',
    path: '/produtos/compostos-de-borracha',
    cName: 'link'
  }
];

export const produtosESMenuItems = [
  {
    title: 'Compuestos de caucho',
    path: '/produtos/compostos-de-borracha/es-ES',
    cName: 'link'
  }
];

export const produtosUSMenuItems = [
  {
    title: 'Rubber Compounds',
    path: '/produtos/compostos-de-borracha/en-US',
    cName: 'link'
  }
];

export const contatoMenuItems = [
  {
    title: 'Fale Conosco',
    path: '/contato/fale-conosco',
    cName: 'link'
  },
  {
    title: 'Localização',
    path: '/contato/localizacao',
    cName: 'link'
  },
  {
    title: 'Trabalhe conosco',
    path: '/contato/trabalhe-conosco',
    cName: 'link'
  }
];

export const contatoESMenuItems = [
  {
    title: 'Hable con nosotros',
    path: '/contato/fale-conosco/es-ES',
    cName: 'link'
  },
  {
    title: 'Localización',
    path: '/contato/localizacao/es-ES',
    cName: 'link'
  }
];

export const contatoUSMenuItems = [
  {
    title: 'Contact us',
    path: '/contato/fale-conosco/en-US',
    cName: 'link'
  },
  {
    title: 'Localization',
    path: '/contato/localizacao/en-US',
    cName: 'link'
  }
];

