import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Main, PrivacyPolicy } from './styles';

export function Politicas() {
  window.scrollTo(0, 0);

  return (
    <Main data-aos="zoom-in" data-aos-duration="1500">
      <Helmet>
        <title>Política de Privacidade | Zanaflex - Compostos de Borracha</title>
        <meta name="description" content="Política de Privacidade - Zanaflex" />
        <meta name="keywords" content="Política de Privacidade, Zanaflex, Borracha, Composto de Borracha" />

        <link rel="canonical" href="https://zanaflex.com.br/politica-de-privacidade" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/politica-de-privacidade" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/politica-de-privacidade/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/politica-de-privacidade/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/politica-de-privacidade/en-US" />
      </Helmet>

      <Container>
        <PrivacyPolicy>
          <h1>Política de privacidade</h1>
          <h2>1. PREÂMBULO</h2>
          <p>A presente Política de Privacidade tem por finalidade demonstrar o compromisso da <strong><a href="https://www.zanaflex.com.br">ZANAFLEX BORRACHAS LTDA.</a></strong>, pessoa jurídica de Direito Privado, inscrita no CNPJ/MF
            sob o nº 62.055.710/0001-76, com sede à Avenida Vasco Massafeli, 1010 – CEP: 06703-
            600 – Cotia - SP (doravante <strong>“ZANAFLEX”</strong>) com a privacidade e a proteção dos dados
            pessoais coletados de seus <strong>TITULARES</strong>, estabelecendo as regras sobre a coleta,
            registro, armazenamento, uso, compartilhamento, enriquecimento e eliminação dos
            dados pessoais coletados dentro do escopo dos serviços prestados, de acordo com as
            leis em vigor.
          </p>
          <p>
            Você (<strong>TITULAR</strong>) declara que fez a leitura completa e atenta das regras deste
            documento e dos Termos de Uso, estando plenamente ciente de seu inteiro teor,
            conferindo assim sua livre e expressa concordância com os termos aqui estipulados.
          </p>
          <h2>2. INFORMAÇÕES GERAIS E DEFINIÇÕES</h2>
          <p>
            A <strong>ZANAFLEX</strong> valoriza a privacidade de seus colaboradores e criou esta Política de
            Privacidade para demonstrar seu compromisso em proteger a sua privacidade e seus
            dados pessoais, nos termos da Lei Geral de Proteção de Dados (<strong>LGPD</strong>) e demais leis
            sobre o tema, bem como descrever de que forma sua privacidade é protegida
            pela <strong>ZANAFLEX</strong> ao coletar, tratar e armazenar suas informações pessoais.
          </p>
          <p>
            A <strong>ZANAFLEX</strong> segue o Código de Conduta ZANAFLEX (“Código de Conduta”) que
            representa a missão, a visão e os valores da <strong>ZANAFLEX</strong>, feito com objetivo de orientar
            e retratar quais os valores prioritários desta Empresa desde sua concepção, sempre
            visando uma atuação ética de todos os seus colaboradores.
          </p>
          <p>
            <p><strong>1.1 Definições</strong></p>
            <strong>Titular:</strong> todas as pessoas físicas que irão prestar algum serviço, maiores de 18 (dezoito)
            anos ou emancipadas e totalmente capazes de praticar os atos da vida civil ou os
            absolutamente ou relativamente incapazes devidamente representados ou assistidos.
          </p>
          <p>
            <strong>Dados Pessoais:</strong> significa quaisquer informações fornecidas e/ou coletadas
            pela <strong>ZANAFLEX</strong> e/ou suas afiliadas, por qualquer meio, ainda que públicos, que: (I)
            identifiquem, ou que, quando usadas em combinação com outras informações tratadas
            pela <strong>ZANAFLEX</strong> identifiquem um indivíduo; ou (II) por meio das quais a identificação
            ou informações de contato de uma pessoa física possam ser derivadas. Os Dados
            Pessoais podem estar em qualquer mídia ou formato, inclusive registros eletrônicos ou
            computadorizados, bem como em arquivos baseados em papel.
          </p>
          <p>
            <strong>Finalidade:</strong> o objetivo, o propósito que a <strong>ZANAFLEX</strong> deseja alcançar a partir de cada
            ato de tratamento das informações pessoais.
          </p>
          <p>
            <strong>Necessidade:</strong> justificativa pelo qual é estritamente necessário coletar dados pessoais,
            para atingir a finalidade, evitando-se a coleta excessiva
          </p>
          <p>
            <strong>Bases legais:</strong> fundamentação legal que torna legítimo o tratamento de dados pessoais
            para uma determinada finalidade prévia por parte da <strong>ZANAFLEX</strong>.
          </p>
          <p>
            <strong>Consentimento:</strong> autorização expressa e inequívoca dada pelo <strong>TITULAR</strong> do dado
            pessoal para que a <strong>ZANAFLEX</strong> trate seus dados pessoais para uma finalidade
            previamente descrita, na qual a base legal necessária para o ato demande a
            autorização expressa do <strong>TITULAR</strong>.
          </p>
          <p>
            Esta política se aplica, em geral, a qualquer colaborador ou prestador de serviço da
            <strong>ZANAFLEX</strong> e potenciais <strong>TITULARES</strong> de dados que venham a utilizar os serviços
            oferecidos pela <strong>ZANAFLEX</strong>, incluindo Usuários dos sistemas, sites ou outros meios
            operados pela <strong>ZANAFLEX</strong> , e resume como a <strong>ZANAFLEX</strong> poderá coletar, produzir,
            receptar, classificar, utilizar, acessar, reproduzir, transmitir, distribuir, processar,
            arquivar, armazenar, eliminar, avaliar ou controlar a informação, modificar,
            comunicar, transferir, difundir ou extrair os dados coletados, incluindo as informações
            de identificação pessoal, de acordo com as bases legais aplicáveis e todas as leis de
            privacidade e proteção de dados em vigor.
          </p>
          <p>
            O <strong>TITULAR</strong> declara ter no mínimo 18 (dezoito) anos e ter capacidade plena e expressa
            para a aceitação dos termos e condições desta Política de Privacidade e do Termo de
            Consentimento para todos os fins de direito. Caso não tenha a plena capacidade civil
            acima identificada, declara o <strong>TITULAR</strong> que está sendo assistido por seu representante
            legal, tais como pais, tutores, e etc.
          </p>
          <p>
            Caso o <strong>TITULAR</strong> não se enquadre na descrição acima e/ou não concorde, ainda que em
            parte, com os termos e condições contidos nesta Política de Privacidade, não deverá
            acessar e/ou utilizar os serviços oferecidos pela <strong>ZANAFLEX</strong>, bem como fazer parte do
            seu quadro de funcionários ou prestar quaisquer outros serviços a empresa, exceto no
            caso em que prevaleçam direitos e liberdades fundamentais do <strong>TITULAR</strong> que exijam a
            proteção dos dados pessoais, nesse caso o <strong>TITULAR</strong> deverá entrar em contato com o
            encarregado de dados (DPO) para o ajuste nos termos citados.
          </p>
          <h2>3. COLETA E USOS DE INFORMAÇÕES PESSOAIS</h2>
          <p>O <strong>TITULAR</strong> está ciente de que fornece informação de forma consciente e voluntária
            por meio, do <strong>RH</strong>, <strong>SESMT</strong>, <strong>SITE</strong>, <strong>COMERCIAL</strong> ou quaisquer outros contatos que
            representem a <strong>ZANAFLEX</strong>.
          </p>
          <p>
            Quando o <strong>TITULAR</strong> realiza o cadastro e/ou preenche formulários oferecidos
            pela <strong>ZANAFLEX</strong>, inclusive nos sistemas por ela operados, determinados Dados Pessoais
            solicitados serão mantidos em sigilo e serão utilizadas apenas para o propósito que
            motivou o cadastro.
          </p>
          <h2>4. COMPARTILHAMENTO E TRATAMENTO DE INFORMAÇÕES PESSOAIS </h2>
          <p>A <strong>ZANAFLEX</strong> não disponibilizará Dados Pessoais coletados para provedores de lista de
            e-mail sem seu expresso consentimento dos <strong>TITULARES</strong>.
          </p>
          <p>
            A <strong>ZANAFLEX</strong> poderá compartilhar os Dados Pessoais coletados a terceiros, nas
            seguintes situações e nos limites exigidos e autorizados pela Lei:
          </p>
          <ol>
            <li>
              Com os seus clientes e parceiros quando necessário e/ou apropriado à prestação
              de serviços ou comercialização de produtos relacionados;
            </li>
            <li>
              Com as empresas e indivíduos contratados para a execução de determinadas
              atividades e serviços em nome da <strong>ZANAFLEX</strong>;
            </li>
            <li>
              Com empresas do grupo (se houver);
            </li>
            <li>
              Com fornecedores e parceiros para execução dos serviços contratados ou
              comercialização dos produtos com a <strong>ZANAFLEX</strong>;
            </li>
            <li>
              Para propósitos administrativos como: pesquisa, planejamento, desenvolvimento
              de serviços, segurança e gerenciamento de risco.
            </li>
            <li>
              Quando necessário em decorrência de obrigação legal, determinação de
              autoridade competente, ou decisão judicial.
            </li>
          </ol>
          <p>
            Nas hipóteses de compartilhamento de Dados Pessoais com terceiros, todos os sujeitos
            mencionados nos itens I a VI deverão utilizar os Dados Pessoais partilhados de maneira
            consistente e de acordo com os propósitos para os quais foram coletados (ou com os
            quais o <strong>TITULAR</strong> consentiu previamente) e de acordo com o que foi determinado por
            esta Política de Privacidade, outras declarações de privacidade de website ou países,
            e todas as leis de privacidade e proteção de dados aplicáveis.
          </p>
          <h2>5. MOTIVOS LEGAIS PARA A DIVULGAÇÃO DE SEUS DADOS</h2>
          <p>
            Em certas circunstâncias, a <strong>ZANAFLEX</strong> poderá compartilhar Dados Pessoais, na medida
            necessária ou apropriada, para órgãos governamentais, consultores e outros terceiros
            com o objetivo de cumprir com a legislação aplicável ou com uma ordem ou intimação
            judicial ou, ainda, se a <strong>ZANAFLEX</strong> acreditar de boa-fé que tal ação seja necessária
            para:
          </p>
          <ol>
            <li>Cumprir com uma legislação que exija tal divulgação;</li>
            <li>
              Investigar, impedir ou tomar medidas relacionadas a atividades ilegais suspeitas
              ou reais ou para cooperar com órgãos públicos ou para proteger a segurança
              nacional;
            </li>
            <li>Execução de seus contratos;</li>
            <li>
              Investigar e se defender contra quaisquer reivindicações ou alegações de
              terceiros;
            </li>
            <li>
              Proteger a segurança ou a integridade dos serviços e produtos comercializados
              (por exemplo, o compartilhamento com empresas que estão sofrendo ameaças
              semelhantes);
            </li>
            <li>
              Exercer ou proteger os direitos, a propriedade e a segurança da ZANAFLEX e suas
              empresas coligadas (se houver);
            </li>
            <li>
              Proteger os direitos e a segurança pessoal de seus funcionários, usuários ou do
              público;
            </li>
            <li>
              Em caso de venda, compra, fusão, reorganização, liquidação ou dissolução
              da <strong>ZANAFLEX</strong>.
            </li>
          </ol>
          <p>
            A <strong>ZANAFLEX</strong> cientificará os respectivos <strong>TITULARES</strong> sobre eventuais demandas legais
            que resultem na divulgação de informações pessoais, nos termos do que foi exposto no
            item V, a menos que tal ciência seja vedada por lei ou proibida por mandado judicial
            ou, ainda, se a requisição for emergencial. A <strong>ZANAFLEX</strong> poderá contestar essas
            demandas se julgar que as solicitações são excessivas, vagas ou feitas por autoridades
            incompetentes.
          </p>
          <h2>6. SEGURANÇA DE INFORMAÇÕES PESSOAIS</h2>
          <p>
            Todas os Dados Pessoais serão guardados na base de dados da <strong>ZANAFLEX</strong> ou em base
            de dados mantidas “na nuvem” ou em “servidores internos”, pelos fornecedores de
            serviços contratados pela <strong>ZANAFLEX</strong>, os quais estão devidamente de acordo com a
            legislação de dados vigente.
          </p>
          <p>
            A <strong>ZANAFLEX</strong> e seus fornecedores utilizam vários procedimentos de segurança para
            proteger a confidencialidade, segurança e integridade de seus Dados Pessoais,
            prevenindo a ocorrência de eventuais danos em virtude do tratamento desses dados.
          </p>
          <p>
            Embora a <strong>ZANAFLEX</strong> utilize medidas de segurança e monitore seu sistema para
            verificar vulnerabilidades e ataques para proteger seus Dados Pessoais contra
            divulgação não autorizada, mau uso ou alteração, o <strong>TITULAR</strong> entende e concorda que
            não há garantias de que as informações não poderão ser acessadas, divulgadas,
            alteradas ou destruídas por violação de qualquer uma das proteções físicas, técnicas
            ou administrativas.
          </p>
          <h2>7. RETENÇÃO DE DADOS</h2>
          <p>
            A <strong>ZANAFLEX</strong> retém todos os dados fornecidos, inclusive os Dados Pessoais, enquanto o
            cadastro do <strong>TITULAR</strong> estiver ativo e conforme seja necessário para consecução de seus
            serviços.
          </p>
          <p>
            A <strong>ZANAFLEX</strong> reterá seus Dados Pessoais e manterá seus dados armazenados até
            eventual requerimento de exclusão, ou de acordo com os períodos descritos na
            Legislação Vigente.
          </p>
          <p>
            A <strong>ZANAFLEX</strong> poderá vir a manter seus Dados Pessoais após receber seu pedido de
            exclusão, caso seja necessário para cumprimento de obrigações legais, resolver
            disputas, manter a segurança, evitar fraudes e abuso e garantir o cumprimento de
            contratos.
          </p>
          <h2>8. BASES LEGAIS PARA PROCESSAMENTO</h2>
          <p>
            A <strong>ZANAFLEX</strong> apenas trata Dados Pessoais em situações em que está autorizada
            legalmente ou mediante seu expresso e inequívoco consentimento do <strong>TITULAR</strong>.
          </p>
          <p>
            Conforme descrito na presente Política, a <strong>ZANAFLEX</strong> tem bases legais para coletar,
            produzir, receptar, classificar, utilizar, acessar, reproduzir, transmitir, distribuir,
            processar, arquivar, armazenar, eliminar, avaliar ou controlar a informação,
            modificar, comunicar, transferir, difundir ou extrair dados sobre o <strong>TITULAR</strong>.
          </p>
          <p>
            As bases legais incluem seu consentimento (colhido de forma expressa e inequívoca no
            Termo de Consentimento), contratos e procedimentos preliminares contratuais (em
            que o processamento é necessário para firmar o contrato com qualquer outra parte) e
            interesses legítimos, desde que tal processamento não viole seus direitos e liberdades.
          </p>
          <p>
            Tais interesses incluem proteger o <strong>TITULAR</strong> e a <strong>ZANAFLEX</strong> de ameaças, cumprir a
            legislação aplicável, o exercício regular de direitos em processo judicial,
            administrativo ou arbitral, habilitar a realização ou administração dos negócios,
            incluindo controle de qualidade, relatórios e serviços oferecidos, gerenciar transações
            empresariais, entender e melhorar os negócios e relacionamentos com os clientes e
            permitir que os <strong>TITULARES</strong> encontrem oportunidades econômicas.
          </p>
          <p>
            O <strong>TITULAR</strong> tem o direito de negar ou retirar o consentimento fornecido à <strong>ZANAFLEX</strong>,
            quando esta for a base legal para tratamento dos dados pessoais, podendo
            a <strong>ZANAFLEX</strong> encerrar a consecução de seus serviços para este solicitante na hipótese
            de ocorrência de tal solicitação.
          </p>
          <p>
            Caso tenha dúvidas sobre as bases legais para coleta, tratamento e armazenamento de
            seus dados pessoais, entre em contato com a <strong>ZANAFLEX</strong> e seu encarregado de
            dados (DPO) por meio do e-mail dpo@zanaflex.com.br.
          </p>
          <h2>9. DIREITO DE ACESSAR E CONTROLAR SEUS DADOS PESSOAIS</h2>
          <p>
            A <strong>ZANAFLEX</strong> oferece ao <strong>TITULAR</strong> diversas opções do que fazer com seus Dados
            Pessoais coletados, tratados e armazenados, incluindo sua exclusão e/ou correção. O
            <strong>TITULAR</strong> pode:
          </p>
          <ol>
            <li>
              <strong>Excluir dados:</strong> o <strong>TITULAR</strong> pode solicitar a exclusão de alguns dos seus Dados
              Pessoais (por exemplo, se eles não são mais necessários para lhe fornecer os
              serviços).
            </li>
            <li>
              <strong>Alterar ou corrigir dados:</strong> o <strong>TITULAR</strong> pode editar ou solicitar a edição de alguns
              dos seus Dados Pessoais. O <strong>TITULAR</strong> também pode solicitar atualizações,
              alterações ou correções de seus dados em determinados casos, principalmente se
              eles estiverem incorretos.
            </li>
            <li>
              <strong>Colocar objeções, limites ou restrições ao uso de dados:</strong> o <strong>TITULAR</strong> pode
              solicitar a interrupção do uso de todos ou alguns de seus Dados Pessoais (por
              exemplo, se não tivermos o direito de continuar a usá-los), ou limitar a nossa
              utilização de tais dados (por exemplo, se seus Dados Pessoais estiverem incorretos
              ou armazenados ilegalmente), destacando-se que a <strong>ZANAFLEX</strong> poderá tratar os
              Dados Pessoais de acordo com as bases legais vigentes.
            </li>
            <li>
              <strong>O Usuário tem direito de acessar ou levar seus dados:</strong> o <strong>TITULAR</strong> pode solicitar
              uma cópia dos seus Dados Pessoais e dos dados que o <strong>TITULAR</strong> forneceu em um
              formato legível sob a forma impressa ou por meio eletrônico.
            </li>
          </ol>
          <p>
            O <strong>TITULAR</strong> pode fazer as solicitações listadas acima entrando em contato com o
            nosso encarregado de dados por meio do e-mail dpo@zanaflex.com.br e estes pedidos
            serão considerados de acordo com as leis aplicáveis.
          </p>
          <h2>10. REVISÕES À POLÍTICA DE PRIVACIDADE </h2>
          <p>
            Caso a <strong>ZANAFLEX</strong> modifique esta Política de Privacidade, tais alterações serão
            publicadas de forma visível no <strong>SGI</strong> e no <strong>SITE</strong> da <strong>ZANAFLEX</strong>. Esta Política é válida a
            partir de 01 de abril de 2021. Caso o <strong>TITULAR</strong> tenha quaisquer questões a respeito das
            políticas de privacidade, por favor, entre em contato com a <strong>ZANAFLEX</strong>, por meio dos
            endereços abaixo/canal de atendimento do encarregado de dados.
          </p>
          <p>
            Esse documento é a Primeira Versão da Política de Privacidade, podendo ela ser
            alterada a qualquer tempo, conforme parágrafo anterior. Na hipótese de atualização
            desta Política de Privacidade, o <strong>TITULAR</strong> será informado e receberá a nova versão para
            validação e aceite ao final da leitura.
          </p>
          <p>
            Não utilizamos nenhum tipo de decisão automatizada que impacte você, <strong>TITULAR</strong>.
          </p>
          <p>
            Caso empresas terceirizadas realizem o processamento de quaisquer dados que
            coletamos, as mesmas deverão respeitar as condições aqui estipuladas e nossas normas
            de Segurança da Informação, obrigatoriamente.
          </p>
          <p>
            Caso alguma disposição desta Política de Privacidade seja considerada ilegal ou
            ilegítima por autoridade da localidade em que resida ou da sua conexão à Internet, as
            demais condições permanecerão em pleno vigor e efeito.
          </p>
          <p>
            O <strong>TITULAR</strong> reconhece que toda comunicação realizada por e-mail (aos endereços
            informados no seu cadastro), SMS, aplicativos de comunicação instantânea ou qualquer
            outra forma digital e virtual também são válidas, eficazes e suficiente para a
            divulgação de qualquer assunto que se refira aos serviços que prestamos, bem como às
            condições de sua prestação ou a qualquer outro assunto nele abordado, ressalvadas as
            disposições expressamente diversas previstas nesta Política de Privacidade.
          </p>
          <h2>11. CONTATO</h2>
          <p>
            A <strong>ZANAFLEX</strong> nomeia, neste ato, Erminio Heredia Diaz como encarregado de
            dados (“DPO”). O <strong>TITULAR</strong> pode entrar em contato com o DPO no seguinte telefone
            (11) 2165-3450 ou através do e-mail: dpo@zanaflex.com.br
          </p>
          <h2>12. MEDIAÇÃO E FORO DE ELEIÇÃO</h2>
          <p>
            Esta política está sujeita à Lei da República Federativa do Brasil e o Foro da Comarca
            de Cotia é competente para dirimir qualquer controvérsia com relação à mesma. </p>
          <p>
            Em caso de incidente com Dados Pessoais, a <strong>ZANAFLEX</strong>, desde já, se reserva a nomear
            a JUSPRO – Justiça Sem Processo Mediação e Conciliação S/S Ltda. como Câmara de
            Mediação apta a dirimir as questões envolvendo os <strong>TITULAR</strong> titulares dos dados, nos
            termos do artigo 52, §7º da Lei Geral de Proteção de Dados.</p>
          <p>Cotia, 31 de março de 2021.</p>
        </PrivacyPolicy>
      </Container>
    </Main>
  );
}

export function PoliticasES() {
  window.scrollTo(0, 0);

  return (
    <Main data-aos="zoom-in" data-aos-duration="1500">
      <Helmet>
        <title>Política de privacidad | Zanaflex - Compuestos de Caucho</title>
        <meta name="description" content="Política de privacidad - Zanaflex" />
        <meta name="keywords" content="Política de privacidad, Zanaflex, Caucho, Compuesto de Caucho" />

        <link rel="canonical" href="https://zanaflex.com.br/politica-de-privacidade/es-ES" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/politica-de-privacidade" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/politica-de-privacidade/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/politica-de-privacidade/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/politica-de-privacidade/en-US" />
      </Helmet>

      <Container>
        <PrivacyPolicy>
          <h1>Política de privacidad</h1>
          <h2>1. PREÁMBULO</h2>
          <p>Esta Política de Privacidad pretende demostrar el compromiso de <strong><a href="https://www.zanaflex.com.br">ZANAFLEX BORRACHAS LTDA.</a></strong>, persona jurídica de Derecho Privado, inscrita en el CNPJ/MF
            bajo el N° 62.055.710/0001-76, con domicilio en Avenida Vasco Massafeli, 1010 – CEP: 06703-
            600 - Cotia - SP (de ahora en adelante <strong>“ZANAFLEX”</strong>) con privacidad y protección de datos
            personal recopilado de su <strong>SUJETOS</strong>, estableciendo normas sobre recaudación,
            registro, almacenamiento, uso, intercambio, enriquecimiento y eliminación de
            datos personales recopilados en el marco de los servicios prestados, de conformidad con el
            leyes vigentes.
          </p>
          <p>
            Tú (<strong>SUJETO</strong>) declara que ha leído las reglas de este
            documento y los Términos de uso, siendo plenamente consciente de todo su contenido,
            dando así su libre y expresa conformidad con los términos aquí estipulados.
          </p>
          <h2>2. INFORMACIÓN GENERAL Y DEFINICIONES</h2>
          <p>
            La <strong>ZANAFLEX</strong> valora la privacidad de sus empleados y creó esta Política de Privacidad
            Privacidad para demostrar su compromiso de proteger su privacidad y su
            datos personales, de conformidad con la Ley General de Protección de Datos de Brasil (<strong>LGPD</strong>) y otras leyes
            sobre el tema, además de describir cómo se protege su privacidad
            a través de <strong>ZANAFLEX</strong> al recopilar, procesar y almacenar su información personal.
          </p>
          <p>
            La <strong>ZANAFLEX</strong> sigue el Código de conducta de ZANAFLEX ("Código de conducta") que
            representa la misión, visión y valores de la <strong>ZANAFLEX</strong>, realizado con el fin de guiar
            y retratar los valores prioritarios de esta Compañía desde sus inicios, siempre
            visando una actuación ética de todos sus colaboradores.
          </p>
          <p>
            <p><strong>1.1 Definiciones</strong></p>
            <strong>Sujeto:</strong> todas las personas físicas que prestarán algún servicio, mayores de 18 (dieciocho)
            años o emancipado y en plena capacidad para realizar los actos de la vida civil o la
            absoluta o relativamente incapaz debidamente representado o asistido.
          </p>
          <p>
            <strong>Datos personales:</strong> significa cualquier información proporcionada y/o recopilada
            a través de <strong>ZANAFLEX</strong> y/o sus afiliados, por cualquier medio, por público que sea, que: (I)
            identificar, o que, cuando se usa en combinación con otra información manejada
            a través de <strong>ZANAFLEX</strong> identificar a un individuo; o (II) por medio del cual la identificación
            o se puede derivar la información de contacto de un individuo. Los datos
            Los datos personales pueden estar en cualquier medio o formato, incluidos registros electrónicos o
            archivos informatizados y en papel.
          </p>
          <p>
            <strong>Meta:</strong> el objetivo, la finalidad que tiene el <strong>ZANAFLEX</strong> quiere lograr de cada
            acto de procesar información personal.
          </p>
          <p>
            <strong>Necesitar:</strong> justificación por la cual es estrictamente necesario recabar datos personales,
            para lograr el propósito, evitando la recaudación excesiva.
          </p>
          <p>
            <strong>Base legal:</strong> base legal que legitima el tratamiento de datos personales
            para un propósito específico por parte del <strong>ZANAFLEX</strong>.
          </p>
          <p>
            <strong>Consentimiento:</strong> autorización expresa e inequívoca otorgada por el <strong>SUJETO</strong> del dado
            personal para que el <strong>ZANAFLEX</strong> procesar sus datos personales para un propósito
            anteriormente descrita, en la que la base jurídica necesaria para el acto exige la
            autorización expresa de <strong>SUJETO</strong>.
          </p>
          <p>
            Esta política se aplica generalmente a cualquier empleado o proveedor de servicios de
            <strong>ZANAFLEX</strong> y potenciales <strong>SUJETOS</strong> de datos que utilizan los servicios
            ofrecido por <strong>ZANAFLEX</strong>, incluyendo Usuarios de sistemas, sitios web u otros medios
            operado por <strong>ZANAFLEX</strong> , y resume cómo el <strong>ZANAFLEX</strong> puede recolectar, producir,
            recibir, clasificar, usar, acceder, reproducir, transmitir, distribuir, procesar,
            archivar, almacenar, suprimir, evaluar o controlar la información, modificar,
            comunicar, transferir, difundir o extraer los datos recabados, incluida la información
            información de identificación personal de acuerdo con las bases legales aplicables y todas las leyes aplicables de
            privacidad y protección de datos en su lugar.
          </p>
          <p>
            El <strong>SUJETO</strong> declara tener al menos 18 (dieciocho) años de edad y tener plena y expresa capacidad
            por la aceptación de los términos y condiciones de esta Política de Privacidad y el Término de
            Consentimiento para todos los efectos legales. Si no tiene plena capacidad civil
            identificado anteriormente, declara la <strong>SUJETO</strong> ser asistido por su representante
            persona jurídica, como padres, tutores, etc.
          </p>
          <p>
            Si el <strong>SUJETO</strong> no se ajusta a la descripción anterior y/o no está de acuerdo, incluso si en
            parte, con los términos y condiciones contenidos en esta Política de Privacidad, no
            acceder y/o utilizar los servicios ofrecidos por <strong>ZANAFLEX</strong>, además de ser parte de
            su personal o prestar cualquier otro servicio a la empresa, excepto en el
            caso en que los derechos y libertades fundamentales de los <strong>SUJETO</strong> que requieren la
            protección de datos personales, en cuyo caso el <strong>SUJETO</strong> debe ponerse en contacto con el
            oficial de datos (DPO) para el ajuste en los términos antes mencionados.
          </p>
          <h2>3. RECOPILACIÓN Y USOS DE INFORMACIÓN PERSONAL</h2>
          <p>El <strong>SUJETO</strong> Usted es consciente de que a sabiendas y voluntariamente proporciona información
            a través de <strong>RH</strong>, <strong>SESMT</strong>, <strong>WEBSITE</strong>, <strong>COMERCIAL</strong> o cualquier otro contacto que
            representan el <strong>ZANAFLEX</strong>.
          </p>
          <p>
            Cuando el <strong>SUJETO</strong> se registra y/o llena los formularios ofrecidos
            a través de <strong>ZANAFLEX</strong>, incluyendo en los sistemas operados por ella, ciertos Datos Personales
            solicitado se mantendrá confidencial y solo se utilizará para el propósito para el cual
            motivó el registro.
          </p>
          <h2>4. COMPARTIR Y PROCESAR INFORMACIÓN PERSONAL </h2>
          <p>La <strong>ZANAFLEX</strong> no pondrá los datos personales recopilados a disposición de los proveedores de listas de correo
            correo electrónico sin su consentimiento expreso por parte del <strong>SUJETOS</strong>.
          </p>
          <p>
            La <strong>ZANAFLEX</strong> podrá compartir los Datos Personales recopilados con terceros, en
            siguientes situaciones y dentro de los límites exigidos y autorizados por la ley:
          </p>
          <ol>
            <li>
              Con sus clientes y socios cuando sea necesario y/o apropiado para la prestación
              de servicios o marketing de productos relacionados;
            </li>
            <li>
              Con empresas y personas físicas contratadas para la realización de determinadas
              actividades y servicios en nombre de <strong>ZANAFLEX</strong>;
            </li>
            <li>
              Con empresas del grupo (si las hubiere);
            </li>
            <li>
              Con proveedores y colaboradores para realizar los servicios contratados o
              comercializar productos con <strong>ZANAFLEX</strong>;
            </li>
            <li>
              Para fines administrativos tales como: investigación, planificación, desarrollo
              servicios, seguridad y gestión de riesgos.
            </li>
            <li>
              Cuando sea necesario como consecuencia de una obligación legal, la determinación de
              autoridad competente, o decisión judicial.
            </li>
          </ol>
          <p>
            En caso de compartir Datos Personales con terceros, todos los sujetos
            mencionados en los incisos I a VI utilizará los Datos Personales compartidos de una
            coherentes y de acuerdo con los fines para los que fueron recopilados (o con el
            que <strong>SUJETO</strong> previamente consentido) y según lo determine
            esta Política de privacidad, otras declaraciones de privacidad de sitios web o países,
            y todas las leyes de privacidad y protección de datos aplicables.
          </p>
          <h2>5. RAZONES LEGALES PARA CEDER SUS DATOS</h2>
          <p>
            En determinadas circunstancias, el <strong>ZANAFLEX</strong> puede compartir datos personales en la medida
            necesario o apropiado, a agencias gubernamentales, consultores y otros terceros
            con el propósito de cumplir con la ley aplicable o con una orden o citación
            tribunal, o incluso si el <strong>ZANAFLEX</strong> cree de buena fe que tal acción es necesaria
            por:
          </p>
          <ol>
            <li>Cumplir con la legislación que exige dicha divulgación;</li>
            <li>
              Investigar, prevenir o tomar medidas relacionadas con actividades ilegales sospechosas
              o real o para cooperar con organismos públicos o para proteger la seguridad
              nacional;
            </li>
            <li>Ejecución de sus contratos;</li>
            <li>
              Investigar y defenderse de cualquier reclamo o acusación de
              la tercera;
            </li>
            <li>
              Proteger la seguridad o integridad de los servicios y productos vendidos
              (por ejemplo, compartir con empresas que están bajo amenaza
              similar);
            </li>
            <li>
              Ejercer o proteger los derechos, la propiedad y la seguridad de ZANAFLEX y sus
              empresas afiliadas (si las hay);
            </li>
            <li>
              Proteger los derechos y la seguridad personal de sus empleados, usuarios o el
              público;
            </li>
            <li>
              En caso de venta, compra, fusión, reorganización, liquidación o disolución
              da <strong>ZANAFLEX</strong>.
            </li>
          </ol>
          <p>
            La <strong>ZANAFLEX</strong> informará al respectivo <strong>SUJETOS</strong> sobre posibles demandas legales
            que resulten en la divulgación de información personal, de conformidad con lo establecido en el
            inciso V, a menos que dicho conocimiento esté prohibido por ley o por orden judicial
            o incluso si la solicitud es una emergencia. LOS <strong>ZANAFLEX</strong> puede disputar estos
            demandas si considera que las solicitudes son excesivas, vagas o realizadas por las autoridades
            incompetente.
          </p>
          <h2>6. SEGURIDAD DE LA INFORMACIÓN PERSONAL</h2>
          <p>
            Todos los Datos Personales serán almacenados en la base de datos de <strong>ZANAFLEX</strong> o en la base
            de datos mantenidos "en la nube" o en "servidores internos" por proveedores de datos.
            servicios contratados por <strong>ZANAFLEX</strong>, que están debidamente de acuerdo con las
            legislación vigente en materia de datos.
          </p>
          <p>
            La <strong>ZANAFLEX</strong> y sus proveedores utilizan varios procedimientos de seguridad para
            proteger la confidencialidad, seguridad e integridad de sus Datos Personales,
            previniendo la ocurrencia de eventuales perjuicios por el tratamiento de estos datos.
          </p>
          <p>
            Aunque el <strong>ZANAFLEX</strong> utilice medidas de seguridad y controle su sistema para
            escanear en busca de vulnerabilidades y ataques para proteger sus datos personales contra
            divulgación no autorizada, mal uso o alteración, la <strong>SUJETO</strong> entender y aceptar que
            no hay garantías de que la información no será accesible, divulgada,
            alterados o destruidos por incumplimiento de cualquiera de los elementos físicos, técnicos,
            o administrativo.
          </p>
          <h2>7. RETENCIÓN DE DATOS</h2>
          <p>
            La <strong>ZANAFLEX</strong> conserva todos los datos facilitados, incluidos los Datos Personales, mientras el
            registracion de <strong>SUJETO</strong> es activa y necesaria para el logro de sus
            servicios.
          </p>
          <p>
            La <strong>ZANAFLEX</strong> conservará sus Datos personales y mantendrá sus datos almacenados hasta
            eventual solicitud de exclusión, o según los plazos descritos en el
            Legislación vigente.
          </p>
          <p>
            La <strong>ZANAFLEX</strong> puede conservar sus Datos Personales después de recibir su solicitud de
            exclusión, si es necesario para cumplir con las obligaciones legales, resolver
            disputas, mantener la seguridad, prevenir el fraude y el abuso, y garantizar el cumplimiento de
            contratos.
          </p>
          <h2>8. BASES LEGALES PARA EL TRATAMIENTO</h2>
          <p>
            La <strong>ZANAFLEX</strong> solo procesa datos personales en situaciones en las que está autorizado
            legalmente o con su consentimiento expreso e inequívoco del <strong>SUJETO</strong>.
          </p>
          <p>
            Como se describe en esta Política, la <strong>ZANAFLEX</strong> tener bases legales para cobrar,
            producir, recibir, clasificar, utilizar, acceder, reproducir, transmitir, distribuir,
            tratar, archivar, almacenar, eliminar, evaluar o controlar la información,
            modificar, comunicar, ceder, difundir o extraer datos sobre el <strong>SUJETO</strong>.
          </p>
          <p>
            Las bases legales incluyen su consentimiento (obtenido de forma expresa e inequívoca en el
            Formulario de Consentimiento), contratos y procedimientos contractuales preliminares (en
            que el procesamiento es necesario para celebrar el contrato con cualquier otra parte) y
            intereses legítimos, siempre que dicho tratamiento no vulnere sus derechos y libertades.
          </p>
          <p>
            Tales intereses incluyen la protección de el <strong>SUJETO</strong> e la <strong>ZANAFLEX</strong> de amenazas, cumplir
            la legislación aplicable, el ejercicio regular de los derechos en los procesos judiciales,
            administrativo o de arbitraje, permitir la realización o administración de negocios,
            incluyendo control de calidad, informes y servicios ofrecidos, gestionar transacciones
            negocios, comprender y mejorar las relaciones comerciales y con los clientes, y
            permitir el <strong>SUJETOS</strong> encontrar oportunidades económicas.
          </p>
          <p>
            El <strong>SUJETO</strong> tiene derecho a denegar o retirar el consentimiento prestado al <strong>ZANAFLEX</strong>,
            cuando esta sea la base legal para el tratamiento de datos personales, pudiendo
            los <strong>ZANAFLEX</strong> rescindir la prestación de sus servicios para este solicitante en caso de
            ocurrencia de tal solicitud.
          </p>
          <p>
            Si tiene preguntas sobre la base legal para recopilar, procesar y almacenar
            sus datos personales, póngase en contacto con el <strong>ZANAFLEX</strong> y su persona a cargo de
            (DPO) a través del correo electrónico dpo@zanaflex.com.br.
          </p>
          <h2>9. DERECHO DE ACCESO Y CONTROL DE SUS DATOS PERSONALES</h2>
          <p>
            La <strong>ZANAFLEX</strong> ofertas a el <strong>SUJETO</strong> varias opciones de qué hacer con sus datos
            Datos personales recogidos, tratados y almacenados, incluida su supresión y/o corrección. El
            <strong>SUJETO</strong> puede:
          </p>
          <ol>
            <li>
              <strong>Borrar datos:</strong> el <strong>SUJETO</strong> puede solicitar la eliminación de algunos de sus Datos
              Personales (por ejemplo, si ya no son necesarios para proporcionarle la
              servicios).
            </li>
            <li>
              <strong>Cambiar o corregir datos:</strong> o <strong>SUJETO</strong> puede editar o solicitar la edición de algunos
              de sus Datos Personales. El <strong>SUJETO</strong> también puede solicitar actualizaciones,
              modificaciones o correcciones de sus datos en determinados casos, especialmente si
              son incorrectos.
            </li>
            <li>
              <strong>Poner objeciones, límites o restricciones en el uso de datos:</strong> el <strong>SUJETO</strong> él puede
              solicitar dejar de usar todos o algunos de sus Datos Personales (por
              ejemplo, si no tenemos derecho a seguir utilizándolos), o limitar nuestra
              uso de dichos datos (por ejemplo, si sus Datos personales son incorrectos
              almacenados ilegalmente), señalando que la <strong>ZANAFLEX</strong> puede tratar el
              Datos Personales de conformidad con las bases legales vigentes.
            </li>
            <li>
              <strong>El Usuario tiene derecho a acceder o tomar sus datos:</strong> el <strong>SUJETO</strong> puede solicitar
              una copia de sus Datos Personales y los datos que el <strong>SUJETO</strong> proporcionado en un
              formato legible en forma impresa o electrónica.
            </li>
          </ol>
          <p>
            El <strong>SUJETO</strong> puede realizar las solicitudes enumeradas anteriormente poniéndose en contacto con el
            nuestro oficial de datos a través del correo electrónico dpo@zanaflex.com.br y estas solicitudes
            serán considerados de acuerdo con las leyes aplicables.
          </p>
          <h2>10. REVISIONES A LA POLÍTICA DE PRIVACIDAD </h2>
          <p>
            Si la <strong>ZANAFLEX</strong> modificar esta Política de Privacidad, dichos cambios serán
            publicado conspicuamente en el <strong>SGI</strong> y no <strong>WEBSITE</strong> de la <strong>ZANAFLEX</strong>. Esta Política es válida para
            a partir del 1 de abril de 2021. Si el <strong>SUJETO</strong> tiene alguna pregunta con respecto a la
            políticas de privacidad, póngase en contacto con la <strong>ZANAFLEX</strong>, a través de
            direcciones a continuación / canal de servicio del oficial de datos.
          </p>
          <p>
            Este documento es la Primera Versión de la Política de Privacidad, y puede ser
            modificado en cualquier momento, según el párrafo anterior. En caso de actualización
            de esta Política de Privacidad, el <strong>SUJETO</strong> será informado y recibirá la nueva versión para
            validación y aceptación al final de la lectura.
          </p>
          <p>
            No utilizamos ningún tipo de decisión automatizada que le afecte, <strong>SUJETO</strong>.
          </p>
          <p>
            Si terceras empresas llevan a cabo el tratamiento de cualquier dato que
            recopilamos, deben respetar las condiciones aquí estipuladas y nuestras reglas
            Seguridad de la Información, obligatorio.
          </p>
          <p>
            Si alguna disposición de esta Política de privacidad se considera ilegal o
            ilegítima por la autoridad del lugar donde reside o su conexión a Internet, la
            otras condiciones permanecerán en pleno vigor y efecto.
          </p>
          <p>
            El <strong>SUJETO</strong> reconoce que toda comunicación por correo electrónico (a las direcciones
            informado en su registro), SMS, aplicaciones de comunicación instantánea o cualquier otro
            por lo demás, lo digital y lo virtual también son válidos, eficaces y suficientes para la
            divulgación de cualquier asunto que se refiera a los servicios que prestamos, así como la
            condiciones de su prestación o de cualquier otro asunto de que se trate, salvo la
            disposiciones expresamente distintas a las establecidas en esta Política de Privacidad.
          </p>
          <h2>11. CONTACTO</h2>
          <p>
            La <strong>ZANAFLEX</strong> designa a Erminio Heredia Díaz como encargado de
            datos (“RPD”). El <strong>SUJETO</strong> puedes contactar con el DPO en el siguiente número de teléfono
            +55 (11) 2165-3450 o por correo electrónico: dpo@zanaflex.com.br
          </p>
          <h2>12. JURISDICCIÓN DE MEDIACIÓN Y ELECCIÓN</h2>
          <p>
            Esta póliza está sujeta a la Ley de la República Federativa de Brasil y del Distrito Judicial
            de Cotia es competente para resolver cualquier controversia en relación con la misma. </p>
          <p>
            En caso de incidencia con los Datos Personales, la <strong>ZANAFLEX</strong>, en adelante, se reserva el derecho de nominar
            JUSPRO – Justicia Sin Proceso Mediación y Conciliación S/S Ltda. como Cámara de
            Mediación capaz de resolver cuestiones relativas a los <strong>SUJETO</strong> interesados, en
            términos del artículo 52, §7 de la Ley General de Protección de Datos.</p>
          <p>Cotia, 31 de marzo de 2021.</p>
        </PrivacyPolicy>
      </Container>
    </Main>
  );
}

export function PoliticasUS() {
  window.scrollTo(0, 0);

  return (
    <Main data-aos="zoom-in" data-aos-duration="1500">
      <Helmet>
        <title>Privacy Policy | Zanaflex - Rubber Compounds</title>
        <meta name="description" content="Privacy Policy - Zanaflex" />
        <meta name="keywords" content="Privacy Policy, Zanaflex, Rubber, Rubber Compound" />

        <link rel="canonical" href="https://zanaflex.com.br/politica-de-privacidade/en-US" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/politica-de-privacidade" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/politica-de-privacidade/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/politica-de-privacidade/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/politica-de-privacidade/en-US" />
      </Helmet>

      <Container>
        <PrivacyPolicy>
          <h1>Privacy Policy</h1>
          <h2>1. PREAMBLE</h2>
          <p>This Privacy Policy is intended to demonstrate the commitment of <strong><a href="https://www.zanaflex.com.br">ZANAFLEX BORRACHAS LTDA.</a></strong>, a legal entity governed by Private Law, registered with the CNPJ/MF
            under No. 62.055.710/0001-76, headquartered at Avenida Vasco Massafeli, 1010 – CEP: 06703-
            600 - Cotia - SP (hereinafter <strong>“ZANAFLEX”</strong>) with privacy and data protection
            personal collected from your <strong>HOLDERS</strong>,establishing rules on collection,
            registration, storage, use, sharing, enrichment and elimination of
            personal data collected within the scope of the services provided, in accordance with the
            laws in force.
          </p>
          <p>
            You (<strong>HOLDER</strong>) declares that it has read the rules of this
            document and the Terms of Use, being fully aware of its entire content,
            thus giving your free and express agreement with the terms stipulated herein.
          </p>
          <h2>2. GENERAL INFORMATION AND DEFINITIONS</h2>
          <p>
            The <strong>ZANAFLEX</strong> values ​​the privacy of its employees and created this Privacy Policy
            Privacy to demonstrate your commitment to protecting your privacy and your
            personal data, pursuant to the Brazilian General Data Protection Law (<strong>LGPD</strong>) and other laws
            on the topic, as well as describing how your privacy is protected
            through the <strong>ZANAFLEX</strong> when collecting, processing and storing your personal information.
          </p>
          <p>
            The <strong>ZANAFLEX</strong> follows the ZANAFLEX Code of Conduct (“Code of Conduct”) which
            represents the mission, vision and values ​​of the <strong>ZANAFLEX</strong>, made with the aim of guiding
            and portray the priority values ​​of this Company since its inception, always
            aiming at an ethical performance of all its collaborators.
          </p>
          <p>
            <p><strong>1.1 Definitions</strong></p>
            <strong>Holder:</strong> all individuals who will provide some service, over 18 (eighteen)
            years or emancipated and fully capable of performing the acts of civil life or
            absolutely or relatively incapable properly represented or assisted.
          </p>
          <p>
            <strong>Personal data:</strong> means any information provided and/or collected
            through the <strong>ZANAFLEX</strong> and/or its affiliates, by any means, however public, that: (I)
            identify, or that, when used in combination with other information handled
            through the <strong>ZANAFLEX</strong> identify an individual; or (II) by means of which the identification
            or contact information for an individual may be derived. The data
            Personal data may be in any medium or format, including electronic records or
            computerized as well as paper-based files.
          </p>
          <p>
            <strong>Goal:</strong> the objective, the purpose that the <strong>ZANAFLEX</strong> want to achieve from each
            act of processing personal information.
          </p>
          <p>
            <strong>Need:</strong> justification for which it is strictly necessary to collect personal data,
            to achieve the purpose, avoiding excessive collection.
          </p>
          <p>
            <strong>Legal basis:</strong> legal basis that makes the processing of personal data legitimate
            for a specific purpose on the part of the <strong>ZANAFLEX</strong>.
          </p>
          <p>
            <strong>Consent:</strong> express and unequivocal authorization given by the <strong>HOLDER</strong> of the die
            staff so that the <strong>ZANAFLEX</strong> process your personal data for a purpose
            previously described, in which the legal basis necessary for the act demands the
            express authorization from the <strong>HOLDER</strong>.
          </p>
          <p>
            This policy generally applies to any employee or service provider of
            <strong>ZANAFLEX</strong> and potentials <strong>HOLDERS</strong> of data that use the services
            offered by <strong>ZANAFLEX</strong>, including Users of systems, websites or other means
            operated by <strong>ZANAFLEX</strong> , and sums up how the <strong>ZANAFLEX</strong> can collect, produce,
            receive, classify, use, access, reproduce, transmit, distribute, process,
            file, store, eliminate, evaluate or control the information, modify,
            communicate, transfer, disseminate or extract the collected data, including the information
            personally identifiable information, in accordance with applicable legal bases and all applicable laws of
            privacy and data protection in place.
          </p>
          <p>
            The <strong>HOLDER</strong> declares to be at least 18 (eighteen) years old and to have full and express capacity
            for the acceptance of the terms and conditions of this Privacy Policy and the Term of
            Consent for all legal purposes. If you do not have full civil capacity
            identified above, declares the <strong>HOLDER</strong> being assisted by your representative
            legal person, such as parents, guardians, etc.
          </p>
          <p>
            If the <strong>HOLDER</strong> does not fit the description above and/or does not agree, even if in
            party, with the terms and conditions contained in this Privacy Policy, shall not
            access and/or use the services offered by <strong>ZANAFLEX</strong>, as well as being part of
            its staff or provide any other services to the company, except in the
            case in which the fundamental rights and freedoms of the <strong>HOLDER</strong> that require the
            protection of personal data, in which case the <strong>HOLDER</strong> should contact the
            data officer (DPO) for the adjustment in the aforementioned terms.
          </p>
          <h2>3. COLLECTION AND USES OF PERSONAL INFORMATION</h2>
          <p>The <strong>HOLDER</strong> You are aware that you knowingly and voluntarily provide information
            through the <strong>HR</strong>, <strong>SESMT</strong>, <strong>WEBSITE</strong>, <strong>COMMERCIAL</strong> or any other contacts that
            represent the <strong>ZANAFLEX</strong>.
          </p>
          <p>
            When the <strong>HOLDER</strong> register and/or fill in the forms offered
            through the <strong>ZANAFLEX</strong>, including in the systems operated by it, certain Personal Data
            requested will be kept confidential and will only be used for the purpose for which
            motivated the registration.
          </p>
          <h2>4. SHARING AND PROCESSING OF PERSONAL INFORMATION </h2>
          <p>The <strong>ZANAFLEX</strong> will not make collected Personal Data available to mailing list providers
            email without your express consent from the <strong>HOLDERS</strong>.
          </p>
          <p>
            The <strong>ZANAFLEX</strong> may share the Personal Data collected with third parties, in
            following situations and within the limits required and authorized by law:
          </p>
          <ol>
            <li>
              With its customers and partners when necessary and/or appropriate for the provision
              of services or marketing of related products;
            </li>
            <li>
              With companies and individuals hired to carry out certain
              activities and services on behalf of <strong>ZANAFLEX</strong>;
            </li>
            <li>
              With group companies (if any);
            </li>
            <li>
              With suppliers and partners to perform the contracted services or
              marketing products with <strong>ZANAFLEX</strong>;
            </li>
            <li>
              For administrative purposes such as: research, planning, development
              services, security and risk management.
            </li>
            <li>
              When necessary as a result of legal obligation, determination of
              competent authority, or court decision.
            </li>
          </ol>
          <p>
            In the event of sharing Personal Data with third parties, all subjects
            mentioned in items I to VI shall use the Personal Data shared in a
            consistent and in accordance with the purposes for which they were collected (or with the
            what the <strong>HOLDER</strong> previously consented) and as determined by
            this Privacy Policy, other website or country privacy statements,
            and all applicable privacy and data protection laws.
          </p>
          <h2>5. LEGAL REASONS FOR DISCLOSING YOUR DATA</h2>
          <p>
            In certain circumstances, the <strong>ZANAFLEX</strong> may share Personal Data to the extent
            necessary or appropriate, to government agencies, consultants and other third parties
            for the purpose of complying with applicable law or with an order or subpoena
            court, or even if the <strong>ZANAFLEX</strong> believe in good faith that such action is necessary
            for:
          </p>
          <ol>
            <li>Comply with legislation that requires such disclosure;</li>
            <li>
              Investigate, prevent or take action related to suspected illegal activities
              or real or to cooperate with public bodies or to protect the security
              national;
            </li>
            <li>Execution of its contracts;</li>
            <li>
              Investigate and defend against any claims or allegations of
              the 3rd;
            </li>
            <li>
              Protect the security or integrity of the services and products sold
              (for example, sharing with companies that are under threat
              similar);
            </li>
            <li>
              Exercise or protect the rights, property and safety of ZANAFLEX and its
              affiliated companies (if any);
            </li>
            <li>
              Protect the rights and personal safety of your employees, users or the
              public;
            </li>
            <li>
              In the event of sale, purchase, merger, reorganization, liquidation or dissolution
              gives <strong>ZANAFLEX</strong>.
            </li>
          </ol>
          <p>
            The <strong>ZANAFLEX</strong> will inform the respective <strong>HOLDERS</strong> about possible legal demands
            that result in the disclosure of personal information, in accordance with what has been set out in the
            item V, unless such knowledge is prohibited by law or prohibited by court order
            or even if the request is an emergency. The <strong>ZANAFLEX</strong> can contest these
            demands if you judge the requests to be excessive, vague or made by authorities
            incompetentes.
          </p>
          <h2>6. SECURITY OF PERSONAL INFORMATION</h2>
          <p>
            All Personal Data will be stored in the database of <strong>ZANAFLEX</strong> or on base
            of data held “in the cloud” or on “internal servers” by data providers.
            services contracted by <strong>ZANAFLEX</strong>, which are duly in accordance with the
            current data legislation.
          </p>
          <p>
            The <strong>ZANAFLEX</strong> and its suppliers use various security procedures to
            protect the confidentiality, security and integrity of your Personal Data,
            preventing the occurrence of eventual damages due to the processing of this data.
          </p>
          <p>
            although the <strong>ZANAFLEX</strong> use security measures and monitor your system to
            scan for vulnerabilities and attacks to protect your Personal Data against
            unauthorized disclosure, misuse or alteration, the <strong>HOLDER</strong> understand and agree that
            there are no guarantees that the information will not be accessible, disclosed,
            altered or destroyed by breach of any of the physical, technical,
            or administrative.
          </p>
          <h2>7. DATA RETENTION</h2>
          <p>
            The <strong>ZANAFLEX</strong> retains all data provided, including Personal Data, while the
            registration of <strong>HOLDER</strong> is active and as necessary for the achievement of its
            services.
          </p>
          <p>
            The <strong>ZANAFLEX</strong> will retain your Personal Data and keep your data stored until
            eventual exclusion request, or according to the periods described in the
            Current legislation.
          </p>
          <p>
            The <strong>ZANAFLEX</strong> may keep your Personal Data after receiving your request for
            exclusion, if necessary to comply with legal obligations, resolve
            disputes, maintain security, prevent fraud and abuse, and ensure compliance with
            contracts.
          </p>
          <h2>8. LEGAL BASES FOR PROCESSING</h2>
          <p>
            The <strong>ZANAFLEX</strong> only processes Personal Data in situations where it is authorized
            legally or with your express and unequivocal consent of the <strong>HOLDER</strong>.
          </p>
          <p>
            As described in this Policy, the <strong>ZANAFLEX</strong> have legal bases to collect,
            produce, receive, classify, use, access, reproduce, transmit, distribute,
            process, archive, store, eliminate, evaluate or control the information,
            modify, communicate, transfer, disseminate or extract data about the <strong>HOLDER</strong>.
          </p>
          <p>
            The legal bases include your consent (expressly and unequivocally collected in the
            Consent Form), contracts and preliminary contractual procedures (in
            that the processing is necessary to enter into the contract with any other party) and
            legitimate interests, provided that such processing does not violate your rights and freedoms.
          </p>
          <p>
            Such interests include protecting the <strong>HOLDER</strong> and the <strong>ZANAFLEX</strong> of threats, comply with
            applicable legislation, the regular exercise of rights in legal proceedings,
            administrative or arbitration, enable the performance or administration of business,
            including quality control, reports and services offered, manage transactions
            businesses, understand and improve business and customer relationships, and
            allow the <strong>HOLDERS</strong> encontrem oportunidades econômicas.
          </p>
          <p>
            The <strong>HOLDER</strong> has the right to deny or withdraw consent given to the <strong>ZANAFLEX</strong>,
            when this is the legal basis for processing personal data, and may
            The <strong>ZANAFLEX</strong> terminate the performance of its services for this applicant in the event
            occurrence of such a request.
          </p>
          <p>
            If you have questions about the legal basis for collecting, processing and storing
            your personal data, please contact the <strong>ZANAFLEX</strong> and your person in charge of
            (DPO) through the e-mail dpo@zanaflex.com.br.
          </p>
          <h2>9. RIGHT TO ACCESS AND CONTROL YOUR PERSONAL DATA</h2>
          <p>
            The <strong>ZANAFLEX</strong> offers to <strong>HOLDER</strong> several options of what to do with your Data
            Personal data collected, processed and stored, including their deletion and/or correction. The
            <strong>HOLDER</strong> can:
          </p>
          <ol>
            <li>
              <strong>Delete data:</strong> The <strong>HOLDER</strong> may request the deletion of some of your Personal Data
              (for example, if they are no longer needed to provide you with the
              services).
            </li>
            <li>
              <strong>Change or correct data:</strong> the <strong>HOLDER</strong> can edit or request the editing of some
              of your Personal Data. The <strong>HOLDER</strong> can also request updates,
              changes or corrections to your data in certain cases, especially if
              they are incorrect.
            </li>
            <li>
              <strong>Placing objections, limits or restrictions on the use of data:</strong> the <strong>HOLDER</strong> he can
              request to stop using all or some of your Personal Data (for
              example, if we do not have the right to continue to use them), or limit our
              use of such data (for example, if your Personal Data is incorrect
              or stored illegally), noting that the <strong>ZANAFLEX</strong> can treat the
              Personal Data in accordance with the legal bases in force.
            </li>
            <li>
              <strong>The User has the right to access or take their data:</strong> The <strong>HOLDER</strong> can request
              a copy of your Personal Data and the data that you <strong>HOLDER</strong> provided in a
              readable format in printed or electronic form.
            </li>
          </ol>
          <p>
            The <strong>HOLDER</strong> you can make the requests listed above by contacting the
            our data manager via e-mail dpo@zanaflex.com.br and these requests
            will be considered in accordance with applicable laws.
          </p>
          <h2>10. REVISIONS TO THE PRIVACY POLICY </h2>
          <p>
            If the <strong>ZANAFLEX</strong> modify this Privacy Policy, such changes will be
            conspicuously published on the <strong>SGI</strong> and on the <strong>WEBSITE</strong> the <strong>ZANAFLEX</strong>. This Policy is valid to
            from April 1, 2021. If the <strong>HOLDER</strong> have any questions regarding the
            privacy policies, please contact <strong>ZANAFLEX</strong>, through the
            addresses below / service channel of the data officer.
          </p>
          <p>
            This document is the First Version of the Privacy Policy, and it may be
            changed at any time, as per the previous paragraph. In case of update
            of this Privacy Policy, the <strong>HOLDER</strong> will be informed and will receive the new version for
            validation and acceptance at the end of reading.
          </p>
          <p>
            We do not use any type of automated decision that impacts you, <strong>HOLDER</strong>.
          </p>
          <p>
            If third-party companies carry out the processing of any data that
            we collect, they must respect the conditions stipulated herein and our rules
            Information Security, mandatory.
          </p>
          <p>
            If any provision of this Privacy Policy is considered illegal or
            illegitimate by the authority of the place where you reside or your connection to the Internet, the
            other conditions will remain in full force and effect.
          </p>
          <p>
            The <strong>HOLDER</strong> acknowledges that all communication by e-mail (to the addresses
            informed in your registration), SMS, instant communication applications or any other
            otherwise digital and virtual are also valid, effective and sufficient for the
            disclosure of any matter that refers to the services we provide, as well as the
            conditions of its provision or any other matter addressed therein, except for the
            expressly different provisions set forth in this Privacy Policy.
          </p>
          <h2>11. CONTACT</h2>
          <p>
            The <strong>ZANAFLEX</strong> hereby appoints Erminio Heredia Diaz as the person in charge of
            data (“DPO”). The <strong>HOLDER</strong> you can contact the DPO at the following phone number
            +55 (11) 2165-3450 or via email: dpo@zanaflex.com.br
          </p>
          <h2>12. MEDIATION AND ELECTION FORUM</h2>
          <p>
            This policy is subject to the Law of the Federative Republic of Brazil and the Judicial District
            de Cotia is competent to settle any dispute in relation to it. </p>
          <p>
            In the event of an incident with Personal Data, the <strong>ZANAFLEX</strong>, from now on, reserves the right to nominate
            JUSPRO – Justice Without Proceedings Mediation and Conciliation S/S Ltda. as Chamber of
            Mediation capable of resolving issues involving the <strong>HOLDER</strong> HOLDERS of the data, in
            terms of article 52, §7 of the General Data Protection Law.</p>
          <p>Cotia, March 31, 2021.</p>
        </PrivacyPolicy>
      </Container>
    </Main>
  );
}