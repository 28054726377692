import { Main, Container } from './styles';
import logoImg from '../../assets/logo-solo.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export function Pesquisa() {
  return (
    <Main>
      <Helmet>
        <title>Pesquisa de Satisfação | Zanaflex - Compostos de Borracha</title>
        <meta name="description" content="Pesquisa de Satisfação - Zanaflex" />
        <meta name="keywords" content="Pesquisa de Satisfação, Zanaflex, Borracha, Composto de Borracha" />

        <link rel="canonical" href="https://zanaflex.com.br/pesquisa-satisfacao/QHX1xVD4QkeGsp6g7Q3G6BnOc-oHGbdIoP4r0HMRIKpUQ1M5UFFaSDROR1lERTNUT0o3TU5ERVhENS4u" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/pesquisa-satisfacao/QHX1xVD4QkeGsp6g7Q3G6BnOc-oHGbdIoP4r0HMRIKpUQ1M5UFFaSDROR1lERTNUT0o3TU5ERVhENS4u" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/pesquisa-satisfacao/QHX1xVD4QkeGsp6g7Q3G6BnOc-oHGbdIoP4r0HMRIKpUQ1M5UFFaSDROR1lERTNUT0o3TU5ERVhENS4u" />
      </Helmet>

      <Container data-aos="fade-right">
        <div>
          <h1>Obrigado por escolher a ZANAFLEX</h1>
          <p>Nos ajude respondendo nossa pesquisa de satisfação!</p>
          <div data-aos="fade-left">
            <img src={logoImg} alt="Logo Zanaflex" />
          </div>
        </div>
        <div>
          <iframe width="640px" title="Pesquisa" src="https://forms.office.com/r/iqN2v0Kz5f?embed=true" frameBorder="0" allowFullScreen={true}> </iframe>
          <small>Ao continuar com o acesso, você concorda com a nossa <Link to="/politica-de-privacidade">política de privacidade.</Link></small>
        </div>
      </Container>
    </Main>
  );
}