import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import laboratoriosImg from '../../assets/laboratorios.png';
import analises2Img from '../../assets/analises2.png';

import firstImg from '../../assets/carousel/infra/1.png';
import secondImg from '../../assets/carousel/infra/2.png';
import thirdImg from '../../assets/carousel/infra/3.png';
import fourthImg from '../../assets/carousel/infra/4.png';
import fiveImg from '../../assets/carousel/infra/5.png';

import firstAnalisesImg from '../../assets/carousel/analises/1.png';
import secondAnalisesImg from '../../assets/carousel/analises/2.png';
import thirdAnalisesImg from '../../assets/carousel/analises/3.png';
import fourthAnalisesImg from '../../assets/carousel/analises/4.png';

import { Main, Container, SubMenu } from './styles';
import SlickSlider from '../../components/Slider';
import { Helmet } from 'react-helmet';

type InfraestruturaProps = {
  showInstalacoesBool?: boolean;
  showLaboratoriosBool?: boolean;
  showAnalisesBool?: boolean;
}

export function Infraestrutura({ showInstalacoesBool = true, showLaboratoriosBool = false, showAnalisesBool = false }: InfraestruturaProps) {
  const [showInstalacoes, setShowInstalacoes] = useState(showInstalacoesBool);
  const [showLaboratorios, setShowLaboratorios] = useState(showLaboratoriosBool);
  const [showAnalises, setShowAnalises] = useState(showAnalisesBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/infraestrutura/instalacoes") {
      setShowAnalises(false);
      setShowLaboratorios(false);
      setShowInstalacoes(true);
    }

    if (location.pathname === "/infraestrutura/laboratorios") {
      setShowAnalises(false);
      setShowLaboratorios(true);
      setShowInstalacoes(false);
    }

    if (location.pathname === "/infraestrutura/analises") {
      setShowAnalises(true);
      setShowLaboratorios(false);
      setShowInstalacoes(false);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Infraestrutura | Zanaflex - Compostos de Borracha</title>
        <meta name="description" content="Instalações, Laboratórios e Análises - Zanaflex" />
        <meta name="keywords" content="Infraestrutura, Instalações, Laboratórios, Análises, Zanaflex, Borracha, Composto de Borracha" />

        <link rel="canonical" href="https://zanaflex.com.br/infraestrutura" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/infraestrutura" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/infraestrutura/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/infraestrutura/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/infraestrutura/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/infraestrutura/instalacoes" className={showInstalacoes ? 'submenu-active' : 'submenu-disabled'} ><h1>Instalações</h1></Link>
        <Link to="/infraestrutura/laboratorios" className={showLaboratorios ? 'submenu-active' : 'submenu-disabled'}><h1>Laboratórios</h1></Link>
        <Link to="/infraestrutura/analises" className={showAnalises ? 'submenu-active' : 'submenu-disabled'} ><h1>Análises</h1></Link>
      </SubMenu>
      {showInstalacoes && (
        <Container>
          <div data-aos="fade-right">
            <h2>Instalações</h2>
            <ul>
              <li>Planta fabril com aproximadamente 10.000 m² de área coberta</li>
              <li>200 colaboradores</li>
              <li>Capacidade de Produção: 1700 toneladas/mês de produto final</li>
              <li>6 linhas de misturas: 5 dedicadas ao composto preto e 1 para composto colorido</li>
              <li>Cilindros abertos, calandra e pré-formadora</li>
              <li>Misturadores internos (banburies),tangencial e interconectado</li>
              <li>Controle do processo por: temperatura, tempo, pressão, velocidade do rotor, energia (Kwh)</li>
            </ul>
          </div>
          <div data-aos="fade-left" data-aos-duration="1500">
            <SlickSlider
              firstImgURL={firstImg}
              secondImgURL={secondImg}
              thirdImgURL={thirdImg}
              fourthImgURL={fourthImg}
              fiveImgURL={fiveImg} />
          </div>
        </Container>
      )}
      {showLaboratorios && (
        <Container>
          <div data-aos="fade-right">
            <h2>Laboratórios</h2>
            <p>A ZANAFLEX conta com laboratórios altamente equipados, temos capacidade de realizar diversos tipos de ensaio em nossas
              matérias-primas, compostos e produtos vulcanizados. A confiabilidade dos ensaios realizados em nossos laboratórios
              é avaliada através de estudos internos, validações externas e uso de ensaios de proficiência por órgãos reconhecidos e acreditados
              nacionalmente, conforme padrões normativos aplicáveis (NBR ISO 17025). Os controles de processo e do produto final asseguram respeitabilidade, qualidade e confiabilidade.
            </p>
          </div>
          <div data-aos="fade-left" data-aos-duration="1500">
            <img src={laboratoriosImg} alt="Laboratorios Zanaflex" />
          </div>
        </Container>
      )}
      {showAnalises && (
        <>
          <Container>
            <div data-aos="fade-right">
              <SlickSlider
                firstImgURL={firstAnalisesImg}
                secondImgURL={secondAnalisesImg}
                thirdImgURL={thirdAnalisesImg}
                fourthImgURL={fourthAnalisesImg} />
            </div>
            <div data-aos="fade-left" data-aos-duration="1500">
              <h2>Laboratório Para Análise de Matéria-Prima</h2>
              <ul>
                <li>Infravermelho</li>
                <li>Cromatografia de Gás</li>
                <li>Índice de Acidez</li>
                <li>Umidade e Perda de Calor</li>
                <li>Peneiração por via úmida</li>
                <li>Percentual de Teores Finos</li>
                <li>Ponto de fusão</li>
                <li>Extrato de Acetona</li>
                <li>Envelhecimento no forno</li>
              </ul>
            </div>
          </Container>
          <Container>
            <div>
              <h2>Laboratório de Análise Compostos de Borracha</h2>
              <ul>
                <li>Curvas de cura por MDR</li>
                <li>Resiliência</li>
                <li>Viscosidade Mooney</li>
                <li>DIN Abrasão</li>
                <li>Dureza Shore A e Shore D</li>
                <li>Envelhecimento ao Calor</li>
                <li>Deformação permanente</li>
                <li>Definição de Compactação</li>
                <li>Testes Viscoelásticos</li>
                <li>Alongamento e Tração</li>
                <li>Dispersão de Sílica e Negro de Fumo</li>
                <li>Ensaio Dinâmico e viscoelásticos em RPA</li>
              </ul>
            </div>
            <div>
              <img src={analises2Img} alt="Analises" />
            </div>
          </Container>
        </>
      )}
    </Main>
  );
}

export function InfraestruturaES({ showInstalacoesBool = true, showLaboratoriosBool = false, showAnalisesBool = false }: InfraestruturaProps) {
  const [showInstalacoes, setShowInstalacoes] = useState(showInstalacoesBool);
  const [showLaboratorios, setShowLaboratorios] = useState(showLaboratoriosBool);
  const [showAnalises, setShowAnalises] = useState(showAnalisesBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/infraestrutura/instalacoes/es-ES") {
      setShowAnalises(false);
      setShowLaboratorios(false);
      setShowInstalacoes(true);
    }

    if (location.pathname === "/infraestrutura/laboratorios/es-ES") {
      setShowAnalises(false);
      setShowLaboratorios(true);
      setShowInstalacoes(false);
    }

    if (location.pathname === "/infraestrutura/analises/es-ES") {
      setShowAnalises(true);
      setShowLaboratorios(false);
      setShowInstalacoes(false);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Infraestructura | Zanaflex - Compuestos de Caucho</title>
        <meta name="description" content="Instalaciones, Laboratorios y Análisis - Zanaflex" />
        <meta name="keywords" content="Infraestructura, Instalaciones, Laboratorios, Análisis, Zanaflex, Caucho, Compuesto de Caucho" />

        <link rel="canonical" href="https://zanaflex.com.br/infraestrutura/es-ES" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/infraestrutura" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/infraestrutura/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/infraestrutura/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/infraestrutura/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/infraestrutura/instalacoes/es-ES" className={showInstalacoes ? 'submenu-active' : 'submenu-disabled'}><h1>Instalaciones</h1></Link>
        <Link to="/infraestrutura/laboratorios/es-ES" className={showLaboratorios ? 'submenu-active' : 'submenu-disabled'}><h1>Laboratorios</h1></Link>
        <Link to="/infraestrutura/analises/es-ES" className={showAnalises ? 'submenu-active' : 'submenu-disabled'} ><h1>Análisis</h1></Link>
      </SubMenu>
      {showInstalacoes && (
        <Container>
          <div data-aos="fade-right">
            <h2>Instalaciones</h2>
            <ul>
              <li>Planta de fabricación con aproximadamente 10.000 m² de superficie cubierta</li>
              <li>200 empleados</li>
              <li>Capacidad de Producción: 1700 ton/mes de producto final</li>
              <li>6 líneas de mezcla: 5 dedicadas al compuesto negro y 1 al compuesto de color</li>
              <li>Cilindros abiertos, calandra y preformador</li>
              <li>Mezcladores internos (banburyes), tangenciales e interconectados</li>
              <li>Control de procesos por: temperatura, tiempo, presión, velocidad del rotor, energía (Kwh)</li>
            </ul>
          </div>
          <div data-aos="fade-left" data-aos-duration="1500">
            <SlickSlider
              firstImgURL={firstImg}
              secondImgURL={secondImg}
              thirdImgURL={thirdImg}
              fourthImgURL={fourthImg}
              fiveImgURL={fiveImg} />
          </div>
        </Container>
      )}
      {showLaboratorios && (
        <Container>
          <div data-aos="fade-right">
            <h2>Laboratorios</h2>
            <p>ZANAFLEX cuenta con laboratorios altamente equipados, estamos en capacidad de realizar diferentes tipos de pruebas en nuestros
              materias primas, compuestos y productos vulcanizados. La fiabilidad de los ensayos realizados en nuestros laboratorios
              es evaluado a través de estudios internos, validaciones externas y uso de pruebas de competencia por parte de organismos reconocidos y acreditados
              a nivel nacional, de acuerdo con las normas normativas aplicables (NBR ISO 17025). Los controles del proceso y del producto final garantizan la respetabilidad, la calidad y la fiabilidad.
            </p>
          </div>
          <div data-aos="fade-left" data-aos-duration="1500">
            <img src={laboratoriosImg} alt="Laboratorios Zanaflex" />
          </div>
        </Container>
      )}
      {showAnalises && (
        <>
          <Container>
            <div data-aos="fade-right">
              <SlickSlider
                firstImgURL={firstAnalisesImg}
                secondImgURL={secondAnalisesImg}
                thirdImgURL={thirdAnalisesImg}
                fourthImgURL={fourthAnalisesImg} />
            </div>
            <div data-aos="fade-left" data-aos-duration="1500">
              <h2>Laboratorio de Análisis de Materias Primas</h2>
              <ul>
                <li>Infrarrojo</li>
                <li>Cromatografía de gases</li>
                <li>Nivel de acidez</li>
                <li>Humedad y pérdida de calor</li>
                <li>Tamizado húmedo</li>
                <li>Porcentaje de contenido fino</li>
                <li>Punto de fusión</li>
                <li>Extracto de acetona</li>
                <li>Envejecimiento en horno</li>
              </ul>
            </div>
          </Container>
          <Container>
            <div>
              <h2>Laboratorio de Análisis de Compuestos de Caucho</h2>
              <ul>
                <li>Curvas de curado MDR</li>
                <li>Resiliencia</li>
                <li>Viscosidad Mooney</li>
                <li>Abrasión DIN</li>
                <li>Dureza Shore A y Shore D</li>
                <li>Envejecimiento térmico</li>
                <li>Deformación permanente</li>
                <li>Definición de compresión</li>
                <li>Pruebas Viscoelásticas</li>
                <li>Estiramiento y Tracción</li>
                <li>Dispersión de sílice y negro de humo</li>
                <li>Ensayos dinámicos y viscoelásticos en RPA</li>
              </ul>
            </div>
            <div>
              <img src={analises2Img} alt="Analisis" />
            </div>
          </Container>
        </>
      )}
    </Main>
  );
}

export function InfraestruturaUS({ showInstalacoesBool = true, showLaboratoriosBool = false, showAnalisesBool = false }: InfraestruturaProps) {
  const [showInstalacoes, setShowInstalacoes] = useState(showInstalacoesBool);
  const [showLaboratorios, setShowLaboratorios] = useState(showLaboratoriosBool);
  const [showAnalises, setShowAnalises] = useState(showAnalisesBool);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/infraestrutura/instalacoes/en-US") {
      setShowAnalises(false);
      setShowLaboratorios(false);
      setShowInstalacoes(true);
    }

    if (location.pathname === "/infraestrutura/laboratorios/en-US") {
      setShowAnalises(false);
      setShowLaboratorios(true);
      setShowInstalacoes(false);
    }

    if (location.pathname === "/infraestrutura/analises/en-US") {
      setShowAnalises(true);
      setShowLaboratorios(false);
      setShowInstalacoes(false);
    }
  }, [location]);

  return (
    <Main>
      <Helmet>
        <title>Infrastructure | Zanaflex - Rubber Compounds</title>
        <meta name="description" content="Installations, Laboratories and Analysis - Zanaflex" />
        <meta name="keywords" content="Infrastructure, Installations, Laboratories, Analysis, Zanaflex, Rubber, Rubber Compound" />

        <link rel="canonical" href="https://zanaflex.com.br/infraestrutura/en-US" />
        <link rel="alternate" hrefLang="pt-BR" href="https://zanaflex.com.br/infraestrutura" />
        <link rel="alternate" hrefLang="en-US" href="https://zanaflex.com.br/infraestrutura/en-US" />
        <link rel="alternate" hrefLang="es-ES" href="https://zanaflex.com.br/infraestrutura/es-ES" />
        <link rel="alternate" hrefLang="x-default" href="https://zanaflex.com.br/infraestrutura/en-US" />
      </Helmet>

      <SubMenu>
        <Link to="/infraestrutura/instalacoes/en-US" className={showInstalacoes ? 'submenu-active' : 'submenu-disabled'}><h1>Installations</h1></Link>
        <Link to="/infraestrutura/laboratorios/en-US" className={showLaboratorios ? 'submenu-active' : 'submenu-disabled'}><h1>Laboratories</h1></Link>
        <Link to="/infraestrutura/analises/en-US" className={showAnalises ? 'submenu-active' : 'submenu-disabled'} ><h1>Analysis</h1></Link>
      </SubMenu>
      {showInstalacoes && (
        <Container>
          <div data-aos="fade-right">
            <h2>Installations</h2>
            <ul>
              <li>Manufacturing plant with approximately 10,000 m² of covered area</li>
              <li>200 employees</li>
              <li>Production Capacity: 1700 tons/month of final product</li>
              <li>6 mixing lines: 5 dedicated to black compound and 1 to colored compound</li>
              <li>Open cylinders, calender and preformer</li>
              <li>Internal mixers (banburies), tangential and interconnected</li>
              <li>Process control by: temperature, time, pressure, rotor speed, energy (Kwh)</li>
            </ul>
          </div>
          <div data-aos="fade-left" data-aos-duration="1500">
            <SlickSlider
              firstImgURL={firstImg}
              secondImgURL={secondImg}
              thirdImgURL={thirdImg}
              fourthImgURL={fourthImg}
              fiveImgURL={fiveImg} />
          </div>
        </Container>
      )}
      {showLaboratorios && (
        <Container>
          <div data-aos="fade-right">
            <h2>Laboratories</h2>
            <p>ZANAFLEX has highly equipped laboratories, we are able to perform different types of tests in our
              raw materials, compounds and vulcanized products. The reliability of the tests performed in our laboratories
              is evaluated through internal studies, external validations and use of proficiency tests by recognized and accredited bodies
              nationally, according to applicable normative standards (NBR ISO 17025). Process and final product controls ensure respectability, quality and reliability.
            </p>
          </div>
          <div data-aos="fade-left" data-aos-duration="1500">
            <img src={laboratoriosImg} alt="Laboratorios Zanaflex" />
          </div>
        </Container>
      )}
      {showAnalises && (
        <>
          <Container>
            <div data-aos="fade-right">
              <SlickSlider
                firstImgURL={firstAnalisesImg}
                secondImgURL={secondAnalisesImg}
                thirdImgURL={thirdAnalisesImg}
                fourthImgURL={fourthAnalisesImg} />
            </div>
            <div data-aos="fade-left" data-aos-duration="1500">
              <h2>Laboratory for Raw Material Analysis</h2>
              <ul>
                <li>Infra-red</li>
                <li>Gas Chromatography</li>
                <li>Acidity level</li>
                <li>Humidity and Heat Loss</li>
                <li>Wet sieving</li>
                <li>Percentage of Fine Contents</li>
                <li>Fusion point</li>
                <li>Acetone Extract</li>
                <li>Oven aging</li>
              </ul>
            </div>
          </Container>
          <Container>
            <div>
              <h2>Laboratory of Analysis of Rubber Compounds</h2>
              <ul>
                <li>MDR cure curves</li>
                <li>Resilience</li>
                <li>Mooney viscosity</li>
                <li>DIN Abrasion</li>
                <li>Shore A and Shore D hardness</li>
                <li>Heat aging</li>
                <li>Permanent deformation</li>
                <li>Definition of Compression</li>
                <li>Viscoelastic Tests</li>
                <li>Stretching and Traction</li>
                <li>Dispersion of Silica and Carbon Black</li>
                <li>Dynamic and viscoelastic testing in RPA</li>
              </ul>
            </div>
            <div>
              <img src={analises2Img} alt="Analysis" />
            </div>
          </Container>
        </>
      )}
    </Main>
  );
}
