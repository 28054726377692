import styled from 'styled-components';

import labImg from '../../assets/grid/0.jpg';
import firstGridImg from '../../assets/grid/1.jpg'
import secondGridImg from '../../assets/grid/2.jpg'
import thirdGridImg from '../../assets/grid/3.jpg'
import fourthGridImg from '../../assets/grid/4.jpg'

export const Main = styled.main`
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  >div + div {
    padding: 64px 0px;
    

    :not(.terms){
      border-bottom: 5px solid var(--color-gray);
    }
  }

  >div:last-child{
    border:none;
  }

  .animation-container {
    padding: 0;
    height: 100%;
  }
`;

export const SubMenu = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px;

  >a {
      font-family: 'Expansiva', sans-serif;
      cursor: pointer;
      margin: 16px;
      color: var(--color-gray);
      text-decoration: none;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      padding-bottom: 35px;

      &:hover{
        text-decoration: underline;
      }

      >a {
      cursor: pointer;
      margin: 16px;
      color: var(--color-gray);
      text-decoration: none;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      padding-bottom: 35px;

      &:hover{
        text-decoration: underline;
        color: var(--color-yellow);
        transition: all 0.2s;
      }
    }
    }

    .submenu-active{
      text-decoration: underline;
      color: var(--color-yellow);
    }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 70%;
  
  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50%;
    padding: 16px;

    >h4:first-child {
      font-family: 'Expansiva', sans-serif;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      border-bottom: 5px solid var(--color-gray);
      padding-bottom: 35px;
    }


    >p, ul {
      padding: 0 16px;
      padding-top: 35px;
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      
    }

    img {
      padding: 10px;
      width: 250px;
    }

    @media screen and (max-width: 960px) {
      padding: 16px;
    }
  }

  @media screen and (max-width: 960px) {
    flex-wrap: wrap;

    img {
      width: 320px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 70%;
  text-align: center;

  h2{
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  p{
    font-size: 22px;
  }
`;

export const Terms = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  width: 70%;

  p{
    font-size: 26px;
  }

  a{
    color: var(--color-gray);
    text-decoration: none;

    &:hover{
        text-decoration: underline;
      }
  }
`;

export const Button2 = styled.div`
  a, button{
    -webkit-appearance: none;
    margin: 0;
    border: none;
    overflow: visible;
    color: var(--color-gray);
    text-transform: uppercase;
    display: inline-block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 20px;
    background: transparent;
    vertical-align: middle;
    line-height: 40px;
    min-height: 40px;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    border-radius: 25px;
    border: 2px solid var(--color-gray);
    font-weight: normal;
    box-shadow: inset 0 0 0 0 transparent;

    &:hover{
      background:var(--color-gray);
      color: var(--color-white);
      transition: all 0.2s ease-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
  }
`;

export const PlayerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Square = styled.div`
 border: 1px solid var(--color-gray);
 min-height: 60vh;

 .supplier-location{
   display: flex;
   flex-direction: column;
   flex: 1;
   justify-content: space-around;

   div{
     text-align: center;
   }
 }
 
 .families{
        display: flex;
        flex: 1;
        width: 100%;
      }

  ul{
    display: flex;
    flex-direction: column;
    flex: 1;

    justify-content: space-evenly;
  }

  a{
    text-decoration: none;
    color: var(--color-gray);
    display: flex;
    flex: 1;

    &:hover{
        text-decoration: underline;
        transition: all 0.2s;
      }
 }
`

export const Glass = styled.div`
  display: flex;
  padding: 20px;
 
  >div{
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
    border: 3px solid var(--color-white);
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    
    &:hover{
      opacity: 1;
      transition: all 0.5s;
    }

    @media screen and (max-width: 960px) {
      opacity: 1;
    }
  }
  
  a{
    text-decoration: none;
    color: var(--color-white);
    display: block;

    &:hover{
        text-decoration: underline;
        transition: all 0.2s;
      }
  }
`

export const AnimationContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: var(--color-gray);
  width: 100%;
  height: 50px;
  color: var(--color-white);
  flex-wrap: wrap;

  @media screen and (max-width: 960px) {
      flex-direction: column;
    }

  .proeficiencia{
    padding: 64px;

    h3{
      font-size: 27px;
      color: var(--color-yellow);
      margin-bottom: 10px;
    }

    @media screen and (max-width: 960px) {
      max-width: 100%;
    }
  }

  .grid{
    display: grid;
    grid-template-columns: 300px 300px;

    @media screen and (max-width: 960px) {
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;
      flex-direction: column;
      flex: 1;
    }
  }

  .laboratorio{
    background-image: url(${labImg});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    min-width: 300px;
    min-height: 600px;

    @media screen and (max-width: 960px) {
      min-width: 100%;
      min-width: 100%;
    }
  }

  .grid-first{
    background-image: url(${firstGridImg});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    width: 300px;
    height: 300px;

    @media screen and (max-width: 960px) {
      min-width: 100%;
      min-width: 100%;
    }
  }

  .grid-second{
    background-image: url(${secondGridImg});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    width: 300px;
    height: 300px;

    @media screen and (max-width: 960px) {
      min-width: 100%;
      min-width: 100%;
    }
  }

  .grid-third{
    background-image: url(${thirdGridImg});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    width: 300px;
    height: 300px;

    @media screen and (max-width: 960px) {
      min-width: 100%;
      min-width: 100%;
    }
  }

  .grid-fourth{
    background-image: url(${fourthGridImg});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    width: 300px;
    height: 300px;

    @media screen and (max-width: 960px) {
      min-width: 100%;
      min-width: 100%;
    }
  }

  .fispqs{
    padding: 64px;
    max-width: 20%;

    h3{
      font-size: 27px;
      color: var(--color-yellow);
      margin-bottom: 10px;
    }

    @media screen and (max-width: 960px) {
      max-width: 100%;
    }
  }

`